import { FC } from 'react';
import { Typography } from '@mui/material';
import FormattedMessage from 'shared/components/FormattedMessage';
import BusinessPriorityChip from 'shared/components/BusinessPriorityChip';
import { BusinessPriority } from 'shared/components/BusinessPriorityChip/businessPriority';
interface CampaignStatusProps {
  type: BusinessPriority | undefined;
}

const CampaignStatus: FC<CampaignStatusProps> = ({ type }) => {
  return (
    <>
      <Typography mr={1} mb={1}>
        <FormattedMessage
          id="campaigns.details.businessPriority"
          defaultMessage="Service Priority"
        />
      </Typography>
      <BusinessPriorityChip type={type} />
    </>
  );
};

export default CampaignStatus;
