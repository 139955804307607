import { Grid } from '@mui/material';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

interface CampaignWorkBreakdownWidgetItemProps extends BaseComponentProps {
  widgetBodyItem: any;
  workBreakData: any;
}

export const CampaignWorkBreakdownWidgetItem: FunctionComponent<
  CampaignWorkBreakdownWidgetItemProps
> = ({ widgetBodyItem, workBreakData }) => {
  const { t: translation } = useTranslation();

  return (
    <Grid item xs={3} className="campaign-work-breakdown-widget-body-item">
      <div className="campaign-widget-item-icon">{widgetBodyItem.getIcon}</div>
      <div className="campaign-widget-item-data">
        <div className="header-3">{widgetBodyItem.getTitle(workBreakData)}</div>
        <div className="body-5 campaign-info">
          {widgetBodyItem.getDescription(translation)}
        </div>
      </div>
    </Grid>
  );
};
