import { ProjectInfoData } from 'Campaigns/interfaces/ProjectInfoWidget.interface';
import { getActiveCampaign, setActiveCampaign } from 'Campaigns/store';
import { useFetchCampaignsListMutation } from 'Campaigns/store/api';
import useCommonDispatch from 'Common/utils/use-dispatch';
import useCommonSelector from 'Common/utils/use-selector';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BusinessPriority } from 'shared/components/BusinessPriorityChip/businessPriority';

export default function useManageActiveCampaignFromParams(): [
  boolean,
  ProjectInfoData | undefined,
  Array<ProjectInfoData> | undefined,
] {
  const dispatch = useCommonDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { id: campaignIdParam } = useParams();
  const [
    searchCampaigns,
    { data: campaignList, error: campaignListFetchError },
  ] = useFetchCampaignsListMutation();

  const activeCampaign: ProjectInfoData | undefined = useCommonSelector<
    ProjectInfoData | undefined
  >(getActiveCampaign);

  useEffect(() => {
    if (campaignIdParam)
      searchCampaigns({ filter: { id: [{ value: campaignIdParam }] } });
  }, []);

  useEffect(() => {
    if (!activeCampaign && campaignList?.data?.length) {
      const activeCampaignFromParams: ProjectInfoData | undefined =
        campaignList?.data?.find(
          (campaign: ProjectInfoData) => campaign.id === campaignIdParam,
        );

      dispatch(setActiveCampaign(activeCampaignFromParams as ProjectInfoData));
    }
  }, [activeCampaign, campaignList]);

  useEffect(() => {
    if (campaignListFetchError) {
      dispatch(
        setActiveCampaign({
          id: campaignIdParam as string,
          endDate: new Date(),
          startTime: new Date(),
          assignees: [],
          businessPriority: BusinessPriority.LOW,
          name: '',
          status: '',
        }),
      );
    }
  }, [campaignListFetchError]);

  useEffect(() => {
    if (activeCampaign) {
      setIsLoading(false);
    }
  }, [activeCampaign]);

  return [isLoading, activeCampaign, campaignList?.data || []];
}
