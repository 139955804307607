import { Stack } from '@mui/material';
import { ProjectInfoData } from 'Campaigns/interfaces/ProjectInfoWidget.interface';
import { getActiveCampaign } from 'Campaigns/store';
import {
  useFetchTicketsPerPersonWidgetQuery,
  useLazyFetchTicketsPerPersonWidgetQuery,
} from 'Campaigns/store/api';
import { presentationalConfigurationForWidgets } from 'Common/components/Widgets/fixtures/widget-data';
import useCommonSelector from 'Common/utils/use-selector';
import { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FormattedMessage from 'shared/components/FormattedMessage';
import VerticalStackedBarChart from 'shared/components/VerticalStackedBarChart';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

interface CampaignTicketsPerPersonWidgetProps extends BaseComponentProps {}

export const CampaignTicketsPerPersonWidget: FunctionComponent<
  CampaignTicketsPerPersonWidgetProps
> = () => {
  const activeCampaign = useCommonSelector<ProjectInfoData | undefined>(
    getActiveCampaign
  );

  const [
    fetchTicketsPerPersonData,
    {
      data: ticketsPerPersonWidgetData,
      isFetching: isLoading,
      error: ticketsPerPersonWidgetDataFetchError,
    },
  ] = useLazyFetchTicketsPerPersonWidgetQuery();

  useEffect(() => {
    if (activeCampaign) {
      fetchTicketsPerPersonData(activeCampaign.id);
    }
  }, [activeCampaign]);

  if (ticketsPerPersonWidgetDataFetchError)
    return (
      <div>
        {' '}
        <FormattedMessage
          id="common.anErrorOccured"
          defaultMessage="An error occured"
        />
      </div>
    );

  return (
    <div className="campaign-widget-container">
      <div className="campaign-widget-title">
        <span>Tickets per Team / Person</span>
      </div>
      <div className="campaign-widget-body">
        <VerticalStackedBarChart
          fetchWidgetData={() => {}}
          widgetData={ticketsPerPersonWidgetData}
          style={
            presentationalConfigurationForWidgets[
              'campaigns_tickets_per_person'
            ]?.style
          }
          isWidgetDataLoading={isLoading || !ticketsPerPersonWidgetData}
          enableTooltip={true}
          displayLegend
          legendPosition="bottom"
          isResponsive
          aspectRatio={2.5}
          options={{}}
          customLegendContainerId={
            'campaigns-tickets-per-person-legend-container'
          }
        />
      </div>
    </div>
  );
};
