import { NodeType } from 'WorkflowBuilder/interfaces/node';
import DiagramStep, {
  initBulk,
  Operator,
} from 'WorkflowBuilder/interfaces/step';
import { DIAGRAM_STEP_TYPE } from 'WorkflowBuilder/interfaces/step';
import { rootWorkflowTriggerSeqId } from 'WorkflowBuilder/interfaces/types';

export const diagramInitialSteps: Array<DiagramStep> = [
  {
    name: 'Set Workflow Trigger',
    workflowSeqId: 0,
    diagramData: {
      position: {
        x: 0,
        y: 0,
      },
    },
    routes: [],
    nodeType: NodeType.TRIGGER,
  },
];

const getDiagramStepsFixtures = (diagramContainerRef?: {
  current: any;
}): DiagramStep[] => {
  return diagramInitialSteps;
};

export default getDiagramStepsFixtures;
