import { ProjectInfoData } from 'Campaigns/interfaces/ProjectInfoWidget.interface';
import { FunctionComponent } from 'react';
import CampaignBurndownWidget from '../CampaignBurndownWidget';
import CampaignWorkBreakdownWidget from '../CampaignWorkBreakdownWidget';
import CampaignProjectInfoWidget from '../CampaignProjectInfoWidget';

interface CampaignDetailsHeaderProps {
  campaignData?: ProjectInfoData;
}
export const CampaignDetailsHeader: FunctionComponent<
  CampaignDetailsHeaderProps
> = ({ campaignData }) => {
  return (
    <div className="campaign-details-header">
      <div className="campaign-details-info-container">
        <CampaignProjectInfoWidget projectData={campaignData} />
      </div>
      <div className="campaign-details-burndown-container">
        <CampaignBurndownWidget campaignId={campaignData?.id} />
      </div>
      <div className="campaign-details-breakdown-container">
        <CampaignWorkBreakdownWidget campaignId={campaignData?.id as string} />
      </div>
    </div>
  );
};
