import { FC } from 'react';
import { Typography, Avatar, Tooltip } from '@mui/material';
import FormattedMessage from 'shared/components/FormattedMessage';
import { Box } from '@mui/system';

interface CampaignAssignsProps {
  projectInfoData: any;
}

const CampaignAssigns: FC<CampaignAssignsProps> = ({ projectInfoData }) => {
  return (
    <>
      <Typography className="project-assignees" mb={'3px'}>
        <FormattedMessage
          id="campaigns.details.assignee"
          defaultMessage="Assignees"
        />
      </Typography>
      <Box className="assignees-container" display={'flex'} mt={'3px'}>
        {projectInfoData?.assignees.length > 5 ? (
          <>
            {projectInfoData?.assignees
              .slice(0, 5)
              ?.map((user: any, i: number) => (
                <Tooltip
                  title={user.email || user.name}
                  placement="top"
                  key={i}
                >
                  <Avatar key={i} src={user.profilePictureUrl} />
                </Tooltip>
              ))}
            <Tooltip
              title={`${projectInfoData?.assignees.length - 5} more assignees`}
              placement="top"
            >
              <div className="avatar body-3">
                {'+'}
                {projectInfoData?.assignees.length - 5}
              </div>
            </Tooltip>
          </>
        ) : (
          projectInfoData?.assignees?.map((user: any, i: number) => (
            <Tooltip title={user.email || user.name} placement="top" key={i}>
              <Avatar key={i} src={user.profilePictureUrl} />
            </Tooltip>
          ))
        )}
      </Box>
    </>
  );
};

export default CampaignAssigns;
