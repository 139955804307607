import { yupResolver } from '@hookform/resolvers/yup';
import { Button, CircularProgress, Stack } from '@mui/material';
import { AutocompleteOption } from 'FindingDetails/store/api';
import {
  useCreateReportRuleMutation,
  useFetchReportRuleQuery,
  useUpdateReportRuleMutation,
} from 'Settings/store/api';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CommonModalContainer } from 'shared/components/CommonModalContainer';
import { ContentSection } from 'shared/components/ContentSection/ContentSection';
import { OpusSvgIcon } from 'shared/components/IconComponents/OpusSvgIcon/OpusSvgIcon';
import { InputLabelWrapper } from 'shared/components/InputLabelWrapper/InputLabelWrapper';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import Autocomplete from 'Common/components/Autocomplete';
import {
  reportRuleDashboardTypeOptions,
  reportRuleDefaultValues,
  reportRuleFrequencyOptions,
  reportRuleInitialSchema,
  reportRuleRecipientsOptions,
  reportRuleRiskWidgetOptions,
  reportRuleWidgetOptions,
} from 'shared/fixtures/data/report-rule.data';
import { useFetchReportRulesOptionItems } from 'shared/hooks/useFetchReportRuleOptionItems';
import { ReportRuleDataHandler } from 'shared/handlers/report-rule-data.handler';
import {
  ReportRuleContentGroupType,
  ReportRuleFormData,
  ReportRuleRecipientType,
  ReportRuleType,
} from 'shared/models/data/report-rule.model';
import * as yup from 'yup';
import {
  operationalDashboardFilterOptions,
  operationalDashboardTrendMetricsTypeOptions,
} from 'shared/fixtures/data/operational-dashboard-filter-option.data';
import { OptionDataHandler } from 'shared/handlers/option-data.handler';

interface ReportRuleDrawerPanelProps extends BaseComponentProps {
  onCancel?: () => void;
  cancelationModalProps?: {
    open: boolean;
    onClose: () => void;
    onOpen: () => void;
  };
}

const reportRuleDataHandler = new ReportRuleDataHandler();

const optionDataHandler = new OptionDataHandler();

export const ReportRuleDrawerPanel: FunctionComponent<
  ReportRuleDrawerPanelProps
> = ({ onCancel, cancelationModalProps }) => {
  const [reportRuleSchema, setReportRuleSchema] = useState(
    reportRuleInitialSchema
  );

  const [searchParams] = useQueryParams();

  const { t: translation } = useTranslation();

  const { data: reportRule, isFetching: reportRuleLoading } =
    useFetchReportRuleQuery(searchParams?.ruleId as string, {
      skip: !searchParams?.ruleId,
      refetchOnMountOrArgChange: true,
    });

  const {
    findingTypeOptionList,
    emailOptionList,
    scopesOptionList,
    groupsOptionList,
    servicesOptionList,
    getGroupsOptionList,
    getServiceOptionList,
    scopesOptionsLoading,
    groupsOptionsLoading,
    servicesOptionsLoading,
    findingTypeOptionListLoading,
  } = useFetchReportRulesOptionItems(reportRule);

  const defaultFormValues = useMemo(() => {
    if (reportRuleLoading) return undefined;

    if (reportRule) {
      return reportRuleDataHandler.transformReportRuleToFormData(reportRule);
    }

    return undefined;
  }, [reportRule, reportRuleLoading]);

  const [
    createReportRule,
    { isLoading: createReportRuleLoading, isSuccess: createReportRuleSuccess },
  ] = useCreateReportRuleMutation();

  const [
    updateReportRule,
    { isLoading: updateReportRuleLoading, isSuccess: updateReportRuleSuccess },
  ] = useUpdateReportRuleMutation();

  const {
    register,
    formState: { errors },
    setValue: setFormValue,
    getValues: getFormValues,
    reset: resetForm,
    watch,
    handleSubmit,
    clearErrors,
  } = useForm({
    resolver: yupResolver(yup.object().shape(reportRuleSchema)),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (defaultFormValues) {
      const recipientValue = defaultFormValues['recipients']
        .value as ReportRuleType;

      const contentTypeValue = defaultFormValues['contentType']
        .value as ReportRuleContentGroupType;

      setFormSchemaBasedOnContentAndRecipientType(
        contentTypeValue,
        recipientValue
      );

      for (const formValueKey in defaultFormValues) {
        setFormValue(
          formValueKey as any,
          defaultFormValues[formValueKey as keyof ReportRuleFormData]
        );
      }
    } else {
      for (const formValueKey in reportRuleDefaultValues) {
        setFormValue(
          formValueKey as any,
          reportRuleDefaultValues[formValueKey as keyof ReportRuleFormData]
        );
      }
    }
  }, [defaultFormValues]);

  const onDrawerClose = () => {
    resetForm();
    onCancel && onCancel();
  };

  useEffect(() => {
    if (createReportRuleSuccess || updateReportRuleSuccess) {
      onDrawerClose();
    }
  }, [createReportRuleSuccess, updateReportRuleSuccess]);

  const recipientsValue = watch('recipients');

  const groupsValue = watch('groups');

  const servicesValue = watch('services');

  const scopeValue = watch('scopes');

  const contentType = watch('contentType');

  const emails = watch('emails');

  const widgetsValue = watch('widgets');

  const domainsValue = watch('domains');

  const reportRuleAllWidgetOptions = useMemo<Array<AutocompleteOption>>(() => {
    if (!contentType) {
      return [...reportRuleWidgetOptions, ...reportRuleRiskWidgetOptions];
    }

    if (contentType?.value === ReportRuleContentGroupType.RISK)
      return reportRuleRiskWidgetOptions;

    return reportRuleWidgetOptions;
  }, [contentType]);

  const resetNodeOptions = () => {
    getGroupsOptionList();
    getServiceOptionList();
  };

  const setFormSchemaBasedOnContentAndRecipientType = (
    contentType: ReportRuleContentGroupType,
    recipientType: ReportRuleType
  ) => {
    switch (recipientType) {
      case ReportRuleType.STATIC:
        setSchemaForEmail();
        break;
      case ReportRuleType.GROUP_OWNERS:
        setSchemaForGroupOwners();
        break;
      case ReportRuleType.SERVICE_OWNERS:
        setSchemaForServiceOwners(
          contentType === ReportRuleContentGroupType.RISK
        );
        break;
    }
  };

  const setSchemaForEmail = () => {
    const schema = {
      ...reportRuleInitialSchema,
      groups: yup.object().required(),
      emails: yup.array().required().min(1),
    };

    if (schema.services) delete schema.services;

    setReportRuleSchema(() => ({ ...schema }));
  };

  const setSchemaForGroupOwners = () => {
    const schema = {
      ...reportRuleInitialSchema,
      groups: yup.array().required().min(1),
    };

    if (schema.services) delete schema.services;
    if (schema.emails) delete schema.emails;

    setReportRuleSchema(() => ({ ...schema }));
  };

  const setSchemaForServiceOwners = (enableServices: boolean) => {
    const schema = {
      ...reportRuleInitialSchema,
      groups: enableServices
        ? yup.object().required()
        : yup.array().required().min(1),
    };

    if (enableServices) {
      schema.services = yup.array().required();
    } else {
      if (schema.services) delete schema.services;
    }

    if (schema.emails) delete schema.emails;

    setReportRuleSchema(() => ({ ...schema }));
  };

  const filteredReportRuleRecipientsOptions = useMemo<
    Array<AutocompleteOption>
  >(() => {
    if (
      contentType?.value === ReportRuleContentGroupType.COMPARATIVE_PER_GROUP
    ) {
      return reportRuleRecipientsOptions.filter(
        (reportRuleRecipientsOption) => {
          return (
            reportRuleRecipientsOption.value !== ReportRuleType.SERVICE_OWNERS
          );
        },
        []
      );
    }

    return reportRuleRecipientsOptions;
  }, [contentType]);

  const onAutocompleteOptionChange = (
    model: any,
    autocompletePayload: AutocompleteOption | Array<AutocompleteOption>
  ) => {
    setFormValue(model, autocompletePayload);
  };

  const resetOrganizationInputs = (
    contentType: ReportRuleContentGroupType,
    recipientType: ReportRuleType
  ) => {
    resetNodeOptions();

    setFormValue('scopes', { value: 'All', label: 'All' });
    setFormValue('emails', []);
    setFormValue('services', []);
    clearErrors(['emails', 'groups', 'services', 'scopes']);

    if (
      (contentType === ReportRuleContentGroupType.COMPARATIVE_PER_SERVICE &&
        recipientType === ReportRuleType.SERVICE_OWNERS) ||
      recipientType === ReportRuleType.GROUP_OWNERS
    ) {
      setFormValue('groups', []);
    }

    setFormSchemaBasedOnContentAndRecipientType(contentType, recipientType);
  };

  const onRecipientsOptionChange = (
    model: any,
    autocompletePayload: AutocompleteOption | Array<AutocompleteOption>
  ) => {
    const selectedOption = autocompletePayload as AutocompleteOption;
    const recipientValue = selectedOption?.value as ReportRuleType;
    const contentTypeValue = contentType?.value as ReportRuleContentGroupType;

    resetOrganizationInputs(contentTypeValue, recipientValue);

    onAutocompleteOptionChange(model, autocompletePayload);
  };

  const onContentTypeOptionChange = (
    model: string,
    options: AutocompleteOption | Array<AutocompleteOption>
  ) => {
    clearErrors(['emails', 'groups', 'services', 'scopes']);

    const selectedOption = options as AutocompleteOption;
    const recipient = recipientsValue?.value as ReportRuleType;

    if (selectedOption?.value !== ReportRuleContentGroupType.RISK) {
      setFormValue('timeFrame', operationalDashboardFilterOptions[0]);
      setFormValue('trendMetricsTypes', []);
    }

    if (
      recipient === ReportRuleType.SERVICE_OWNERS &&
      selectedOption.value === ReportRuleContentGroupType.COMPARATIVE_PER_GROUP
    ) {
      setFormValue('recipients', reportRuleRecipientsOptions[0]);
    }
    setFormValue('widgets', []);

    resetOrganizationInputs(
      selectedOption.value as ReportRuleContentGroupType,
      recipient
    );

    onAutocompleteOptionChange(model, options);
  };

  const onScopeOptionChange = (
    model: string,
    autocompletePayload: AutocompleteOption | Array<AutocompleteOption>
  ) => {
    const singleOption = autocompletePayload as AutocompleteOption;

    const recipient = recipientsValue?.value;

    if (singleOption.value === 'All') {
      resetNodeOptions();
    } else {
      getGroupsOptionList(singleOption.value);
      getServiceOptionList(singleOption.value);

      if (
        recipient === ReportRuleType.SERVICE_OWNERS &&
        contentType?.value === ReportRuleContentGroupType.RISK
      ) {
        setFormValue('services', []);
      }

      if (
        recipient === ReportRuleType.GROUP_OWNERS ||
        (recipient === ReportRuleType.SERVICE_OWNERS &&
          contentType?.value ===
            ReportRuleContentGroupType.COMPARATIVE_PER_SERVICE)
      ) {
        setFormValue('groups', []);
      } else {
        setFormValue('groups', {
          value: 'All',
          label: 'All',
        });
      }
    }

    onAutocompleteOptionChange(model, autocompletePayload);
  };

  const onGroupOptionChange = (
    model: string,
    autocompletePayload: AutocompleteOption | Array<AutocompleteOption>
  ) => {
    const recipient = recipientsValue?.value;

    onAutocompleteOptionChange(model, autocompletePayload);
    if (recipient === ReportRuleType.SERVICE_OWNERS) {
      setFormValue('services', []);
      const singleOption = autocompletePayload as AutocompleteOption;
      if (singleOption.value === 'All') {
        if (scopeValue.value === 'All') {
          getServiceOptionList();
        } else {
          getServiceOptionList(scopeValue.value);
        }
      } else {
        getServiceOptionList(singleOption.value);
      }
    }
  };

  const submitForm = () => {
    handleSubmit((values) => {
      const reportRulePostData =
        reportRuleDataHandler.transformReportRuleFormDataToPostData(
          values as ReportRuleFormData
        );

      if (reportRule) {
        updateReportRule({ id: reportRule.id, ...reportRulePostData });
      } else {
        createReportRule(reportRulePostData);
      }
    })();
  };

  const renderEmailField = () => {
    if (recipientsValue?.value === ReportRuleType.STATIC) {
      return (
        <InputLabelWrapper
          label={translation(`settings.forms.labels.reportRules.sendAnEmail`)}
        >
          <Autocomplete
            model="emails"
            onChangeCallBack={onAutocompleteOptionChange}
            optionList={optionDataHandler.sortOptionList(
              emailOptionList,
              'desc',
              'label'
            )}
            classes={{
              root: 'multi-select-field-1 ',
              paper: 'multi-select-field-paper-1',
              inputRoot: errors.emails ? 'input-validation-error-1' : '',
            }}
            enableCheckbox
            freeSolo
            initialSelectedValues={emails}
            values={emails}
          />
        </InputLabelWrapper>
      );
    }

    return <></>;
  };

  const allScopeOptions = useMemo<Array<AutocompleteOption>>(() => {
    return scopesOptionList
      ? optionDataHandler.sortOptionList(scopesOptionList, 'desc', 'label')
      : [];
  }, [scopesOptionList]);

  const renderScopeFieldInput = () => {
    return (
      <Autocomplete
        single
        optionList={scopesOptionsLoading ? undefined : allScopeOptions}
        loading={true}
        model="scopes"
        onChangeCallBack={onScopeOptionChange}
        classes={{
          root: 'multi-select-field-1 ',
          paper: 'multi-select-field-paper-1',
          inputRoot: errors.scopes ? 'input-validation-error-1' : '',
        }}
        enableCheckbox
        values={scopeValue}
        initialSelectedValues={scopeValue}
        areOptionsLoaded
      />
    );
  };

  const allGroupOptions = useMemo(() => {
    let options: Array<AutocompleteOption> = [];

    if (
      !(
        (recipientsValue?.value === ReportRuleType.SERVICE_OWNERS &&
          contentType?.value ===
            ReportRuleContentGroupType.COMPARATIVE_PER_SERVICE) ||
        recipientsValue?.value === ReportRuleType.GROUP_OWNERS
      )
    ) {
      options = [...(groupsOptionList || [])];
    } else {
      options = groupsOptionList
        ? groupsOptionList.filter((option) => option.value !== 'All')
        : [];
    }

    return optionDataHandler.sortOptionList(options, 'desc', 'label');
  }, [groupsOptionList, recipientsValue?.value, contentType?.value]);

  const renderGroupFieldInput = () => {
    return (
      <Autocomplete
        single={
          !(
            (recipientsValue?.value === ReportRuleType.SERVICE_OWNERS &&
              contentType?.value ===
                ReportRuleContentGroupType.COMPARATIVE_PER_SERVICE) ||
            recipientsValue?.value === ReportRuleType.GROUP_OWNERS
          )
        }
        optionList={allGroupOptions}
        loading={groupsOptionsLoading}
        model="groups"
        onChangeCallBack={onGroupOptionChange}
        classes={{
          root: 'multi-select-field-1 ',
          paper: 'multi-select-field-paper-1',
          inputRoot: errors.groups ? 'input-validation-error-1' : '',
        }}
        enableCheckbox
        values={
          groupsValue?.length
            ? optionDataHandler.sortOptionList(
                groupsValue || [],
                'desc',
                'label'
              )
            : groupsValue
        }
        initialSelectedValues={
          groupsValue?.length
            ? optionDataHandler.sortOptionList(
                groupsValue || [],
                'desc',
                'label'
              )
            : groupsValue
        }
        areOptionsLoaded
      />
    );
  };

  const allServiceOptions = useMemo<Array<AutocompleteOption>>(() => {
    if (servicesOptionList?.length) {
      const options: Array<AutocompleteOption> = servicesOptionList.filter(
        (option) => option.value !== 'All'
      );

      return optionDataHandler.sortOptionList(options, 'desc', 'label');
    }

    return [];
  }, [servicesOptionList]);

  const renderServiceFieldInput = () => {
    const recipients = recipientsValue?.value;
    const type = contentType?.value;

    if (
      recipients === ReportRuleType.SERVICE_OWNERS &&
      type === ReportRuleContentGroupType.RISK
    ) {
      return (
        <InputLabelWrapper
          label={translation(`settings.forms.labels.reportRules.services`)}
        >
          <Autocomplete
            optionList={allServiceOptions}
            loading={servicesOptionsLoading}
            model="services"
            onChangeCallBack={onAutocompleteOptionChange}
            classes={{
              root: 'multi-select-field-1 ',
              paper: 'multi-select-field-paper-1',
              inputRoot: errors.services ? 'input-validation-error-1' : '',
            }}
            enableCheckbox
            values={optionDataHandler.sortOptionList(
              servicesValue || [],
              'desc',
              'label'
            )}
            initialSelectedValues={optionDataHandler.sortOptionList(
              servicesValue || [],
              'desc',
              'label'
            )}
            areOptionsLoaded
          />
        </InputLabelWrapper>
      );
    }
    return <></>;
  };

  const renderRiskReportAdditionalConfiguration = () => {
    if (contentType?.value === ReportRuleContentGroupType.RISK) {
      return (
        <Stack flexDirection="row" gap={3}>
          <InputLabelWrapper
            label={translation(`settings.forms.labels.reportRules.timeFrame`)}
          >
            <Autocomplete
              single
              optionList={optionDataHandler.sortOptionList(
                operationalDashboardFilterOptions,
                'desc',
                'label'
              )}
              model="timeFrame"
              onChangeCallBack={onAutocompleteOptionChange}
              classes={{
                root: 'multi-select-field-1 ',
                paper: 'multi-select-field-paper-1',
                inputRoot: errors.timeFrame ? 'input-validation-error-1' : '',
              }}
              enableCheckbox
              initialSelectedValues={defaultFormValues?.timeFrame}
              areOptionsLoaded
            />
          </InputLabelWrapper>

          <InputLabelWrapper
            label={translation(
              `settings.forms.labels.reportRules.trendMetricsTypes`
            )}
          >
            <Autocomplete
              optionList={optionDataHandler.sortOptionList(
                operationalDashboardTrendMetricsTypeOptions,
                'desc',
                'label'
              )}
              model="trendMetricsTypes"
              onChangeCallBack={onAutocompleteOptionChange}
              classes={{
                root: 'multi-select-field-1 ',
                paper: 'multi-select-field-paper-1',
                inputRoot: errors.trendMetricsTypes
                  ? 'input-validation-error-1'
                  : '',
              }}
              enableCheckbox
              initialSelectedValues={defaultFormValues?.trendMetricsTypes}
              areOptionsLoaded
            />
          </InputLabelWrapper>
        </Stack>
      );
    }

    return <></>;
  };

  return reportRuleLoading ? (
    <div className="notification-rule-view-panel-loading">
      <CircularProgress size={35} />
    </div>
  ) : (
    <div className="notification-rule-view-panel">
      <CommonModalContainer
        handleClose={() => {
          cancelationModalProps?.onClose && cancelationModalProps?.onClose();
        }}
        isOpen={Boolean(cancelationModalProps?.open)}
        title={`Cancel Report Rule ${reportRule ? 'Update' : 'Creation'}`}
      >
        <div className="business-unit-form-modal-body">
          <div className="business-unit-form-modal-description">
            <span>Are you sure you want to cancel this action?</span>
            <span>All data will be lost</span>
          </div>
          <div className="business-unit-form-modal-buttons">
            <Button
              className="base-opus-text-button"
              onClick={() => {
                cancelationModalProps?.onClose &&
                  cancelationModalProps?.onClose();
              }}
            >
              Stay
            </Button>
            <Button
              className="base-opus-text-button"
              onClick={() => {
                cancelationModalProps?.onClose &&
                  cancelationModalProps?.onClose();
                onDrawerClose();
              }}
            >
              Yes
            </Button>
          </div>
        </div>
      </CommonModalContainer>
      <div className="notification-rule-view-panel-header">
        <div className="notification-rule-view-panel-header-icon">
          <OpusSvgIcon type={SVG_ICON_TYPES.LIST_CHECK_ICON} />
        </div>
        <div className="notification-rule-view-panel-header-text">
          <div className="notification-rule-view-panel-header-title">
            {translation(`settings.details.reportRuleDrawerTitle`)}{' '}
            {reportRule ? 'Update' : 'Creation'}
          </div>
          <div className="notification-rule-view-panel-header-description">
            {translation(`settings.details.reportRuleDrawerDescription`)}
          </div>
        </div>
      </div>

      <div className="notification-rule-view-panel-body">
        <form className="notification-rule-view-panel-form">
          <ContentSection
            title=""
            rootClassName="notification-rule-content-section notification-rule-base-content-section"
          >
            <InputLabelWrapper
              label={translation(`settings.forms.labels.reportRules.name`)}
            >
              <input
                type="text"
                className={`text-field-1 ${
                  errors.name ? 'input-validation-error-1' : ''
                }`}
                {...register('name')}
              ></input>
            </InputLabelWrapper>

            <InputLabelWrapper
              label={translation(
                `settings.forms.labels.reportRules.description`
              )}
            >
              <input
                type="text"
                className={`text-field-1`}
                {...register('description')}
              ></input>
            </InputLabelWrapper>
            <Stack flexDirection="row" gap={3}>
              <InputLabelWrapper
                label={translation(`settings.forms.labels.reportRules.type`)}
              >
                <Autocomplete
                  single
                  optionList={optionDataHandler.sortOptionList(
                    reportRuleDashboardTypeOptions,
                    'desc',
                    'label'
                  )}
                  model="contentType"
                  onChangeCallBack={onContentTypeOptionChange}
                  classes={{
                    root: 'multi-select-field-1 ',
                    paper: 'multi-select-field-paper-1',
                    inputRoot: errors.contentType
                      ? 'input-validation-error-1'
                      : '',
                  }}
                  enableCheckbox
                  initialSelectedValues={defaultFormValues?.contentType}
                />
              </InputLabelWrapper>

              <InputLabelWrapper
                label={translation(
                  `settings.forms.labels.reportRules.recipients`
                )}
              >
                <Autocomplete
                  single
                  optionList={optionDataHandler.sortOptionList(
                    filteredReportRuleRecipientsOptions,
                    'desc',
                    'label'
                  )}
                  model="recipients"
                  onChangeCallBack={onRecipientsOptionChange}
                  classes={{
                    root: 'multi-select-field-1 ',
                    paper: 'multi-select-field-paper-1',
                    inputRoot: errors.recipients
                      ? 'input-validation-error-1'
                      : '',
                  }}
                  enableCheckbox
                  values={recipientsValue}
                  initialSelectedValues={recipientsValue}
                  areOptionsLoaded
                />
              </InputLabelWrapper>
            </Stack>

            <Stack flexDirection="row" gap={3}>
              <InputLabelWrapper
                label={translation(`settings.forms.labels.reportRules.scopes`)}
              >
                {renderScopeFieldInput()}
              </InputLabelWrapper>
              <InputLabelWrapper
                label={translation(`settings.forms.labels.reportRules.groups`)}
              >
                {renderGroupFieldInput()}
              </InputLabelWrapper>
            </Stack>

            <Stack flexDirection="row" gap={3}>
              {renderServiceFieldInput()}

              <InputLabelWrapper
                label={translation(`settings.forms.labels.reportRules.domains`)}
              >
                <Autocomplete
                  model="domains"
                  onChangeCallBack={onAutocompleteOptionChange}
                  optionList={
                    findingTypeOptionListLoading
                      ? undefined
                      : optionDataHandler.sortOptionList(
                          findingTypeOptionList,
                          'desc',
                          'label'
                        )
                  }
                  classes={{
                    root: 'multi-select-field-1 ',
                    paper: 'multi-select-field-paper-1',
                    inputRoot: errors.domains ? 'input-validation-error-1' : '',
                  }}
                  enableCheckbox
                  areOptionsLoaded
                  loading={findingTypeOptionListLoading}
                  values={domainsValue}
                  initialSelectedValues={domainsValue}
                />
              </InputLabelWrapper>
            </Stack>

            {renderRiskReportAdditionalConfiguration()}

            <InputLabelWrapper
              label={translation(`settings.forms.labels.reportRules.widgets`)}
            >
              <Autocomplete
                optionList={optionDataHandler.sortOptionList(
                  reportRuleAllWidgetOptions,
                  'desc',
                  'label'
                )}
                model="widgets"
                onChangeCallBack={onAutocompleteOptionChange}
                classes={{
                  root: 'multi-select-field-1 ',
                  paper: 'multi-select-field-paper-1',
                  inputRoot: errors.widgets ? 'input-validation-error-1' : '',
                }}
                enableCheckbox
                initialSelectedValues={defaultFormValues?.widgets}
                values={widgetsValue}
              />
            </InputLabelWrapper>

            <InputLabelWrapper
              label={translation(`settings.forms.labels.reportRules.frequency`)}
            >
              <Autocomplete
                model="frequencyType"
                onChangeCallBack={onAutocompleteOptionChange}
                single
                optionList={reportRuleFrequencyOptions}
                classes={{
                  root: 'multi-select-field-1 ',
                  paper: 'multi-select-field-paper-1',
                  inputRoot: errors.frequencyType
                    ? 'input-validation-error-1'
                    : '',
                }}
                enableCheckbox
                initialSelectedValues={defaultFormValues?.frequencyType}
              />
            </InputLabelWrapper>
            {renderEmailField()}
          </ContentSection>
        </form>
      </div>

      <div className="notification-rule-view-panel-footer">
        <div className="notification-rule-view-panel-footer-validation-error">
          {Object.keys(errors)?.length ? (
            <div className="notification-rule-validation-error-text">
              <OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_EXCLAMATION_ICON} />
              <span>
                {translation(
                  `settings.details.notificationRuleFormRequiredErrorMessage`
                )}
              </span>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="notification-rule-view-panel-footer-buttons">
          <Button
            className="opus-secondary-button"
            onClick={() => {
              cancelationModalProps?.onOpen && cancelationModalProps?.onOpen();
            }}
          >
            Cancel
          </Button>
          <Button className="opus-primary-button" onClick={submitForm}>
            {createReportRuleLoading || updateReportRuleLoading
              ? 'Saving'
              : 'Save'}
          </Button>
        </div>
      </div>
    </div>
  );
};
