import { FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import { Stack } from '@mui/material';
import { getFilterList, setListFilter, setSearchParams } from 'Campaigns/store';
import { useFetchCampaignsFiltersMutation } from 'Campaigns/store/api';
import { CommonDispatch } from 'Common/store';
import useCommonDispatch from 'Common/utils/use-dispatch';
import AdvanceFilter from 'shared/components/SearchFilter/LegacyAdvanceFilter';
import { FilterSearchParamsHandler } from 'shared/handlers/filter-search-params.handler';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { useTranslation } from 'react-i18next';
import {
  FavouritesFilterItem,
  QuickFiltersList,
} from 'shared/components/CommonFavouritesFilter/CommonFavouritesFilter';
import { useAuthUser } from '@frontegg/react';

const filterSearchParamsHandler = new FilterSearchParamsHandler();

export const CampaignsAdvanceFilter: FunctionComponent = () => {
  const { t: translation } = useTranslation();
  const dispatch: CommonDispatch = useCommonDispatch();

  const user = useAuthUser();

  const [urlSearchParams, setUrlSearchParams] = useQueryParams();
  const quickFilterItems = useMemo<QuickFiltersList>(() => {
    return {
      savedFilters: [],
      systemFilters: [
        {
          name: translation('common.quickFilters.assignedToMe', {
            param1: translation('campaigns.title'),
          }),
          id: 'assignee',
          options: [
            {
              type: 'assignees',
              values: [
                {
                  id: user.id,
                  name: user.name,
                },
              ],
            },
          ],
        },
      ],
    };
  }, [user]);

  useEffect(() => {
    const existingSearchParam =
      filterSearchParamsHandler.setSearchParamsForKeys(urlSearchParams, [
        'searchKeyword',
      ]);
    dispatch(
      setSearchParams({
        filter: {
          name: {
            value: existingSearchParam?.searchKeyword,
          },
        },
      })
    );
    return () => {
      dispatch(
        setSearchParams({
          filter: {},
        })
      );
    };
  }, []);

  const onSearch = useCallback((searchInput: string) => {
    let existingParams = filterSearchParamsHandler.setSearchParamsForKeys(
      urlSearchParams,
      ['filter', 'searchKeyword']
    );

    if (searchInput.length) {
      existingParams = {
        ...existingParams,
        searchKeyword: searchInput,
      };
      dispatch(
        setSearchParams({
          filter: {
            name: {
              value: searchInput,
            },
          },
        })
      );
    } else {
      delete existingParams.searchKeyword;
      dispatch(
        setSearchParams({
          filter: {
            name: {},
          },
        })
      );
    }
    setUrlSearchParams(existingParams);
  }, []);

  return (
    <Stack className="campaign-filters-header" direction="row">
      <AdvanceFilter
        quickFilterItems={quickFilterItems}
        searchValue={urlSearchParams.searchKeyword || ''}
        setListFilter={setListFilter}
        getFilterList={getFilterList}
        searchEnabled
        onSearch={onSearch}
        mutationProps={{
          fetchMutation: useFetchCampaignsFiltersMutation,
          params: {},
        }}
      />
    </Stack>
  );
};
