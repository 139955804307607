/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, FunctionComponent } from 'react';
import _ from 'lodash';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { Stack, Button, TextField } from '@mui/material';
import classNames from 'classnames';
import CardWrapper from 'Common/components/CardWrapper';
import Chip from 'shared/components/FilterChip/Chip';
import FilterDropdown, {
  mutationProps,
} from 'shared/components/FilterDropdown/FilterDropdown';
import { MenuItems } from '../../../DashboardFilter/store';
import useCommonDispatch from 'Common/utils/use-dispatch';
import useCommonSelector from 'Common/utils/use-selector';
import { TypeFilter } from 'shared/enums/campaigns.enum';
import OpusSvgIcon from '../IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { FilterSearchParamsHandler } from 'shared/handlers/filter-search-params.handler';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import CommonFavoriteComponent from '../CommonFavouritesFilter';
import {
  FavouritesFilterItem,
  QuickFiltersList,
} from '../CommonFavouritesFilter/CommonFavouritesFilter';

import { AdvanceFilterGetter } from 'shared/hooks/useGetAdvanceFilterGetter';
import { FindingState } from 'shared/fixtures/data/risk-grid.data';
import FormattedMessage from '../FormattedMessage';
import { CustomFilterType } from 'Risk/interfaces/CustomFilterType.enum';

export interface FilterOption {
  id: any;
  name: string;
  checked?: boolean;
}

export interface FilterItem<T = FilterOption> {
  id: string;
  name: string;
  type?: string;
  dateRange?: any;
  numberInput?: number;
  items: Array<T>;
  loaded?: boolean;
  field?: string;
  nested?: boolean;
  keepExistingOptions?: boolean;
  filterItems?: Array<FilterItem<T>>;
  bodyParameters?: any;
  additionalBodyParameters?: any;
  optionGetterId?: AdvanceFilterGetter;
  resetFields?: Array<string>;
  getAdditionalBodyParameters?: (selection: any) => any;
}

type AdvanceFilterElements = 'button';

interface AdvanceFilterProps {
  onSearch: (searchInput: string) => void;
  searchValue?: string;
  searchEnabled?: boolean;
  mutationProps: mutationProps;
  getFilterList: any;
  setListFilter: any;
  quickFilterItems?: QuickFiltersList;
  initialFilterItems?: MenuItems;
  storeSynchronization?: boolean;
  querySynchronization?: boolean;
  labels?: Record<AdvanceFilterElements, string>;
  stateSelector?: any;
  rootClassName?: string;
  saveFilters?: () => void;
  customFilterType?: CustomFilterType;
}

const filterSearchParamsHandler = new FilterSearchParamsHandler();

const AdvanceFilter: FunctionComponent<AdvanceFilterProps> = ({
  onSearch,
  searchValue = '',
  searchEnabled = true,
  mutationProps,
  getFilterList,
  setListFilter,
  quickFilterItems,
  labels,
  stateSelector = useCommonSelector,
  storeSynchronization = true,
  querySynchronization = true,
  rootClassName = '',
  initialFilterItems = [],
  saveFilters = () => {},
  customFilterType,
}) => {
  const [urlSearchParams, setUrlSearchParams, getUrlSearchParams] =
    useQueryParams();

  const { t: translation } = useTranslation();
  const dispatch = useCommonDispatch();
  const allDataMenu: MenuItems = stateSelector(getFilterList);

  const [menu, setMenu] = useState<any>([]);
  const [subMenu, setSubMenu] = useState<any>([]);
  const [nestedSubMenu, setNestedSubMenu] = useState<any>([]);
  const [showNestedSubMenu, setNestedShowSubMenu] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [anchorElEdit, setAnchorElEdit] = useState<{
    [key: number]: HTMLButtonElement | null;
  }>({});
  const [showSubMenu, setShowSubMenu] = useState<boolean>(false);
  const [nestedItem, setNestedItem] = useState<FilterItem | null>(null);
  const [filteredData, setFilteredData] = useState<boolean>(false);
  const [chipData, setChipData] = useState<any>([]);
  const [initializedDefaultFilter, setInitializedDefaultFilter] =
    useState(false);

  let open = Boolean(anchorEl);
  let id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    return () => {
      if (storeSynchronization) {
        dispatch(setListFilter(allDataMenu));
      } else {
        setListFilter(allDataMenu);
      }
    };
  }, []);

  useEffect(() => {
    if (allDataMenu.length === 0 && initialFilterItems.length > 0) {
      if (storeSynchronization) {
        dispatch(setListFilter(initialFilterItems));
      } else {
        setListFilter(initialFilterItems);
      }
    }
  }, [initialFilterItems, allDataMenu]);

  useEffect(() => {
    setMenu(allDataMenu);
    if (_.isArray(subMenu) === false && subMenu) {
      const updatedSubmenu = allDataMenu.filter(
        (item: any) => item.id === subMenu.id
      );
      setSubMenu(updatedSubmenu[0]);
    }

    if (nestedSubMenu && _.isArray(nestedSubMenu) === false) {
      const updatedNestedSubMenu = allDataMenu.filter(
        (item) =>
          item.nested &&
          item.filterItems?.find(
            (filterItem) => filterItem.id && nestedSubMenu.id
          )
      );

      const updatedSubMenuItem = updatedNestedSubMenu
        ?.find((nestedSubMenuItem) => nestedSubMenuItem?.id === nestedItem?.id)
        ?.filterItems?.find((filterItem) => filterItem.id === nestedSubMenu.id);

      setNestedSubMenu(updatedSubMenuItem);
    }
    setChipList();
  }, [allDataMenu]);

  const updateFilterList = () => {
    let filterList = allDataMenu;

    const searchParams = getUrlSearchParams();
    const transformedFilterList =
      filterSearchParamsHandler.populateAdvanceFilterOptionsWithPreselectedInformation(
        searchParams,
        filterList
      );

    if (transformedFilterList.length) {
      if (storeSynchronization) {
        dispatch(
          setListFilter({
            list: transformedFilterList,
            isSelectingItem: true,
          })
        );
      } else {
        setListFilter(transformedFilterList);
      }
    }
  };
  const setChipList = () => {
    const nestedChipArray = allDataMenu
      ?.filter((filterItem) => filterItem.nested)
      .map((filterItem) =>
        filterItem.filterItems?.map((item) => ({
          ...item,
          name: item.name,
        }))
      )
      .flat()
      .map((obj: any) => {
        const filteredArray = obj.items.filter(
          (innerObj: any) => innerObj.checked === true
        );
        return { ...obj, items: filteredArray };
      })
      .filter(
        (e: any) =>
          e.items.length > 0 || e?.dateRange?.length > 0 || !!e?.numberInput
      );

    const newChipArray = allDataMenu
      ?.filter((filterItem) => !filterItem.nested)
      .map((obj: any) => {
        const filteredArray = obj.items.filter(
          (innerObj: any) => innerObj.checked === true
        );
        return { ...obj, items: filteredArray };
      })
      .filter(
        (e: any) =>
          e.items.length > 0 || e?.dateRange?.length > 0 || !!e?.numberInput
      );

    setChipData([...newChipArray, ...nestedChipArray]);
  };

  useEffect(() => {
    updateFilterList();
  }, [urlSearchParams]);

  useEffect(() => {
    let params = getUrlSearchParams();
    if (!initializedDefaultFilter) {
      let parsedFilterParams: any = {};
      let disableDefaultFilter: boolean = false;

      if (params.filter?.length) {
        parsedFilterParams = JSON.parse(params.filter);
      }

      if (params.disableDefaultFilter?.length) {
        disableDefaultFilter = JSON.parse(params.disableDefaultFilter);
      }

      const defaultFilter = disableDefaultFilter
        ? {}
        : {
            state: [
              {
                id: FindingState.ACTIVE,
                name: FindingState.ACTIVE,
              },
            ],
          };

      const filter = JSON.stringify({
        ...defaultFilter,
        ...parsedFilterParams,
      });

      const searchParams = getUrlSearchParams();

      setUrlSearchParams({ ...searchParams, filter: filter });
      setInitializedDefaultFilter(true);
    }
  });

  const handleQuickFilterSelection = (item: FavouritesFilterItem) => {
    const filterKeys = ['filter', 'activeTabId', 'order', 'searchKeyword'];
    const searchParams = getUrlSearchParams();
    const updatedParams = filterSearchParamsHandler.setSearchParamsForKeys(
      searchParams,
      filterKeys
    );

    let quickFilterObject: any = {};
    for (const option of item.options) {
      quickFilterObject[option.type] = [...option.values];
    }
    const filter = JSON.stringify(quickFilterObject);
    setUrlSearchParams({ ...updatedParams, filter: filter });

    let resetFilters = [...allDataMenu];
    resetFilters = resetFilters.map((item) => {
      if (item.nested) {
        return {
          ...item,
          filterItems: item.filterItems?.map((filterItem: FilterItem) => {
            return {
              ...filterItem,
              loaded: filterItem.loaded,
              items: filterItem.loaded
                ? filterItem.items?.map((dataItem) => ({
                    ...dataItem,
                    checked: false,
                  })) || []
                : [],
            };
          }),
        };
      }

      return {
        ...item,
        loaded: item.loaded,
        items: item.loaded
          ? item.items?.map((filterItem) => ({
              ...filterItem,
              checked: false,
            })) || []
          : [],
      };
    });
    const transformedFilterList =
      filterSearchParamsHandler.populateAdvanceFilterOptionsWithPreselectedInformation(
        { ...updatedParams, filter: filter },
        resetFilters
      );

    if (storeSynchronization) {
      dispatch(
        setListFilter({ list: transformedFilterList, isSelectingItem: true })
      );
    } else {
      setListFilter(transformedFilterList);
    }
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setFilteredData(false);
    setShowSubMenu(false);
    setMenu(allDataMenu);
    setNestedItem(null);
    setNestedSubMenu(null);
  };

  const handleCloseEditMenu = () => {
    setAnchorElEdit({});
    setFilteredData(false);
    setShowSubMenu(false);
    setMenu(allDataMenu);
    setNestedItem(null);
    setNestedShowSubMenu(false);
    setNestedSubMenu(null);
  };

  const handleGoBack = () => {
    setShowSubMenu(false);
    setMenu(allDataMenu);
  };

  const handleRemoveChip = (id: string | number, item: any) => {
    if (querySynchronization) {
      filterSearchParamsHandler.handleFilterCategoryRemovalFromSearchParams(
        id as string,
        getUrlSearchParams(),
        setUrlSearchParams
      );
    }

    setAnchorElEdit({});
    const newChipList = chipData.filter((obj: any) => obj.id !== id);
    setChipData(newChipList);
    let updatedArray;

    const nestedItem = menu.find(
      (menuItem: FilterItem) =>
        menuItem.nested &&
        menuItem.filterItems?.find(
          (filterItem: FilterItem) => filterItem.id === id
        )
    );

    if (nestedItem) {
      updatedArray = menu.map((obj: any) => {
        if (
          obj.nested &&
          obj.filterItems.find((filterItem: FilterItem) => filterItem.id === id)
        ) {
          let updatedFilterItems = obj.filterItems?.map(
            (filterItem: FilterItem) => {
              if (filterItem.id === id) {
                return {
                  ...filterItem,
                  items: filterItem.items.map((item: any) => {
                    return { ...item, checked: false };
                  }),
                };
              }
              return filterItem;
            }
          );

          const resetFields = obj.filterItems?.find(
            (filterItem: FilterItem) => filterItem.id === id
          )?.resetFields;

          if (resetFields?.length) {
            updatedFilterItems = updatedFilterItems.map(
              (filterItem: FilterItem) => {
                if (resetFields?.includes(filterItem.id)) {
                  return {
                    ...filterItem,
                    additionalBodyParameters: {},
                    loaded: false,
                  };
                }

                return filterItem;
              }
            );
          }

          return {
            ...obj,
            filterItems: updatedFilterItems,
          };
        }

        return obj;
      });
    } else {
      if (
        item.type === TypeFilter.MULTI_SELECT ||
        item.type === TypeFilter.SINGLE_SELECT
      ) {
        updatedArray = menu.map((obj: any) => {
          if (obj.id === id) {
            return {
              ...obj,
              items: obj.items.map((item: any) => {
                return { ...item, checked: false };
              }),
            };
          }
          return obj;
        });
      } else if (item.type === TypeFilter.DATE_PICKER_RANGE) {
        updatedArray = menu.map((obj: any) => {
          if (obj.id === id) {
            return {
              ...obj,
              dateRange: null,
            };
          }
          return obj;
        });
      } else {
        updatedArray = menu.map((obj: any) => {
          if (obj.id === id) {
            return {
              ...obj,
              numberInput: null,
            };
          }
          return obj;
        });
      }
    }

    if (storeSynchronization) {
      dispatch(setListFilter({ list: updatedArray, isSelectingItem: true }));
    } else {
      setListFilter(updatedArray);
    }
  };

  const clearAllChips = () => {
    if (querySynchronization) {
      filterSearchParamsHandler.handleClearParamFromSearchParams(
        'filter',
        getUrlSearchParams(),
        setUrlSearchParams
      );
    }

    const updatedArray = menu.map((obj: any) => {
      if (obj.nested) {
        return {
          ...obj,
          filterItems: obj.filterItems?.map((filterItem: FilterItem) => ({
            ...filterItem,
            items: filterItem.items.map((item: any) => ({
              ...item,
              checked: false,
            })),
          })),
        };
      } else {
        let updatedItem = { ...obj };

        if (obj.dateRange) {
          updatedItem.dateRange = null;
        }

        if (obj.numberInput) {
          updatedItem.numberInput = null;
        }

        updatedItem.items = obj.items.map((item: any) => ({
          ...item,
          checked: false,
        }));

        return updatedItem;
      }
    });

    if (storeSynchronization) {
      dispatch(setListFilter({ list: updatedArray, isSelectingItem: true }));
    } else {
      setListFilter(updatedArray);
    }
  };

  const handleFindItem = (id: string | number, data: []) => {
    return data.find((e: any) => e.id === id);
  };

  const handleSelectMenuItem = (id: any, data: any) => {
    const item = handleFindItem(id, data) as any;

    if (item?.nested) {
      setNestedItem(item);
    } else {
      setSubMenu(item);
      setShowSubMenu(true);
    }
  };

  const handleNestedSelectMenuItem = (id: any, data: any) => {
    const item = allDataMenu
      .find((allDataItem) => allDataItem.id === nestedItem?.id)
      ?.filterItems?.find((filterItem) => filterItem.id === id);

    if (item) {
      setNestedShowSubMenu(true);
      setNestedSubMenu(item);
    }
  };

  const selectSubMenuItem = (
    id: any,
    data: any,
    menuItems: any,
    idToRemove?: any
  ) => {
    if (querySynchronization) {
      const searchParams = getUrlSearchParams();

      filterSearchParamsHandler.handleAdvanceFilterOptionSelection(
        data,
        id,
        searchParams,
        setUrlSearchParams
      );
    }

    const updatedSubmenu = menuItems.filter(
      (item: any) => item.id === data.id
    )[0];

    let updatedObject;

    if (data.type === TypeFilter.DATE_PICKER_RANGE) {
      updatedObject = {
        ...updatedSubmenu,
        dateRange: id,
      };
    } else if (data.type === TypeFilter.NUMBER) {
      updatedObject = {
        ...updatedSubmenu,
        numberInput: id,
      };
    } else if (data.type === TypeFilter.SINGLE_SELECT) {
      updatedObject = {
        ...updatedSubmenu,
        items: updatedSubmenu.items.map((item: any) => {
          if (item.id === id.id) {
            return {
              ...item,
              checked: !id.checked,
            };
          }
          return {
            ...item,
            checked: false,
          };
        }),
      };
    } else {
      updatedObject = {
        ...updatedSubmenu,
        items: updatedSubmenu.items.map((item: any) => {
          if (item.id === id.id) {
            return {
              ...item,
              checked: !id.checked,
            };
          }
          return item;
        }),
      };
    }

    return updatedObject;
  };

  const handleSelectSubMenuItem = (id: any, data: any) => {
    const updatedObject = selectSubMenuItem(id, data, menu);

    updateReduxData(updatedObject);
  };

  const handleSelectNestedSubMenuItem = (id: any, data: any) => {
    let updatedNestedItem: any = allDataMenu.find(
      (filterItem) =>
        filterItem.nested &&
        filterItem.filterItems?.find((item: FilterItem) => item.id === data.id)
    );

    const isUnchecking = data.items?.find(
      (item: FilterOption) => item.id === id.id && item.checked
    );

    const updatedObject = selectSubMenuItem(
      id,
      data,
      updatedNestedItem?.filterItems
    );

    if (updatedObject.resetFields) {
      updatedNestedItem = {
        ...updatedNestedItem,
        filterItems: updatedNestedItem.filterItems?.map(
          (filterItem: FilterItem) => {
            if (updatedObject.resetFields?.includes(filterItem?.id)) {
              filterSearchParamsHandler.handleFilterCategoryRemovalFromSearchParams(
                filterItem?.id as string,
                getUrlSearchParams(),
                setUrlSearchParams
              );

              return {
                ...filterItem,
                loaded: false,
                items: isUnchecking ? filterItem.items : [],
                additionalBodyParameters: isUnchecking
                  ? {}
                  : filterItem?.getAdditionalBodyParameters
                  ? filterItem.getAdditionalBodyParameters(id)
                  : {},
              };
            }

            return filterItem;
          }
        ),
      };
    }

    updateReduxData({
      ...updatedNestedItem,
      filterItems: updatedNestedItem?.filterItems?.map(
        (filterItem: FilterItem) => {
          if (filterItem.id === updatedObject.id) {
            return updatedObject;
          }
          return filterItem;
        }
      ),
    });
  };

  const handleSelectAllSubMenuItem = (filterItem: FilterItem) => {
    const updatedSubmenu = menu.filter(
      (item: any) => item.id === filterItem.id
    )[0];

    let updatedObject;

    const allSelected: boolean = updatedSubmenu.items.every(
      (item: FilterOption) => item.checked
    );

    updatedObject = {
      ...updatedSubmenu,
      items: updatedSubmenu.items.map((item: FilterOption) => ({
        ...item,
        checked: !allSelected,
      })),
    };

    updateReduxData(updatedObject);

    if (querySynchronization) {
      const searchParams = getUrlSearchParams();

      filterSearchParamsHandler.handleAdvanceFilterAllOptionsSelection(
        filterItem,
        allSelected,
        searchParams,
        setUrlSearchParams
      );
    }
  };

  const handleSelectAllNestedSubMenuItem = (filterItem: FilterItem) => {
    const parentItem = menu.find(
      (item: any) =>
        item.nested &&
        item.filterItems?.find((item: FilterItem) => item.id === filterItem.id)
    );

    const childItem = parentItem?.filterItems?.find(
      (item: FilterItem) => item.id === filterItem.id
    );

    let updatedObject;

    const allSelected: boolean = childItem.items.every(
      (item: FilterOption) => item.checked
    );

    updatedObject = {
      ...parentItem,
      filterItems: parentItem?.filterItems?.map((item: FilterItem) => {
        if (item.id === filterItem.id) {
          return {
            ...filterItem,
            items: item.items.map((item: FilterOption) => ({
              ...item,
              checked: !allSelected,
            })),
          };
        }

        return item;
      }),
    };

    updateReduxData(updatedObject);

    if (querySynchronization) {
      const searchParams = getUrlSearchParams();

      filterSearchParamsHandler.handleAdvanceFilterAllOptionsSelection(
        filterItem,
        allSelected,
        searchParams,
        setUrlSearchParams
      );
    }
  };

  const updateReduxData = (item: any) => {
    let updatedArray = menu.map((obj: any) => {
      if (obj.id === item.id) {
        return { ...item };
      }
      return obj;
    });

    if (storeSynchronization) {
      dispatch(setListFilter({ list: updatedArray, isSelectingItem: true }));
    } else {
      setListFilter(updatedArray);
    }
  };

  const editChip = (
    event: React.MouseEvent<HTMLButtonElement>,
    itemData: any
  ) => {
    const isNestedItem = menu.find(
      (item: any) =>
        item.nested &&
        item.filterItems.find(
          (filterItem: FilterItem) => filterItem.id === itemData.id
        )
    );

    if (isNestedItem) {
      const menuItem = isNestedItem?.filterItems?.find(
        (filterItem: FilterItem) => filterItem.id === itemData.id
      );

      setSubMenu(null);
      setNestedSubMenu(menuItem);
    } else {
      const updatedSubmenu = menu.filter(
        (item: any) => item.id === itemData.id
      );

      setNestedSubMenu(null);
      setSubMenu(updatedSubmenu[0]);
    }
    setAnchorElEdit({ [itemData.id]: event.currentTarget });
  };

  const renderNestedMenu = () => {
    if (nestedItem) {
      const nestedAnchorEl = document.querySelector(
        '.main-category-filter-dropdown-container .MuiPaper-root'
      );

      const nestedStateItem = allDataMenu.find(
        (filterItem) => filterItem.id === nestedItem.id
      );

      return (
        <FilterDropdown
          id={id}
          mutationProps={mutationProps}
          anchorEl={nestedAnchorEl}
          open={true}
          handleClose={handleCloseMenu}
          listItems={nestedItem.filterItems}
          setListItems={setMenu}
          data={nestedStateItem?.filterItems}
          handleItemSelect={handleNestedSelectMenuItem}
          filteredData={filteredData}
          setFilteredData={setFilteredData}
          className="nested-category-filter-dropdown-container"
          storeSynchronization={storeSynchronization}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        />
      );
    }

    return <></>;
  };

  const renderNestedSubMenu = () => {
    if (showNestedSubMenu) {
      const nestedSubMenuAnchorEl = document.querySelector(
        '.nested-category-filter-dropdown-container .MuiPaper-root'
      );

      return (
        <FilterDropdown
          mutationProps={mutationProps}
          id={id}
          setListFilter={setListFilter}
          isSubMenu={true}
          anchorEl={nestedSubMenuAnchorEl}
          open={open}
          handleClose={() => {
            setNestedItem(null);
            setAnchorEl(null);
            setNestedShowSubMenu(false);
            setNestedSubMenu(null);
          }}
          listItems={nestedSubMenu}
          mainMenuItemsSort="ASC"
          handleGoBack={() => {
            setNestedShowSubMenu(false);
            setNestedSubMenu(null);
          }}
          setListItems={setNestedSubMenu}
          data={allDataMenu}
          handleItemSelect={handleSelectNestedSubMenuItem}
          filteredData={filteredData}
          setFilteredData={setFilteredData}
          className="sub-nested-category-filter-dropdown-container"
          storeSynchronization={storeSynchronization}
          handleSelectAll={handleSelectAllNestedSubMenuItem}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        />
      );
    }

    return <></>;
  };

  return (
    <CardWrapper
      sx={{ width: 'inherit' }}
      className={`advanced-filter-container ${rootClassName}`}
    >
      <Stack
        direction="row"
        flexWrap="wrap"
        spacing={4}
        justifyContent="space-between"
      >
        <div className="filter-container">
          <>
            {quickFilterItems && (
              <>
                <CommonFavoriteComponent
                  items={quickFilterItems}
                  onSelect={handleQuickFilterSelection}
                  filterType={customFilterType}
                />
              </>
            )}
            {searchEnabled && (
              <TextField
                className={classNames(['filter-input filter-main-input'])}
                placeholder={translation(`common.searchPlaceholder`)}
                onChange={(e) => {
                  onSearch(e.target.value);
                }}
                InputProps={{
                  className: 'search-filter-input',
                  startAdornment: (
                    <OpusSvgIcon type={SVG_ICON_TYPES.MAGNIFYING_GLASS_ICON} />
                  ),
                }}
                value={searchValue}
              />
            )}
            <Button
              className={classNames([
                'opus-filter-button add-filter-btn',
                { active: open },
              ])}
              aria-describedby={id}
              onClick={handleOpenMenu}
            >
              <AddIcon className="add-icon" />
              <span
                className="label-1"
                style={{ color: '#7547DC', marginLeft: '8px' }}
              >
                {labels?.button?.length ? labels?.button : 'Add Filter'}
              </span>
            </Button>
            {!showSubMenu ? (
              <FilterDropdown
                id={id}
                mutationProps={mutationProps}
                anchorEl={anchorEl}
                open={open}
                handleClose={handleCloseMenu}
                listItems={menu}
                mainMenuItemsSort="ASC"
                setListItems={setMenu}
                data={allDataMenu}
                handleItemSelect={handleSelectMenuItem}
                filteredData={filteredData}
                setFilteredData={setFilteredData}
                className="main-category-filter-dropdown-container"
                storeSynchronization={storeSynchronization}
              />
            ) : (
              <FilterDropdown
                mutationProps={mutationProps}
                id={id}
                setListFilter={setListFilter}
                isSubMenu={true}
                anchorEl={anchorEl}
                open={open}
                handleClose={handleCloseMenu}
                listItems={subMenu}
                mainMenuItemsSort="ASC"
                handleGoBack={handleGoBack}
                setListItems={setSubMenu}
                data={allDataMenu}
                handleItemSelect={handleSelectSubMenuItem}
                filteredData={filteredData}
                setFilteredData={setFilteredData}
                className="sub-category-filter-dropdown-container"
                storeSynchronization={storeSynchronization}
                handleSelectAll={handleSelectAllSubMenuItem}
              />
            )}

            {renderNestedMenu()}
            {renderNestedSubMenu()}
          </>
          <div className="filter-info">
            {chipData.length > 0 && (
              <div className="chip-list">
                {chipData.map((e: any, index: number) => {
                  return (
                    <div key={e.id}>
                      <Chip
                        key={e.id}
                        item={e}
                        editChip={editChip}
                        removeChip={() => handleRemoveChip(e.id, e)}
                        aria-describedby={`chip-edit-popover-${e.id}`}
                      />
                      <FilterDropdown
                        mutationProps={mutationProps}
                        isEdit={true}
                        id={`chip-edit-popover-${e.id}`}
                        isSubMenu={true}
                        anchorEl={anchorElEdit[e.id]}
                        open={Boolean(anchorElEdit[e.id])}
                        handleClose={handleCloseEditMenu}
                        listItems={nestedSubMenu || subMenu}
                        setListFilter={setListFilter}
                        handleGoBack={handleGoBack}
                        setListItems={
                          nestedSubMenu ? setNestedSubMenu : setSubMenu
                        }
                        data={allDataMenu}
                        handleItemSelect={
                          nestedSubMenu
                            ? handleSelectNestedSubMenuItem
                            : handleSelectSubMenuItem
                        }
                        filteredData={filteredData}
                        setFilteredData={setFilteredData}
                        className="chip-category-filter-dropdown-container"
                        storeSynchronization={storeSynchronization}
                        handleSelectAll={
                          nestedSubMenu
                            ? handleSelectAllNestedSubMenuItem
                            : handleSelectAllSubMenuItem
                        }
                      />
                    </div>
                  );
                })}
              </div>
            )}
            {chipData.length > 0 && (
              <div className="save-text label-4" onClick={saveFilters}>
                <FormattedMessage
                  id="common.save"
                  defaultMessage="Save"
                  capitalize
                />
              </div>
            )}
            {chipData.length > 0 && (
              <div className="clear-text label-4" onClick={clearAllChips}>
                <FormattedMessage
                  capitalize
                  id="common.clear"
                  defaultMessage="Clear"
                />
              </div>
            )}
          </div>
        </div>
      </Stack>
    </CardWrapper>
  );
};

export default AdvanceFilter;
