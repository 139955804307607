import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { OptionListType } from '../OptionList/OptionList';
import { FilterOption } from 'shared/models/data/data-filter.model';
import { useTranslation } from 'react-i18next';
import { useFetchDataBasedOnParametersMutation } from 'shared/store/api';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { CircularProgress, TextField } from '@mui/material';
import { debounce } from 'lodash';
import OptionListItem from '../OptionListItem';
import BaseOptionList from '../BaseOptionList';

interface StaticOptionListProps extends BaseComponentProps {
  title: string;
  mode: OptionListType;
  options: Array<FilterOption>;
  onOptionsSelect?: (
    options: Array<FilterOption>,
    isAllSelected?: boolean
  ) => void;
  onAllSelect?: (options: Array<FilterOption>) => void;
  selectedOptions?: Array<FilterOption>;
  actionHandlers?: {
    goBackHandler?: () => void;
  };
  allSelected?: boolean;
}

export const StaticOptionList: FunctionComponent<StaticOptionListProps> = ({
  title,
  mode,
  options,
  onOptionsSelect,
  selectedOptions,
  actionHandlers,
  onAllSelect,
  allSelected,
}) => {
  const [filteredOptions, setFilteredOptions] =
    useState<Array<FilterOption>>(options);

  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value;
    setFilteredOptions(() => {
      return options.filter((option) =>
        (option.label || option.value)
          ?.toLowerCase()
          .includes(searchValue.toLowerCase().trim())
      );
    });
  };

  const handleOptionsSelect = (
    selectedOptions: Array<FilterOption>,
    allSelected?: boolean
  ) => {
    onOptionsSelect &&
      onOptionsSelect(
        selectedOptions,
        selectedOptions.length === options.length
      );
  };

  const handleAllSelect = (allOptions: Array<FilterOption>) => {
    if (filteredOptions.length < options.length) {
      const everyOptionSelected =
        selectedOptions?.length &&
        selectedOptions.every((selectedOption) =>
          filteredOptions.find(
            (filteredOption) => filteredOption.value === selectedOption.value
          )
        );

      onOptionsSelect &&
        onOptionsSelect(everyOptionSelected ? [] : filteredOptions, false);
    } else {
      onAllSelect && onAllSelect(allOptions);
    }
  };

  return (
    <BaseOptionList
      title={title}
      options={filteredOptions}
      mode={mode}
      onOptionsSelect={handleOptionsSelect}
      selectedOptions={selectedOptions}
      actionHandlers={actionHandlers}
      onAllSelect={handleAllSelect}
      allSelected={selectedOptions?.length === filteredOptions.length}
      onSearch={onSearch}
      enableAllSelection
    />
  );
};
