import moment from 'moment';
import RiskChip from 'Risk/components/RiskChip';
import TextOverflow from 'shared/components/TextOverflowComponent';
import {
  OverflowDirection,
  OverflowTextDisplay,
} from 'shared/components/TextOverflowComponent/TextOverflow';
import { ResourceCell } from 'shared/components/ResourceCell/ResourceCell';
import { CommonDataGridUtilityCell } from 'shared/components/CommonSimpleDataGrid/enums/common-data-grid.enum';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import CommonChip from 'shared/components/CommonChip';
import Status from 'Common/components/Status';
import CommonIconCell from 'shared/components/CommonIconCell';
const findingsCampaignGridDateFormat = 'MMM DD, YYYY [at] HH:mmA';

export class CampaignFindingsTableColumns {
  static getCampaignFindingsTableColumns(
    translation: any,
    setShouldIgnoreRowClick?: any
  ): Array<ColDef | ColGroupDef> {
    return [
      {
        field: CommonDataGridUtilityCell.DATA_GRID_CHECKBOX_CELL,
        headerName: '',
        sortable: false,
        resizable: false,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        lockPinned: true,
        lockPosition: true,
        lockVisible: true,
        headerClass: 'data-grid-checkbox-header',
        width: 10,
      },
      {
        field: 'title',
        headerName: 'Risk Name',
        initialFlex: 4,
        cellRenderer: (params: any) => {
          return (
            <TextOverflow
              direction={OverflowDirection.WIDTH}
              type={OverflowTextDisplay.ELLIPSIS}
            >
              {params?.data?.title}
            </TextOverflow>
          );
        },
      },
      {
        field: 'riskScore',
        headerName: translation('campaigns.findings.table.riskScore'),
        sortable: true,
        initialFlex: 2,
        cellRenderer: (params: any) => (
          <RiskChip riskScore={params?.value}></RiskChip>
        ),
      },
      {
        field: 'resourceName',
        headerName: translation('campaigns.findings.table.resourceName'),
        sortable: true,
        initialFlex: 3,
        cellRenderer: (params: any) => (
          <ResourceCell
            type={params.data?.resourceType}
            name={params?.data?.resourceName}
            iconUrl={params?.data?.resourceTypeLogoUrl}
          />
        ),
      },

      {
        field: 'businessUnitName',
        headerName: 'Service',
        initialFlex: 2,
        cellRenderer: (params: any) => {
          return params?.data.businessUnitName ? (
            <CommonChip label={params?.data?.businessUnitName} />
          ) : (
            <div>-</div>
          );
        },
      },
      {
        field: 'owner',
        headerName: translation('campaigns.findings.table.resourceOwner'),
        sortable: true,
        initialFlex: 2,
        cellRenderer: (params: any) => (
          <TextOverflow
            direction={OverflowDirection.WIDTH}
            type={OverflowTextDisplay.ELLIPSIS}
            maxWidth={160}
            className="body-2"
          >
            {params?.data?.owner}
          </TextOverflow>
        ),
      },

      {
        field: 'status',
        headerName: translation('campaigns.findings.table.findingStatus'),
        sortable: true,
        initialFlex: 3,
        cellRenderer: (params: any) =>
          params?.data?.status && (
            <Status readonly label={params?.data?.status} />
          ),
      },

      {
        field: 'ticketName',
        headerName: translation('campaigns.findings.table.ticket'),
        sortable: true,
        initialFlex: 2,
        cellRenderer: (params: any) =>
          params?.data?.ticketName ? (
            <CommonIconCell
              iconUrl={params?.data?.ticketApplicationLogo}
              label={params?.data.ticketName}
              linkUrl={params?.data?.ticketLink}
              maxWidth={130}
            />
          ) : (
            <div>-</div>
          ),
      },

      {
        field: 'ticketStatus',
        headerName: translation('campaigns.findings.table.ticketStatus'),
        sortable: true,
        initialFlex: 2,
        cellRenderer: (params: any) =>
          params?.data?.ticketStatus && (
            <Status label={params?.data?.ticketStatus} readonly />
          ),
      },

      {
        field: 'lastUpdate',
        headerName: translation('campaigns.findings.table.lastUpdate'),
        sortable: true,
        initialFlex: 3,
        cellRenderer: (params: any) => {
          return (
            params?.data?.lastUpdate &&
            moment(params?.data?.lastUpdate).format(
              findingsCampaignGridDateFormat
            )
          );
        },
      },
    ];
  }
}
