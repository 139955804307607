import { Chip } from '@mui/material';
import { FunctionComponent } from 'react';
import { BusinessPriorityChipProps } from 'shared/models/props/business-priority-chip-props.model';
import {
  BusinessPriority,
  BusinessPriorityColorMapper,
} from './businessPriority';

export const BusinessPriorityChip: FunctionComponent<
  BusinessPriorityChipProps
> = ({ type = BusinessPriority.LOW }) => {
  const currentBusinessPriority =
    BusinessPriority[type.toUpperCase() as keyof typeof BusinessPriority];
  const primaryBusinessPriorityColor =
    BusinessPriorityColorMapper[type.toUpperCase()];

  const borderBusinessPriorityColor =
    BusinessPriorityColorMapper[`${type.toUpperCase()}_SECONDARY`];

  return (
    <Chip
      className="business-priority-chip"
      label={currentBusinessPriority}
      variant="outlined"
      sx={{
        borderRadius: 1,
        backgroundColor: '#fff',
        color: primaryBusinessPriorityColor,
        borderColor: borderBusinessPriorityColor,
      }}
    />
  );
};
