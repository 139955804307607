import { FunctionComponent } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import CampaignFindingsAdvancedDataGrid from '../CampaignFindingsAdvancedDataGrid';

interface CampaignFindingsViewProps extends BaseComponentProps {}

export const CampaignFindingsView: FunctionComponent<
  CampaignFindingsViewProps
> = () => {
  return <CampaignFindingsAdvancedDataGrid />;
};
