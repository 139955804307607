import { useFetchUsersMutation } from 'Auth/store/api';
import { AutocompleteOption } from 'FindingDetails/store/api';
import { useFetchTeamsMutation } from 'Settings/store/api';
import { useEffect, useMemo } from 'react';
import { TeamDataHandler } from 'shared/handlers/team-data.handler';
import { UserDataHandler } from 'shared/handlers/user-data.handler';

type NotificationSettingsOptionList = Record<
  'emailOptionList' | 'teamOptionList',
  Array<AutocompleteOption>
>;

const userDataHandler = new UserDataHandler();
const teamDataHandler = new TeamDataHandler();

export function useFetchNotificationSettingsOptionItems(): NotificationSettingsOptionList {
  const [fetchUsers, { data: userList }] = useFetchUsersMutation();
  const [fetchTeams, { data: teamList }] = useFetchTeamsMutation();

  useEffect(() => {
    fetchUsers();
    fetchTeams();
  }, []);

  const userEmailList = useMemo(() => {
    if (userList) {
      return userDataHandler.transformUserListToAutocompleteEmailList(userList);
    }

    return [];
  }, [userList]);

  const teamOptionList = useMemo(() => {
    if (teamList) {
      return teamDataHandler.transformTeamListToAutocompleteTeamList(teamList);
    }

    return [];
  }, [teamList]);

  return {
    emailOptionList: userEmailList,
    teamOptionList: teamOptionList,
  };
}
