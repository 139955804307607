import { FunctionComponent, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress, Grid } from '@mui/material';
import { ActiveCampaign } from 'shared/models/data/active-campaign.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { useGetCampaignMetricsQuery } from 'Campaigns/store/api';
import { useOnScreen } from 'shared/hooks/useOnScreen';
import DashboardBusinessPriorityChip from '../DashboardBusinessPriorityChip';

interface ActiveCampaignCarouselCardProps
  extends BaseComponentProps,
    ActiveCampaign {
  lazyLoading?: boolean;
}

export const ActiveCampaignCarouselCard: FunctionComponent<
  ActiveCampaignCarouselCardProps
> = ({ id, businessPriority, name, lazyLoading = true }) => {
  const navigate = useNavigate();
  const activeCampaignCardRef = useRef(null);

  const elementIsOnScreen = useOnScreen(activeCampaignCardRef);

  const { data: campaignMetrics, isLoading: campaignMetricsLoading } =
    useGetCampaignMetricsQuery(id, {
      skip: lazyLoading && !elementIsOnScreen,
    });

  const onNavigateToCampaignDetails = () => {
    navigate(`/campaigns/${id}`);
  };

  return (
    <div
      className="active-campaign-carousel-card-container clickable-item"
      ref={activeCampaignCardRef}
      onClick={onNavigateToCampaignDetails}
    >
      <Grid container gap={'4px'}>
        <Grid
          item
          xs={12}
          className="active-campaign-carousel-card-title"
          title={name}
        >
          <h5>{name}</h5>
        </Grid>
        <Grid item className="active-campaign-carousel-card-business-priority">
          <DashboardBusinessPriorityChip businessPriority={businessPriority} />
        </Grid>
      </Grid>
      {campaignMetricsLoading ? (
        <CircularProgress size={18} />
      ) : (
        <>
          <div className="active-campaign-carousel-card-completion-rate-container">
            <div className="active-campaign-carousel-card-completion-rate-text-container">
              <b className="active-campaign-carousel-card-completion-rate">
                {campaignMetrics?.completionRate}%
              </b>
              <p className="active-campaign-carousel-card-completion-text">
                Completion Rate
              </p>
            </div>
            <div
              className={`active-campaign-carousel-card-completion-rate-indicator-container active-campaign-carousel-card-completion-rate-${campaignMetrics?.burnRate}-indicator-container `}
            >
              <div
                style={{
                  width: `${campaignMetrics?.completionRate}%`,
                }}
                className="active-campaign-carousel-card-completion-rate-inner-indicator-container"
              ></div>
            </div>
          </div>
          <div className="active-campaign-carousel-card-days-remaining-container">
            <OpusSvgIcon type={SVG_ICON_TYPES.SIMPLE_CALENDAR_ICON} />
            <div className="active-campaign-carousel-card-days-counter">
              {campaignMetrics?.remainingDays}
            </div>
            <div className="active-campaign-carousel-card-days-text body-2">
              Days Remaining
            </div>
          </div>
        </>
      )}
    </div>
  );
};
