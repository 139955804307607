import { AutocompleteOption } from 'FindingDetails/store/api';
import {
  RuleCategory,
  RuleProperty,
  RuleStatus,
} from 'Settings/interfaces/RiskCustomizationConfig';
import { BusinessUnitRole } from 'shared/enums/business-unit.enum';

export interface NotificationRule {
  id?: string;
  name: string;
  description?: string;
  status?: RuleStatus;
  triggers: NotificationRuleTrigger[];
  properties: RuleProperty[];
  actions: NotificationRuleAction[];
}

export enum NotificationSupportedRoles {
  BUChannel = 'Service Channel',
  ParentChannel = 'Parent Channel',
  ResourceOwner = 'Resource Owner',
  TicketOwner = 'Ticket Assignee',
}

export enum NotificationRuleTrigger {
  FINDING_CREATED = 'finding_created',
  FINDING_CLOSED = 'finding_closed',
  FINDING_SUPPRESSED = 'finding_suppressed',
  FINDING_IN_PROGRESS = 'finding_in_progress',
  FINDING_IN_REVIEW = 'finding_in_review',
  EXCEPTION_REQUESTED = 'exception_requested',
  EXCEPTION_APPROVED = 'exception_approved',
  EXCEPTION_EXPIRED = 'exception_expired',
  FINDING_SLA_WARNING = 'finding-sla-warning',
  FINDING_SLA_BREACHED = 'finding-sla-breached',
  TICKET_CREATED = 'ticket-created',
}

export interface NotificationRuleEmailModel {
  recipients: string[];
  roles?: NotificationSupportedRoles[];
}

export interface SelectOptionModel {
  value: string;
  label: string;
}

export interface NotificationRuleMessageModel {
  recipients: SelectOptionModel[];
  roles?: NotificationSupportedRoles[];
}

export interface NotificationRuleLabelsModel {
  labels: string[];
}

export type NotificationRuleActionData =
  | NotificationRuleEmailModel
  | NotificationRuleMessageModel
  | NotificationRuleTicketModel
  | NotificationRuleLabelsModel;

export interface NotificationRuleAction {
  type: NotificationRuleActionType;
  data?: NotificationRuleActionData;
}

export enum NotificationRuleActionType {
  EMAIL = 'email',
  TICKET = 'ticket',
  MESSAGE = 'message',
  RESOURCE_OWNER = 'resource-owner',
  LABELS = 'labels',
}

export enum NotificationRuleTicketAction {
  OPEN_TICKET = 'open-ticket',
  CLOSE_TICKET = 'close-ticket',
}

export interface NotificationRuleTicketModel {
  ticketActions: NotificationRuleTicketAction[];
  projectId?: string;
}

export interface NotificationRuleProperty {
  type: NotificationRulePropertyType;
  operator: NotificationRuleOperatorType;
  category?: RuleCategory;
  values: any;
}

export enum NotificationRulePropertyType {
  BUSINESS_UNIT_ID = 'businessUnitId',
  BUSINESS_UNIT_ENVIRONMENT_ID = 'businessUnitEnvironmentId',
  TAG = 'tag',
  SEVERITY = 'severity',
  BUSINESS_UNIT_ENVIRONMENT_TYPE = 'businessUnitEnvironmentType',
}

const NotificationSupportedRolesMapping: Record<
  string,
  BusinessUnitRole | NotificationSupportedRoles
> = {
  ApplicationOwner: BusinessUnitRole.APPLICATION_OWNER,
  SecurityChampion: BusinessUnitRole.SECURITY_CHAMPION,
  DevopsOwner: BusinessUnitRole.DEVOPS_OWNER,
  AppSecOwner: BusinessUnitRole.APPSEC_OWNER,
  BUChannel: NotificationSupportedRoles.BUChannel,
  ParentChannel: NotificationSupportedRoles.ParentChannel,
  ResourceOwner: NotificationSupportedRoles.ResourceOwner,
  TicketOwner: NotificationSupportedRoles.TicketOwner,
};

export enum NotificationRuleOperatorType {
  IN = 'in',
  IN_RANGE = 'in-range',
  HAS_ACCESS = 'has-access',
}

export enum TriggerRulePolicyType {
  EVENT_SOURCE_SEVERITY = 'EVENT_SOURCE_SEVERITY',
  BUSINESS_UNIT = 'BUSINESS_UNIT',
}

export enum TriggerRuleActionType {
  SEND_AN_EMAIL = 'SEND_AN_EMAIL',
}

export const UserRoleOptionsList: Array<AutocompleteOption> = [
  {
    id: NotificationSupportedRolesMapping.ApplicationOwner,
    value: NotificationSupportedRolesMapping.ApplicationOwner,
  },
  {
    id: NotificationSupportedRolesMapping.SecurityChampion,
    value: NotificationSupportedRolesMapping.SecurityChampion,
  },
  {
    id: NotificationSupportedRolesMapping.DevopsOwner,
    value: NotificationSupportedRolesMapping.DevopsOwner,
  },
  {
    id: NotificationSupportedRolesMapping.ResourceOwner,
    value: NotificationSupportedRolesMapping.ResourceOwner,
  },
  {
    id: NotificationSupportedRolesMapping.AppSecOwner,
    value: NotificationSupportedRolesMapping.AppSecOwner,
  },
];

export const TeamRoleOptionsList: Array<AutocompleteOption> = [
  ...UserRoleOptionsList,
  {
    id: NotificationSupportedRolesMapping.BUChannel,
    value: NotificationSupportedRolesMapping.BUChannel,
  },
  {
    id: NotificationSupportedRolesMapping.ParentChannel,
    value: NotificationSupportedRolesMapping.ParentChannel,
  },
  {
    id: NotificationSupportedRoles.TicketOwner,
    value: NotificationSupportedRoles.TicketOwner,
  },
];
