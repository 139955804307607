import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { GridSelectionModel } from '@mui/x-data-grid';
import { useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import {
  CampaignsStore,
  getCampaignFindingsState,
  getFilterList,
  getIacFindingsTableSelectionProps,
  getSelectionList,
  setCampaignFindingsPage,
  setCampaignFindingsPageSize,
  setIacFindingsTableSelection,
  setListFilter,
  setSearchParams,
} from 'Campaigns/store';
import { useFetchFindingsByCampaignMutation } from 'Campaigns/store/api';
import { OrderState } from 'Common/utils/sort';
import useCommonDispatch from 'Common/utils/use-dispatch';
import useCommonSelector from 'Common/utils/use-selector';
import NoDataBackdrop from 'shared/components/NoDataBackdrop';
import { AdvanceFilterHandler } from 'shared/handlers/advance-filter-data.handler';
import { FilterSearchParamsHandler } from 'shared/handlers/filter-search-params.handler';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { CampaignFindingsTableColumns } from './CampaignFindingsTableColumns/CampaignFindingsTableColumns';
import CommonSimpleDataGrid from 'shared/components/CommonSimpleDataGrid';
import { GetRowIdParams, RowClickedEvent } from 'ag-grid-community';
import FindingCodeEventDrawerComplex from 'shared/components/FindingCodeEventDrawerComplex/FindingCodeEventDrawerComplex';

interface FindingsRiskTableProps extends BaseComponentProps {
  gridRef?: any;
}
const filterSearchParamsHandler = new FilterSearchParamsHandler();
const advanceFilterDataHandler = new AdvanceFilterHandler();

export const CampaignFindingsTable: FunctionComponent<
  FindingsRiskTableProps
> = ({ gridRef }) => {
  let [urlSearchParams, setUrlSearchParams, getUrlSearchParams] =
    useQueryParams();
  const [, setReactiveSearchParams] = useSearchParams();

  const { id: activeCampaign } = useParams();
  let [c2cDetailsModelOpen, setc2cDetailsModalOpen] = useState<boolean>(false);
  const [shouldIgnoreRowClick, setShouldIgnoreRowClick] =
    useState<boolean>(false);
  const [findingDetailsModelOpen, setFindingDetailsModalOpen] =
    useState<boolean>(false);
  const advanceFilterList = useCommonSelector(getFilterList);
  const memoizedSearchParams = useMemo(
    () => urlSearchParams,
    [urlSearchParams]
  );
  const iacFindingsTableSelectionProps = useCommonSelector(
    getIacFindingsTableSelectionProps
  );
  const advanceFilterSelections = useCommonSelector(getSelectionList);

  const { t: translation } = useTranslation();
  const dispatch = useCommonDispatch();

  const campaignFindingsState: CampaignsStore = useCommonSelector(
    getCampaignFindingsState
  );

  useEffect(() => {
    return () => {
      clearFilters();
    };
  }, []);

  const [
    searchCampaignFindings,
    { data: campaignFindings, isLoading: isLoading },
  ] = useFetchFindingsByCampaignMutation();

  const orderParams = useMemo<OrderState | null>(() => {
    try {
      if (memoizedSearchParams.order) {
        const parsedOrderParams = JSON.parse(
          memoizedSearchParams.order as string
        ) as OrderState;

        return parsedOrderParams;
      }

      return null;
    } catch (err) {
      return null;
    }
  }, [memoizedSearchParams.order]);

  const additionalSearchParams = useMemo(() => {
    const updatedSearchParams =
      advanceFilterDataHandler.translateAdvanceFilterComponentValuesToSearchParams(
        advanceFilterSelections
      );

    return updatedSearchParams;
  }, [advanceFilterSelections]);

  useEffect(() => {
    searchCampaignFindings({
      filter: additionalSearchParams,
      order: orderParams as OrderState,
      take: campaignFindingsState.pageSize,
      skip:
        campaignFindingsState.page && campaignFindingsState.pageSize
          ? (campaignFindingsState.page - 1) * campaignFindingsState.pageSize
          : 0,
      campaignId: activeCampaign,
    });
  }, [
    campaignFindingsState.page,
    campaignFindingsState.pageSize,
    orderParams,
    additionalSearchParams,
  ]);

  useEffect(() => {
    if (urlSearchParams.openFindingId && findingDetailsModelOpen === false) {
      setFindingDetailsModalOpen(true);
    }

    if (!urlSearchParams.openFindingId && findingDetailsModelOpen === true) {
      setFindingDetailsModalOpen(false);
    }
  }, [urlSearchParams.openFindingId]);

  const onHandleColumnOrderChange = (columnOrderPayload: Array<OrderState>) => {
    const existingParams = filterSearchParamsHandler.setSearchParamsForKeys(
      urlSearchParams,
      ['filter']
    );
    if (columnOrderPayload.length) {
      setUrlSearchParams({
        ...existingParams,
        order: JSON.stringify({
          field: columnOrderPayload[0].field,
          type: columnOrderPayload[0].type,
        }),
      });
    } else {
      setUrlSearchParams({
        ...existingParams,
      });
    }
  };

  const onPageChange = (selectedPage: number) => {
    dispatch(setCampaignFindingsPage(selectedPage));
  };

  const onPageSizeChange = (selectedPageSize: number) => {
    dispatch(setCampaignFindingsPageSize(selectedPageSize));
  };

  const onHandleSelectionChange = (selections: GridSelectionModel) => {
    dispatch(
      setIacFindingsTableSelection({
        ...iacFindingsTableSelectionProps,
        selectedChildren: selections,
        totalSelectedChildren: selections.length,
      })
    );
  };

  const clearFilters = () => {
    const updatedArray = advanceFilterList.map((obj: any) => {
      if (!obj?.dateRange) {
        return {
          ...obj,
          dateRange: null,
          items: obj.items.map((item: any) => {
            return { ...item, checked: false };
          }),
        };
      }
      return {
        ...obj,
        dateRange: null,
      };
    });
    dispatch(setListFilter({ list: updatedArray, isSelectingItem: true }));
    dispatch(setSearchParams({ ...memoizedSearchParams, filter: {} }));
  };

  const handleFindingDetailsModalOpen = (findingId: string) => {
    const updatedSearchParams = { ...urlSearchParams };
    setUrlSearchParams({ ...updatedSearchParams, openFindingId: findingId });
    const updatedReactiveSearchParams = new URLSearchParams(
      getUrlSearchParams()
    );

    if (updatedReactiveSearchParams.has('openCodeEventId'))
      updatedReactiveSearchParams.delete('openCodeEventId');

    updatedReactiveSearchParams.set('openFindingId', findingId);

    setReactiveSearchParams(updatedReactiveSearchParams);
  };

  const handleRowClicked = (event: RowClickedEvent) => {
    handleFindingDetailsModalOpen(event.data.findingId);
  };

  return (
    <div className="campaign-findings-table-container">
      <CommonSimpleDataGrid
        rowData={campaignFindings?.data || []}
        columnDefs={CampaignFindingsTableColumns.getCampaignFindingsTableColumns(
          translation,
          setShouldIgnoreRowClick
        )}
        rowSelection="multiple"
        defaultColDef={{
          resizable: true,
        }}
        suppressRowClickSelection
        onRowClicked={handleRowClicked}
        visibilityControlProps={{
          enableVisibilityControls: false,
          columns: [],
        }}
        paginationProps={{
          pageSize: campaignFindingsState.pageSize,
          currentPage: campaignFindingsState.page,
          onPageChange,
          onPageSizeChange,
          totalItems: campaignFindings?.totalItems || 0,
        }}
        isLoading={isLoading}
        onSelect={onHandleSelectionChange}
        onSort={onHandleColumnOrderChange}
        selectionModel={
          iacFindingsTableSelectionProps.selectedChildren as Array<string>
        }
        sortModel={orderParams as OrderState}
        keepCurrentSelections
        otherComponents={{
          NoDataBackdropComponent: (
            <NoDataBackdrop descriptionText="Try relaxing your search criteria" />
          ),
        }}
        loadingOverlayComponent={() => <CircularProgress />}
        getRowId={(row: GetRowIdParams) => {
          return row.data.findingId;
        }}
        gridRef={gridRef}
      />
      <FindingCodeEventDrawerComplex />
    </div>
  );
};
