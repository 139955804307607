import { FunctionComponent } from 'react';
import {
  ApiDynamicOptionSourceProperties,
  ApiStaticOptionSourceProperties,
  CategoryState,
  DateRangeState,
  ExtendedFilterCategory,
  FilterOption,
  FilterType,
  KeyValueSelection,
  KeyValueState,
  MultiSelectState,
  NumberRangeState,
  NumberState,
  OptionSourceType,
  SingleSelectState,
  StaticOptionSourceProperties,
} from 'shared/models/data/data-filter.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import OptionList from '../OptionList';
import {
  CategoryDropdown,
  DropdownProps,
} from '../CategoryDropdown/CategoryDropdown';
import { OptionListType } from '../OptionList/OptionList';
import DateRangeInput from '../DateRangeInput';
import NumberInput from '../NumberInput';
import NumberRangeInput from '../NumberRangeInput';
import { FilterChipGroupChangeHandlerProps } from '../FilterChipGroup/FilterChipGroup';
import DropdownWrapper from '../DropdownWrapper';
import StaticOptionList from '../StaticOptionList';
import ApiStaticOptionList from '../ApiStaticOptionList';
import ApiDynamicOptionList from '../ApiDynamicOptionList';
import { DataFitlerHandler } from 'shared/handlers/data-filter.handler';
import KeyValueInput from '../KeyValueInput';

interface FilterContentDropdownProps
  extends BaseComponentProps,
    ExtendedFilterCategory,
    DropdownProps,
    FilterChipGroupChangeHandlerProps {}

const dataFilterHandler = new DataFitlerHandler();

export const FilterContentDropdown: FunctionComponent<
  FilterContentDropdownProps
> = ({
  label,
  id,
  state,
  type,
  categories,
  anchorEl,
  anchorOrigin,
  open,
  handleClose,
  onChange,
  categoryState,
}) => {
  const renderFilterComponentBasedOnType = (type: FilterType) => {
    switch (type) {
      case FilterType.SINGLE_SELECT: {
        const singleSelectState = state as SingleSelectState;

        switch (singleSelectState.sourceType) {
          case OptionSourceType.STATIC: {
            const staticSource =
              singleSelectState.source as StaticOptionSourceProperties;

            return (
              <StaticOptionList
                options={staticSource.options}
                mode={OptionListType.SINGLE}
                title={label}
                actionHandlers={{
                  goBackHandler: handleClose,
                }}
                onOptionsSelect={(options: Array<FilterOption>) => {
                  onChange(id, {
                    selectedOptions: options,
                  });
                }}
                selectedOptions={singleSelectState.selectedOptions}
              />
            );
          }
          case OptionSourceType.API_STATIC: {
            const staticSource =
              singleSelectState.source as ApiStaticOptionSourceProperties;

            return (
              <ApiStaticOptionList
                source={staticSource}
                mode={OptionListType.SINGLE}
                open={open}
                title={label}
                actionHandlers={{
                  goBackHandler: handleClose,
                }}
                onOptionsSelect={(options: Array<FilterOption>) => {
                  onChange(id, {
                    selectedOptions: options,
                  });
                }}
                selectedOptions={singleSelectState.selectedOptions}
                sortMethod={
                  singleSelectState.sortMethod ||
                  dataFilterHandler.sortFilterOptions
                }
                extraOptions={singleSelectState.extraOptions}
              />
            );
          }
        }

        return <></>;
      }
      case FilterType.MULTI_SELECT: {
        const multiSelectState = state as MultiSelectState;

        switch (multiSelectState.sourceType) {
          case OptionSourceType.STATIC: {
            const staticSource =
              multiSelectState.source as StaticOptionSourceProperties;

            return (
              <StaticOptionList
                options={staticSource.options}
                mode={OptionListType.MULTIPLE}
                title={label}
                actionHandlers={{
                  goBackHandler: handleClose,
                }}
                onOptionsSelect={(
                  options: Array<FilterOption>,
                  isAllSelected?: boolean
                ) => {
                  onChange(id, {
                    selectedOptions: options,
                    allSelected: isAllSelected,
                  });
                }}
                selectedOptions={multiSelectState.selectedOptions}
                allSelected={multiSelectState.allSelected}
                onAllSelect={(options: Array<FilterOption>) => {
                  const typedState = state as MultiSelectState;

                  if (typedState.allSelected) {
                    onChange(id, {
                      selectedOptions: [],
                      allSelected: false,
                    });
                  } else {
                    onChange(id, {
                      selectedOptions: options,
                      allSelected: true,
                    });
                  }
                }}
              />
            );
          }
          case OptionSourceType.API_STATIC: {
            const apiStaticSource =
              multiSelectState.source as ApiStaticOptionSourceProperties;

            return (
              <ApiStaticOptionList
                source={apiStaticSource}
                mode={OptionListType.MULTIPLE}
                open={open}
                title={label}
                actionHandlers={{
                  goBackHandler: handleClose,
                }}
                onOptionsSelect={(
                  options: Array<FilterOption>,
                  isAllSelected?: boolean
                ) => {
                  onChange(id, {
                    selectedOptions: options,
                    allSelected: isAllSelected,
                  });
                }}
                selectedOptions={multiSelectState.selectedOptions}
                allSelected={multiSelectState.allSelected}
                onAllSelect={(options: Array<FilterOption>) => {
                  const typedState = state as MultiSelectState;

                  if (typedState.allSelected) {
                    onChange(id, {
                      selectedOptions: [],
                      allSelected: false,
                    });
                  } else {
                    onChange(id, {
                      selectedOptions: options,
                      allSelected: true,
                    });
                  }
                }}
                sortMethod={
                  multiSelectState.sortMethod ||
                  dataFilterHandler.sortFilterOptions
                }
                extraOptions={multiSelectState.extraOptions}
              />
            );
          }
          case OptionSourceType.API_DYNAMIC: {
            const apiStaticSource =
              multiSelectState.source as ApiDynamicOptionSourceProperties;

            return (
              <ApiDynamicOptionList
                source={apiStaticSource}
                mode={OptionListType.MULTIPLE}
                open={open}
                title={label}
                actionHandlers={{
                  goBackHandler: handleClose,
                }}
                onOptionsSelect={(
                  options: Array<FilterOption>,
                  isAllSelected?: boolean
                ) => {
                  onChange(id, {
                    selectedOptions: options,
                    allSelected: isAllSelected,
                  });
                }}
                selectedOptions={multiSelectState.selectedOptions}
                allSelected={multiSelectState.allSelected}
                onAllSelect={(options: Array<FilterOption>) => {
                  const typedState = state as MultiSelectState;

                  if (typedState.allSelected) {
                    onChange(id, {
                      selectedOptions: [],
                      allSelected: false,
                    });
                  } else {
                    onChange(id, {
                      selectedOptions: options,
                      allSelected: true,
                    });
                  }
                }}
                sortMethod={
                  multiSelectState.sortMethod ||
                  dataFilterHandler.sortFilterOptions
                }
                extraOptions={multiSelectState.extraOptions}
              />
            );
          }
        }

        return <></>;
      }
      case FilterType.DATE_RANGE: {
        const typedState = state as DateRangeState;
        return (
          <DateRangeInput
            label={label}
            onDateChange={(startDate: Date, endDate: Date) => {
              onChange(id, {
                startDate,
                endDate,
              });
            }}
            actionHandlers={{
              goBackHandler: handleClose,
            }}
            {...typedState}
          />
        );
      }
      case FilterType.NUMBER: {
        const typedState = state as NumberState;
        return <NumberInput {...typedState} />;
      }
      case FilterType.NUMBER_RANGE: {
        const typedState = state as NumberRangeState;
        return (
          <NumberRangeInput
            label={label}
            actionHandlers={{
              goBackHandler: handleClose,
            }}
            onRangeChange={(numberRangeState: NumberRangeState) => {
              onChange(id, numberRangeState);
            }}
            {...typedState}
          />
        );
      }
      case FilterType.KEY_VALUE: {
        const typedState = state as KeyValueState;

        return (
          <KeyValueInput
            {...typedState}
            title={label}
            onChange={(selections: Array<KeyValueSelection>) => {
              onChange(id, {
                ...typedState,
                selections,
              });
            }}
            actionHandlers={{
              goBackHandler: handleClose,
            }}
          />
        );
      }
      default:
        return <></>;
    }
  };

  const renderItemPopover = () => {
    if (categories?.length)
      return (
        <CategoryDropdown
          categories={categories?.sort((categoryA, categoryB) =>
            categoryA.label.localeCompare(categoryB.label)
          )}
          anchorEl={anchorEl}
          handleClose={handleClose}
          open={Boolean(anchorEl)}
          onChange={onChange}
          anchorOrigin={anchorOrigin}
          title={label}
          enableBackButton
          categoryState={categoryState}
        />
      );

    return (
      <DropdownWrapper
        id="popover-wrapper"
        anchorEl={anchorEl}
        handleClose={handleClose}
        open={Boolean(anchorEl)}
        anchorOrigin={anchorOrigin}
        className={`filter-content-dropdown ${
          type === FilterType.KEY_VALUE
            ? 'filter-content-key-value-dropdown'
            : ''
        }`}
      >
        {type ? renderFilterComponentBasedOnType(type) : <></>}
      </DropdownWrapper>
    );
  };

  return renderItemPopover();
};
