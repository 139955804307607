import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useDeleteSlaConfigRuleByIdMutation,
  useGetSlaConfigRulesQuery,
  useUpdateSlaConfigRuleByIdMutation,
} from 'Settings/store/api';
import FormattedMessage from 'shared/components/FormattedMessage';
import { SettingsLayout } from '../SettingsLayout/SettingsLayout';
import { usePermissions } from '../../../shared/hooks/usePermissionActions';
import { ApplicationPermission } from '../../../shared/enums/permission.enum';
import ForbiddenPage from '../../../Common/components/ForbiddenPage';
import PopoverWrapper from 'shared/components/PopoverWrapper/PopoverWrapper';
import SlaRulesFormPanel from './SlaRulesFormPanel';
import { SettingsTabPanel } from 'Settings/interfaces/SettingTabPanel.enum';
import { DrawerMode } from 'shared/enums/business-unit.enum';
import { SlaConfigRuleModel } from 'shared/models/data/sla-config-rule.model';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import RiskAndSlaCard from '../RiskAndSlaCard/RiskAndSlaCard';
import { RuleStatus } from 'Settings/interfaces/RiskCustomizationConfig';
import { SlaType } from 'Settings/interfaces/Sla';

export default function SlaList() {
  const { isFetching: isLoading, data: slaData } = useGetSlaConfigRulesQuery(
    '',
    { refetchOnMountOrArgChange: true }
  );

  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const [tableItems, setTableItems] = useState<SlaConfigRuleModel[]>([]);
  const userPermissions = usePermissions();
  const [searchParams, setSearchParams] = useQueryParams();
  const { t: translation } = useTranslation();
  const [updateSlaRule] = useUpdateSlaConfigRuleByIdMutation();

  useEffect(() => {
    setPopoverOpen(!!searchParams.mode);
  }, [searchParams]);

  useEffect(() => {
    if (slaData) {
      setTableItems(slaData);
    }
  }, [slaData]);

  const hasSLAReadPermission = userPermissions.includes(
    ApplicationPermission.SETTINGS_SLA_READ
  );

  const drawerOpenHandler = () => {
    setSearchParams({
      activeTabId: SettingsTabPanel.SLA,
      mode: DrawerMode.CREATE,
    });
  };

  const updateStatus = (checked: boolean, id: string) => {
    const updatedItem = slaData?.find((item) => item.id === id);
    updateSlaRule({
      id: id,
      body: {
        ...updatedItem,
        status: checked ? RuleStatus.ENABLED : RuleStatus.DISABLED,
      },
    });
  };
  const drawerCloseHandler = () => {
    setPopoverOpen(false);
    setSearchParams({
      activeTabId: SettingsTabPanel.SLA,
    });
  };

  if (!hasSLAReadPermission) return <ForbiddenPage />;
  const renderBody = () => {
    if (tableItems?.length) {
      return tableItems.map((rule, index) => (
        <div key={index}>
          <RiskAndSlaCard
            useDeleteMutation={useDeleteSlaConfigRuleByIdMutation}
            onSwitchClicked={updateStatus}
            onEditClick={() => {
              setSearchParams({
                activeTabIds: SettingsTabPanel.SLA,
                mode: DrawerMode.EDIT,
                slaRuleId: rule.id || '',
              });
            }}
            requiredWritePermission={ApplicationPermission.SETTINGS_SLA_WRITE}
            {...rule}
            id={rule.id || ''}
            slas={rule.values}
            settings={rule.settings.type as SlaType}
            properties={rule.properties}
            disabledTooltipContent={translation(
              'settings.details.disabledDefaultMessage',
              {
                param: translation(
                  'settings.details.slaDisabledDefaultMessage'
                ),
              }
            )}
          />
        </div>
      ));
    }
    if (isLoading) {
      return (
        <Box textAlign="center" p="2rem">
          <CircularProgress />
        </Box>
      );
    }

    return (
      <div className="no-data-container">{translation(`common.noData`)}</div>
    );
  };

  return (
    <>
      <SettingsLayout<SlaConfigRuleModel>
        headerProps={{
          setTableItems: setTableItems,
          items: slaData || [],
          filterPropertyName: 'name',
          onButtonClick: drawerOpenHandler,
          buttonLabel: (
            <FormattedMessage
              id="settings.details.addSlaPolicy"
              defaultMessage="Add SLA Policy"
            />
          ),
          buttonType: 'button',
          buttonRequiredPermissions: [ApplicationPermission.SETTINGS_SLA_WRITE],
        }}
        tableProps={{
          deleteMutation: () => {},
          onItemClick: () => {},
        }}
        fetchData={() => {}}
        components={{
          TableComponent: <div className="sla-rules-list">{renderBody()}</div>,
        }}
      />
      <PopoverWrapper
        showPopover={popoverOpen}
        handleClose={drawerCloseHandler}
      >
        <SlaRulesFormPanel onCancel={drawerCloseHandler} />
      </PopoverWrapper>
    </>
  );
}
