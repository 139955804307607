import React, { FunctionComponent, useState } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import CommonHtmlContent from '../../../../../Common/components/HtmlContent';

interface FindingContentDescriptionProps extends BaseComponentProps {
  description?: string;
}

export const FindingContentDescription: FunctionComponent<
  FindingContentDescriptionProps
> = ({ description }) => {
  const maxDescriptionLength = 400;
  const descriptionExceedsMaxLength =
    (description?.length || 0) > maxDescriptionLength;
  const [showFullDescription, setFullDescription] = useState<boolean>(false);
  const showFullDescriptionHandler = () => {
    setFullDescription(!showFullDescription);
  };

  const descriptionDisplay =
    showFullDescription || !descriptionExceedsMaxLength
      ? description
      : `${description?.slice(0, maxDescriptionLength)}...`;

  return (
    <div className="finding-content-description-container">
      <CommonHtmlContent
        data={descriptionDisplay?.replace(/<a/g, '&lt;a') || ''}
      />
      {descriptionExceedsMaxLength && (
        <span
          onClick={showFullDescriptionHandler}
          className="finding-content-description-read-more"
        >
          Read {showFullDescription ? 'Less' : 'More'}
        </span>
      )}
    </div>
  );
};
