import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { AccordionSection } from '../AccordionSection/AccordionSection';
import { OpusSvgIcon } from '../IconComponents/OpusSvgIcon/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { Button, CircularProgress } from '@mui/material';
import { InputLabelWrapper } from '../InputLabelWrapper/InputLabelWrapper';
import Autocomplete from 'Common/components/Autocomplete';
import {
  businessUnitFormRequiredFields,
  businessUnitFormSchema,
  businessUnitStakeholdersDefaultValues,
} from 'shared/fixtures/data/business-unit.data';
import {
  ConfigurationFieldValue,
  MultiSelectConfigurationField,
} from '../MultiSelectConfigurationField/MultiSelectConfigurationField';
import {
  BusinessUnitComplianceRequirements,
  BusinessUnitRiskAttribute,
} from 'shared/enums/business-unit.enum';
import { CheckboxLabel } from '../CheckboxLabel/CheckboxLabel';
import { BusinessUnitDataHandler } from 'shared/handlers/business-unit-data.handler';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFetchBusinessUnitFormOptionItems } from 'shared/hooks/useFetchBusinessUnitFormOptionItems';
import {
  ExtendedBusinessUnitCreateModel,
  ExtendedBusinessUnitFormModel,
  ExtendedBusinessUnitModel,
} from 'shared/models/data/extended-business-unit.model';
import { AutocompleteOption } from 'FindingDetails/store/api';
import { OpusAvatarIcon } from '../IconComponents/OpusAvatarIcon/OpusAvatarIcon';
import { debounce, uniq } from 'lodash';
import { useFetchBusinessUnitQuery } from 'Settings/store/api';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { CommonModalContainer } from '../CommonModalContainer';
import FormattedMessage from '../FormattedMessage';
import { OrganizationNode } from 'Organization/interfaces/OrganizationNode.interface';
import { useTranslation } from 'react-i18next';
import { MultiTextConfigurationField } from '../MultiTextConfigurationField';
import { MultiTextRowValue } from '../MultiTextConfigurationField/MultiTextConfigurationField';

const businessUnitDataHandler = new BusinessUnitDataHandler();

interface BusinessUnitEditPanelProps extends BaseComponentProps {
  formProps?: {
    onSubmit?: (formData: ExtendedBusinessUnitCreateModel) => void;
    onCancel?: () => void;
    loading?: boolean;
    error?: any;
  };

  cancelationModalProps?: {
    open: boolean;
    onClose: () => void;
    onOpen: () => void;
  };
}

export const BusinessUnitFormPanel: FunctionComponent<
  BusinessUnitEditPanelProps
> = ({ formProps = {}, cancelationModalProps }) => {
  const { t: translation } = useTranslation();

  const [searchParams] = useQueryParams();

  const businessUnitOptions = useFetchBusinessUnitFormOptionItems();

  const { data: businessUnitData, isFetching: businessUnitDataLoading } =
    useFetchBusinessUnitQuery(searchParams?.businessUnitId || '', {
      skip: Boolean(!searchParams?.businessUnitId),
      refetchOnMountOrArgChange: true,
    });

  const [formRequiredFieldKeys, setFormRequiredFieldKeys] = useState<
    Array<string>
  >(businessUnitFormRequiredFields);

  const formDefaultValues = useMemo<
    | {
        [x: string]: any;
      }
    | undefined
  >(() => {
    if (businessUnitData) {
      return businessUnitDataHandler.transformBusinessUnitDataToDefaultFormValues(
        businessUnitData as ExtendedBusinessUnitModel
      );
    }

    return undefined;
  }, [businessUnitData]);

  const {
    register,
    formState: { errors },
    setValue: setFormValue,
    setError,
    getValues,
    clearErrors,
    watch: watchFormValue,
  } = useForm({
    resolver: yupResolver(businessUnitFormSchema),
  });

  const scopeId = watchFormValue('orgScopeId');

  useEffect(() => {
    if (formDefaultValues) {
      for (const key in formDefaultValues) {
        setFormValue(key as any, formDefaultValues[key]);
      }
    }
  }, [formDefaultValues]);

  useEffect(() => {
    if (scopeId?.value) {
      businessUnitOptions?.getCodeRepos(scopeId?.value);
      businessUnitOptions?.getCloudSubscriptions(scopeId?.value);
    }
  }, [scopeId]);

  const searchCodeRepos = (alias: string) => {
    businessUnitOptions.getCodeRepos(scopeId?.value, alias);
  };

  const searchCloudSubscriptions = (alias: string) => {
    businessUnitOptions.getCloudSubscriptions(scopeId?.value, alias);
  };

  const onChangeFormValue = (formInputName: any, formValue: any) => {
    setFormValue(formInputName, formValue);
  };

  const onRiskAttributeChange = (checked: boolean, value: string) => {
    const formRiskAttributes = getValues().riskAttributes;

    if (formRiskAttributes?.length) {
      if (checked) {
        onChangeFormValue(
          'riskAttributes',
          uniq([...formRiskAttributes, value])
        );
      } else {
        onChangeFormValue(
          'riskAttributes',
          formRiskAttributes?.filter(
            (riskAttribute: BusinessUnitRiskAttribute) =>
              riskAttribute !== value
          )
        );
      }
    } else {
      onChangeFormValue('riskAttributes', [value]);
    }
  };

  const onComplianceRequirementsChange = (checked: boolean, value: string) => {
    const formComplianceRequirements = getValues().complianceRequirements;

    if (formComplianceRequirements?.length) {
      if (checked) {
        onChangeFormValue(
          'complianceRequirements',
          uniq([...formComplianceRequirements, value])
        );
      } else {
        onChangeFormValue(
          'complianceRequirements',
          formComplianceRequirements?.filter(
            (complianceRequirement: BusinessUnitComplianceRequirements) =>
              complianceRequirement !== value
          )
        );
      }
    } else {
      onChangeFormValue('complianceRequirements', [value]);
    }
  };

  const getUpdateButtonMessage = (loading: any) => {
    return loading ? (
      <FormattedMessage
        id="common.updating"
        defaultMessage="Updating"
        capitalize
      />
    ) : (
      <FormattedMessage id="common.update" defaultMessage="Update" capitalize />
    );
  };

  const getCreateButtonMessage = (loading: any) => {
    return loading ? (
      <FormattedMessage
        id="common.creating"
        defaultMessage="Creating"
        capitalize
      />
    ) : (
      <FormattedMessage id="common.create" defaultMessage="Create" capitalize />
    );
  };
  useEffect(() => {
    if (formDefaultValues?.orgScopeId) {
      businessUnitOptions.getGroups(formDefaultValues?.orgScopeId?.value);
    }
  }, [formDefaultValues?.orgScopeId]);

  const BusinessUnitDetailsLabel = () => {
    const isLoading = formProps?.loading;
    const isUpdating = businessUnitData?.id;

    const updateButtonMessage = getUpdateButtonMessage(isLoading);
    const createButtonMessage = getCreateButtonMessage(isLoading);

    return (
      <>
        {isUpdating ? updateButtonMessage : createButtonMessage}
        {'\u00A0'}
        <FormattedMessage
          id="settings.details.businessUnit"
          defaultMessage="Service"
        />
      </>
    );
  };

  const validateFormBeforeSubmit = (): boolean => {
    const formValues: any = getValues();

    let isValid = true;

    for (const requiredField of formRequiredFieldKeys) {
      if (
        !formValues[requiredField] ||
        formValues[requiredField]?.length === 0
      ) {
        setError(requiredField as any, {
          message: `${requiredField} is required`,
        });
        isValid = false;
      } else {
        clearErrors(requiredField as any);
      }
    }

    return isValid;
  };

  const getApplicationIcon = (iconUrl: string) => {
    return (
      <img
        src={iconUrl}
        className="business-unit-form-panel-application-icon"
      ></img>
    );
  };

  const getAvatarIcon = (avatarUrl: string) => {
    return (
      <OpusAvatarIcon
        src={avatarUrl}
        className="business-unit-form-panel-avatar-icon"
      />
    );
  };

  const onMultiSelectConfigurationFieldChange = (
    fieldName: string,
    selectedOptions: Array<ConfigurationFieldValue>
  ) => {
    const validSelectedOptions: Array<ConfigurationFieldValue> =
      selectedOptions.filter(
        (selectedOption) =>
          selectedOption.values[0]?.value.length &&
          selectedOption.values[1]?.value.length
      );

    setFormValue(
      fieldName as keyof typeof businessUnitFormSchema,
      validSelectedOptions
    );
  };

  return businessUnitDataLoading ? (
    <div className="business-unit-form-panel-loading">
      <CircularProgress size={35} />
    </div>
  ) : (
    <>
      <div className="business-unit-form-panel">
        <CommonModalContainer
          handleClose={() => {
            cancelationModalProps?.onClose && cancelationModalProps.onClose();
          }}
          isOpen={Boolean(cancelationModalProps?.open)}
          title={`Cancel Service Scope ${
            businessUnitData?.id ? 'Update' : 'Creation'
          }`}
        >
          <div className="business-unit-form-modal-body">
            <div className="business-unit-form-modal-description">
              <span>Are you sure you want to cancel this action?</span>
              <span>All data will be lost</span>
            </div>
            <div className="business-unit-form-modal-buttons">
              <Button
                className="base-opus-text-button"
                onClick={() => {
                  cancelationModalProps?.onClose &&
                    cancelationModalProps.onClose();
                }}
              >
                Stay
              </Button>
              <Button
                className="base-opus-text-button"
                onClick={() => {
                  cancelationModalProps?.onClose &&
                    cancelationModalProps?.onClose();
                  formProps?.onCancel && formProps.onCancel();
                }}
              >
                Yes
              </Button>
            </div>
          </div>
        </CommonModalContainer>
        <div className="business-unit-form-panel-body">
          <form className="business-unit-form-panel-form">
            <AccordionSection
              title="General Info"
              description="Group your organization's resources to align with service goals"
              toggleIcon={
                <OpusSvgIcon type={SVG_ICON_TYPES.CHEVRON_DOWN_ICON} />
              }
              sectionIcon={
                <OpusSvgIcon type={SVG_ICON_TYPES.INFO_CIRCLE_ICON} />
              }
              iconClassName="business-unit-form-panel-accordion-icon"
              rootClassName="business-unit-form-panel-accordion"
            >
              <div className="business-unit-form-panel-form-general-info-group">
                <InputLabelWrapper label="Name">
                  <input
                    type="text"
                    className={`business-scope-text-field text-field-1 ${
                      errors.name ? 'input-validation-error-1' : ''
                    }`}
                    {...register('name')}
                    id="service-name-field"
                  ></input>
                </InputLabelWrapper>
                <InputLabelWrapper
                  label="Please provide some details on the service goals of this scope"
                  isOptional
                >
                  <textarea
                    rows={4}
                    className="text-area-field-1"
                    {...register('description')}
                    id="service-description-field"
                  ></textarea>
                </InputLabelWrapper>
                <InputLabelWrapper label="Service Impact">
                  <Autocomplete
                    onChangeCallBack={(model: string, value) => {
                      onChangeFormValue('businessUnitImpact', value);
                    }}
                    model="business-impact"
                    optionList={businessUnitOptions.businessImpact}
                    single
                    classes={{
                      root: 'multi-select-field-1 business-impact-multi-select-field',
                      paper: 'multi-select-field-paper-1',
                      inputRoot: errors.businessUnitImpact
                        ? 'input-validation-error-1'
                        : '',
                    }}
                    initialSelectedValues={
                      formDefaultValues?.businessUnitImpact
                    }
                    enableCheckbox
                    id="service-impact-field"
                  />
                </InputLabelWrapper>
                <div className="business-unit-form-panel-form-row">
                  <InputLabelWrapper label="Org Scope">
                    <Autocomplete
                      onChangeCallBack={(model: string, value) => {
                        onChangeFormValue('orgScopeId', value);
                        setFormValue('groupNodeId', undefined);
                        const singleSelectOption = value as AutocompleteOption;

                        businessUnitOptions?.getGroups(
                          singleSelectOption.value
                        );
                      }}
                      placeholder=""
                      optionList={businessUnitOptions.scopes}
                      single
                      classes={{
                        root: 'multi-select-field-1',
                        paper: 'multi-select-field-paper-1',
                        inputRoot: errors.orgScopeId
                          ? 'input-validation-error-1'
                          : '',
                      }}
                      initialSelectedValues={formDefaultValues?.orgScopeId}
                      model={'nodes-scope'}
                      areOptionsLoaded
                      id="service-org-scope-field"
                    />
                  </InputLabelWrapper>
                  <InputLabelWrapper label="Org Group">
                    <Autocomplete
                      onChangeCallBack={(model: string, value) => {
                        onChangeFormValue('groupNodeId', value);
                      }}
                      placeholder=""
                      optionList={businessUnitOptions.groups}
                      single
                      classes={{
                        root: 'multi-select-field-1',
                        paper: 'multi-select-field-paper-1',
                        inputRoot: errors.groupNodeId
                          ? 'input-validation-error-1'
                          : '',
                      }}
                      initialSelectedValues={formDefaultValues?.groupNodeId}
                      model={'nodes-group'}
                      areOptionsLoaded
                      id="service-org-group-field"
                    />
                  </InputLabelWrapper>
                </div>
              </div>
            </AccordionSection>

            <AccordionSection
              title="Related Environments"
              description="Please add the relevant cloud ( private / public) environments"
              helperText={`(${translation('common.optional')})`}
              toggleIcon={
                <OpusSvgIcon type={SVG_ICON_TYPES.CHEVRON_DOWN_ICON} />
              }
              sectionIcon={
                <OpusSvgIcon type={SVG_ICON_TYPES.CHART_NETWORK_ICON} />
              }
              iconClassName="business-unit-form-panel-accordion-icon"
              rootClassName="business-unit-form-panel-accordion"
            >
              <div className="business-unit-form-panel-form-related-environments-group">
                <InputLabelWrapper label="Code Repo">
                  <Autocomplete
                    onChangeCallBack={(model: string, value) => {
                      onChangeFormValue('associatedRepos', value);
                    }}
                    model="code-repo"
                    loading={businessUnitOptions.codeReposLoading}
                    optionList={businessUnitOptions.codeRepo}
                    classes={{
                      root: 'multi-select-field-1 business-impact-multi-select-field',
                      paper: 'multi-select-field-paper-1',
                    }}
                    icons={{
                      ChipRemoveIcon: (
                        <OpusSvgIcon type={SVG_ICON_TYPES.CLOSE_ICON} />
                      ),
                    }}
                    initialSelectedValues={formDefaultValues?.associatedRepos}
                    getIcon={(option: AutocompleteOption) =>
                      getApplicationIcon(option.iconUrl as string)
                    }
                    enableCheckbox
                    disabled={scopeId === undefined}
                    areOptionsLoaded
                    onSearch={debounce(searchCodeRepos, 200)}
                    id="service-code-repo-field"
                  />
                </InputLabelWrapper>

                <div className="business-unit-form-panel-form-divider"></div>

                <InputLabelWrapper label="Runtime Environments">
                  <Autocomplete
                    onChangeCallBack={(model: string, value) => {
                      onChangeFormValue('associatedAccounts', value);
                    }}
                    model="associatedAccounts"
                    loading={businessUnitOptions.cloudSubscriptionsLoading}
                    optionList={businessUnitOptions.cloudSubscription}
                    classes={{
                      root: 'multi-select-field-1 business-impact-multi-select-field',
                      paper: 'multi-select-field-paper-1',
                    }}
                    icons={{
                      ChipRemoveIcon: (
                        <OpusSvgIcon type={SVG_ICON_TYPES.CLOSE_ICON} />
                      ),
                    }}
                    initialSelectedValues={
                      formDefaultValues?.associatedAccounts
                    }
                    getIcon={(option: AutocompleteOption) =>
                      getApplicationIcon(option.iconUrl as string)
                    }
                    enableCheckbox
                    disabled={scopeId === undefined}
                    areOptionsLoaded
                    onSearch={debounce(searchCloudSubscriptions, 200)}
                    id="service-runtime-environments-field"
                  />
                </InputLabelWrapper>

                <div className="business-unit-form-panel-form-divider"></div>

                <MultiTextConfigurationField
                  columns={[
                    {
                      name: translation(`settings.details.resourceTagName`),
                    },
                    {
                      name: translation(`settings.details.resourceTagValue`),
                    },
                  ]}
                  onChange={(values) => {
                    const trimmedValues = values.map(
                      (value: MultiTextRowValue) =>
                        value.map((valueTextItem: string) =>
                          valueTextItem.trim()
                        )
                    );

                    onChangeFormValue('associationRules', trimmedValues);
                  }}
                  defaultValues={formDefaultValues?.associationRules || []}
                  ids={[
                    'service-resource-tag-name-field',
                    'service-resource-tag-value-field',
                  ]}
                />

                <div className="business-unit-form-panel-form-divider"></div>

                <InputLabelWrapper
                  label="Linked Domains"
                  helperText="Use comma to separate multiple domains"
                >
                  <input
                    type="text"
                    className="text-field-1 business-unit-linked-domains-text-field"
                    {...register('associatedDomains')}
                    id="service-linked-domains-field"
                  ></input>
                </InputLabelWrapper>

                {/* <InputLabelWrapper
                  label={translation(`settings.details.assetGroups`)}
                  helperText={translation(`settings.details.assetGroupsHelper`)}
                >
                  <input
                    type="text"
                    className="text-field-1 business-unit-linked-domains-text-field"
                    {...register('assetGroups')}
                  ></input>
                </InputLabelWrapper> */}
              </div>
            </AccordionSection>

            <AccordionSection
              title="Communication"
              description="Add the relevant integration and automate responses to newly detected Issues."
              toggleIcon={
                <OpusSvgIcon type={SVG_ICON_TYPES.CHEVRON_DOWN_ICON} />
              }
              sectionIcon={
                <OpusSvgIcon type={SVG_ICON_TYPES.SATELLITE_DISH_ICON} />
              }
              iconClassName="business-unit-form-panel-accordion-icon"
              rootClassName="business-unit-form-panel-accordion"
            >
              <div className="business-unit-form-panel-form-communication-group">
                <MultiSelectConfigurationField
                  fieldLabels={['Role', 'User Name']}
                  fieldOptions={[
                    businessUnitOptions.role,
                    businessUnitOptions.user,
                  ]}
                  values={
                    (formDefaultValues?.stakeholders as Array<ConfigurationFieldValue>) || [
                      ...businessUnitStakeholdersDefaultValues,
                    ]
                  }
                  onChange={(selectedOptions) => {
                    onMultiSelectConfigurationFieldChange(
                      'stakeholders',
                      selectedOptions
                    );
                  }}
                  classes={{
                    inputRoot: `multi-select-field-input-root ${
                      errors.stakeholders ? 'input-validation-error-1' : ''
                    }`,
                  }}
                  getIconMethods={{
                    'User Name': getAvatarIcon,
                  }}
                  ids={['service-role-field', 'service-username-field']}
                />
                <div className="business-unit-form-panel-form-divider"></div>

                <div className="business-unit-form-panel-form-row">
                  <InputLabelWrapper label="Ticketing System" isOptional>
                    <Autocomplete
                      onChangeCallBack={(model: string, value) => {
                        if (value) {
                          setFormRequiredFieldKeys(
                            (prevFormRequiredFieldKeys) => [
                              ...prevFormRequiredFieldKeys,
                              'projectName',
                            ]
                          );
                        } else {
                          setFormRequiredFieldKeys(
                            businessUnitFormRequiredFields
                          );
                        }
                        onChangeFormValue('ticketingSystem', value);
                      }}
                      model="connections"
                      id="service-ticketing-system-field"
                      optionList={businessUnitOptions.ticketingSystem}
                      single
                      classes={{
                        root: 'multi-select-field-1',
                        paper: 'multi-select-field-paper-1',
                      }}
                      icons={{
                        ChipRemoveIcon: (
                          <OpusSvgIcon type={SVG_ICON_TYPES.CLOSE_ICON} />
                        ),
                      }}
                      initialSelectedValues={formDefaultValues?.ticketingSystem}
                      getIcon={(option: AutocompleteOption) =>
                        getApplicationIcon(option?.iconUrl as string)
                      }
                      enableCheckbox
                    />
                  </InputLabelWrapper>

                  <InputLabelWrapper
                    label="Project"
                    isOptional={!formRequiredFieldKeys.includes('projectName')}
                  >
                    <input
                      {...register('projectName')}
                      type="text"
                      className={`text-field-1 ${
                        errors?.projectName ? 'input-validation-error-1' : ''
                      }`}
                      id="service-project-name-field"
                    ></input>
                  </InputLabelWrapper>
                </div>
                <div className="business-unit-form-panel-form-divider"></div>

                <div className="business-unit-form-panel-form-row">
                  <InputLabelWrapper
                    label="Communication Application"
                    isOptional
                  >
                    <Autocomplete
                      onChangeCallBack={(model: string, item: any) => {
                        onChangeFormValue('communicationAppId', item?.name);
                      }}
                      model="communicationAppId"
                      id="service-communication-application-field"
                      optionList={
                        businessUnitOptions.relatedCommunicationChannels
                      }
                      single
                      classes={{
                        root: 'multi-select-field-1 business-unit-related-team-multi-select-field',
                        paper: 'multi-select-field-paper-1',
                      }}
                      icons={{
                        ChipRemoveIcon: (
                          <OpusSvgIcon type={SVG_ICON_TYPES.CLOSE_ICON} />
                        ),
                      }}
                      initialSelectedValues={
                        formDefaultValues?.communicationAppId
                      }
                      enableCheckbox
                    />
                  </InputLabelWrapper>
                </div>

                <div className="business-unit-form-panel-form-divider"></div>

                <InputLabelWrapper label="Related Team" isOptional>
                  <Autocomplete
                    onChangeCallBack={(model: string, value) => {
                      onChangeFormValue('team', value);
                    }}
                    model="related-team"
                    id="service-related-team-field"
                    optionList={businessUnitOptions.relatedTeam}
                    single
                    classes={{
                      root: 'multi-select-field-1 business-unit-related-team-multi-select-field',
                      paper: 'multi-select-field-paper-1',
                    }}
                    icons={{
                      ChipRemoveIcon: (
                        <OpusSvgIcon type={SVG_ICON_TYPES.CLOSE_ICON} />
                      ),
                    }}
                    initialSelectedValues={formDefaultValues?.team}
                    enableCheckbox
                  />
                </InputLabelWrapper>

                <div className="business-unit-form-panel-form-divider"></div>

                <InputLabelWrapper
                  label={translation(`settings.details.ownerTagsLabel`)}
                  isOptional={true}
                >
                  <input
                    {...register('tags')}
                    type="text"
                    id="service-tags-field"
                    className={`text-field-1 ${
                      errors?.tags ? 'input-validation-error-1' : ''
                    }`}
                  ></input>
                </InputLabelWrapper>
              </div>
            </AccordionSection>

            <AccordionSection
              title="General Configuration"
              helperText="(Optional)"
              description="Please select the risk attributes of this service scope"
              toggleIcon={
                <OpusSvgIcon type={SVG_ICON_TYPES.CHEVRON_DOWN_ICON} />
              }
              sectionIcon={<OpusSvgIcon type={SVG_ICON_TYPES.GEAR_ICON} />}
              iconClassName="business-unit-form-panel-accordion-icon"
              rootClassName="business-unit-form-panel-accordion"
            >
              <div className="business-unit-form-panel-general-configuration-container">
                <div className="business-unit-form-panel-form-general-configuration-group business-unit-form-panel-form-risk-attributes-configuration-group">
                  <InputLabelWrapper label="Risk Attributes">
                    <div className="business-unit-form-panel-risk-attributes">
                      {Object.values(BusinessUnitRiskAttribute).map((value) => {
                        return (
                          <CheckboxLabel
                            checked={Boolean(
                              formDefaultValues?.riskAttributes?.includes(value)
                            )}
                            label={value}
                            onCheck={(checked) => {
                              onRiskAttributeChange(checked, value);
                            }}
                          />
                        );
                      })}
                    </div>
                  </InputLabelWrapper>
                </div>

                <div className="business-unit-form-panel-form-general-configuration-group business-unit-form-panel-form-compliance-requirements-configuration-group">
                  <InputLabelWrapper
                    label={translation(
                      `settings.details.complianceRequirements`
                    )}
                  >
                    <div className="business-unit-form-panel-risk-attributes">
                      {Object.values(BusinessUnitComplianceRequirements).map(
                        (value) => {
                          return (
                            <CheckboxLabel
                              checked={Boolean(
                                formDefaultValues?.complianceRequirements?.includes(
                                  value
                                )
                              )}
                              label={value}
                              onCheck={(checked) => {
                                onComplianceRequirementsChange(checked, value);
                              }}
                            />
                          );
                        }
                      )}
                    </div>
                  </InputLabelWrapper>
                </div>
              </div>
            </AccordionSection>
          </form>
        </div>
      </div>
      <div className="business-unit-form-panel-footer">
        <div className="business-unit-form-panel-footer-validation-error">
          {Object.keys(errors)?.length ? (
            <div className="business-unit-form-panel-footer-validation-error-text">
              <OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_EXCLAMATION_ICON} />
              <span>Please fill the mandatory fields</span>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="business-unit-form-panel-footer-buttons">
          <Button
            className="opus-secondary-button"
            onClick={() => {
              cancelationModalProps?.onOpen && cancelationModalProps.onOpen();
            }}
          >
            Cancel
          </Button>
          <Button
            className="opus-primary-button"
            disabled={formProps.loading}
            onClick={() => {
              const isFormValid = validateFormBeforeSubmit();

              if (isFormValid) {
                const formData =
                  businessUnitDataHandler.transformBusinessUnitFormValuesToCreateModel(
                    getValues() as ExtendedBusinessUnitFormModel,
                    businessUnitData as ExtendedBusinessUnitModel
                  );

                if (formProps?.onSubmit) {
                  formProps.onSubmit({ ...formData, id: businessUnitData?.id });
                }
              }
            }}
          >
            {BusinessUnitDetailsLabel()}
          </Button>
        </div>
      </div>
    </>
  );
};
