import { FC } from 'react';
import moment from 'moment';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
interface DateRangeProps {
  startDate: string | Date;
  endDate: string | Date;
}

const DateRange: FC<DateRangeProps> = ({ startDate, endDate }) => {
  const formattedStartDate = moment(startDate).format('D MMMM YYYY');
  const formattedEndDate = moment(endDate).format('D MMMM YYYY');

  return (
    <div className="date-range-container">
      <CalendarTodayIcon className="calendar-icon" />
      <span className="header-8  date-range-text">
        {formattedStartDate + ' – ' + formattedEndDate}
      </span>
    </div>
  );
};

export default DateRange;
