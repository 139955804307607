import { FunctionComponent } from 'react';
import { Box, Grid } from '@mui/material';
import Item from 'Dashboard/components/Item/Item';
import CampaignDetailsHeader from './components/CampaignDetailsHeader';
import CampaignFindingsByStatusWidget from './components/CampaignFindingsByStatus';
import CampaignFindingsByTicketStatusWidget from './components/CampaignFindingsByTicketStatusWidget';
import CampaignFindingsView from './components/CampaignFindingsView';
import CampaignTicketsPerPersonWidget from './components/CampaignTicketsPerPersonWidget';
import useCommonDispatch from 'Common/utils/use-dispatch';
import { campaignFindingsFilterItems } from 'shared/fixtures/data/campaign-findings-grid.data';
import { setListItems } from 'Campaigns/store';
import HeaderComponent from 'shared/components/Header/HeaderComponent';
import { CampaignActions } from './components/CampaignActions';

export const CampaignDetailsPage: FunctionComponent = () => {
  const dispatch = useCommonDispatch();
  dispatch(setListItems(campaignFindingsFilterItems));

  return (
    <>
      <HeaderComponent
        text="Campaign Tracker"
        sideElement={<CampaignActions />}
      />
      <Box sx={{ width: '100%' }} className="campaigns-page-container">
        <Grid container rowSpacing={2} columnSpacing={{ xs: 2, sm: 4, md: 3 }}>
          <Grid item xs={12} className="campaign-header-item">
            <CampaignDetailsHeader />
          </Grid>
          <Grid item xs={4}>
            <Item>
              <CampaignFindingsByStatusWidget />
            </Item>
          </Grid>
          <Grid item xs={4}>
            <Item>
              <CampaignTicketsPerPersonWidget />
            </Item>
          </Grid>
          <Grid item xs={4}>
            <Item>
              <CampaignFindingsByTicketStatusWidget />
            </Item>
          </Grid>
          <Grid item xs={12}>
            <Item>
              <CampaignFindingsView />
            </Item>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
