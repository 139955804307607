import { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { presentationalConfigurationForWidgets } from 'Common/components/Widgets/fixtures/widget-data';
import FormattedMessage from 'shared/components/FormattedMessage';
import VerticalSimpleBarChart from 'shared/components/VerticalSimpleBarChart';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import {
  useFetchFindingsByStatusQuery,
  useLazyFetchFindingsByStatusQuery,
} from 'Campaigns/store/api';
import useCommonSelector from 'Common/utils/use-selector';
import { ProjectInfoData } from 'Campaigns/interfaces/ProjectInfoWidget.interface';
import { getActiveCampaign } from 'Campaigns/store';

interface CampaignFindingsByStatusWidgetProps extends BaseComponentProps {}

export const CampaignFindingsByStatusWidget: FunctionComponent<
  CampaignFindingsByStatusWidgetProps
> = () => {
  const activeCampaign = useCommonSelector<ProjectInfoData | undefined>(
    getActiveCampaign
  );

  const [
    fetchFindingsByStatusData,
    {
      data: findingsByStatusData,
      isFetching: isFindingsByStatusDataLoading,
      error: findingsByStatusDataFetchError,
    },
  ] = useLazyFetchFindingsByStatusQuery();

  useEffect(() => {
    if (activeCampaign) {
      fetchFindingsByStatusData(activeCampaign.id);
    }
  }, [activeCampaign]);

  if (findingsByStatusDataFetchError)
    return (
      <div>
        {' '}
        <FormattedMessage
          id="common.anErrorOccured"
          defaultMessage="An error occured"
        />
      </div>
    );

  return (
    <div className="campaign-widget-container">
      <div className="campaign-widget-title">
        <FormattedMessage
          id="campaigns.widgets.findingsByStatus"
          defaultMessage="Findings by Status"
        />
      </div>
      <div className="campaign-widget-body">
        <VerticalSimpleBarChart
          fetchWidgetData={() => {}}
          widgetData={findingsByStatusData}
          isWidgetDataLoading={
            isFindingsByStatusDataLoading || !findingsByStatusData
          }
          enableTooltip={true}
          isResponsive={true}
          aspectRatio={1.9}
          style={
            presentationalConfigurationForWidgets[
              'campaign_findings_by_status_widget'
            ]?.style
          }
          applyColorList
        />
      </div>
    </div>
  );
};
