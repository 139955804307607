import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import SimpleLineChart from 'shared/components/SimpleLineChart';
import {
  BurndownWidgetData,
  BurndownWidgetDataProps,
} from 'Campaigns/interfaces/BurndownWidget.interface';
import { useLazyFetchBurndownWidgetQuery } from 'Campaigns/store/api';
import { presentationalConfigurationForWidgets } from 'Common/components/Widgets/fixtures/widget-data';
import FormattedMessage from 'shared/components/FormattedMessage';
import useCommonSelector from 'Common/utils/use-selector';
import { getActiveCampaign } from 'Campaigns/store';
import { ProjectInfoData } from 'Campaigns/interfaces/ProjectInfoWidget.interface';

export const CampaignBurndownWidget: FunctionComponent<
  BurndownWidgetDataProps
> = ({ campaignId }: BurndownWidgetDataProps) => {
  const activeCampaign = useCommonSelector<ProjectInfoData | undefined>(
    getActiveCampaign
  );

  const { t: translation } = useTranslation();
  const [burndownData, setBurndownData] = useState<
    BurndownWidgetData | undefined
  >();
  const [burndownWidgetLabels, setBurndownWidgetLabels] = useState<string[]>(
    []
  );

  const [
    fetchBurndownWidget,
    { data: burndown, isFetching, error: burndownDataFetchError },
  ] = useLazyFetchBurndownWidgetQuery();

  useEffect(() => {
    if (isFetching) setBurndownData(undefined);
  }, [isFetching]);

  useEffect(() => {
    if (activeCampaign) {
      fetchBurndownWidget(activeCampaign.id);
    }

    if (campaignId?.length) {
      fetchBurndownWidget(campaignId);
    }
  }, [activeCampaign, campaignId]);

  useEffect(() => {
    if (burndown) {
      setBurndownData(burndown);
    }
  }, [burndown]);

  useEffect(() => {
    if (burndownData) getTimelineforBurndownWidget(burndownData);
  }, [burndownData]);

  const getDatasetForWidget = (data: number[]) => {
    return data;
  };

  const getTimelineforBurndownWidget = ({
    startDate,
    endDate,
  }: BurndownWidgetData) => {
    const dates = [];
    let currentDate = moment(startDate);
    while (currentDate <= moment(endDate)) {
      dates.push(currentDate.format('DD MMM'));
      currentDate = moment(currentDate).add(1, 'days');
    }
    if (dates.length > 0) {
      setBurndownWidgetLabels(dates);
    }
  };

  const payload = {
    labels: burndownWidgetLabels,
    datasets: [
      {
        label: translation('campaigns.details.closedIssues'),
        data: getDatasetForWidget(burndownData?.bins || []),
      },
      {
        label: translation('campaigns.details.requiredBurndownSpeed'),
        data: getDatasetForWidget(burndownData?.binsExpectedSpeed || []),
      },
    ],
  };

  const options = useMemo(() => {
    return {
      scaleShowValues: true,
      gridLines: {
        drawBorder: false,
      },
      maintainAspectRatio: false,
      elements: {
        point: {
          pointStyle: 'star',
          pointRadius: 0,
        },
      },
      scales: {
        y: {
          max: burndownData?.totalTickets,
          beginAtZero: true,
        },
        x: {
          ticks: {
            autoSkip: true,
            maxTicksLimit: 10,
            major: { enabled: true },
          },
        },
      },
    };
  }, [burndownData]);

  if (burndownDataFetchError)
    return (
      <div>
        <FormattedMessage
          id="common.anErrorOccured"
          defaultMessage="An error occured"
        />
      </div>
    );

  if (isFetching || !burndown) return <CircularProgress size={14} />;

  return (
    <div className="burndown-widget">
      <div className="header-8 campaign-label">
        <FormattedMessage
          id="campaigns.burndown"
          defaultMessage="Campaign Tracker"
        />
      </div>
      <div className="campaign-widget-body">
        <SimpleLineChart
          fetchWidgetData={() => {}}
          widgetData={payload}
          style={
            presentationalConfigurationForWidgets['campaign_burndown_widget']
              ?.style
          }
          isWidgetDataLoading={false}
          enableTooltip={true}
          displayLegend
          legendPosition="bottom"
          isResponsive
          aspectRatio={5}
          options={options}
        />
      </div>
    </div>
  );
};
