import { CircularProgress, Stack } from '@mui/material';
import { ProjectInfoData } from 'Campaigns/interfaces/ProjectInfoWidget.interface';
import { getActiveCampaign } from 'Campaigns/store';
import {
  useFetchFindingsByTicketStatusQuery,
  useLazyFetchFindingsByTicketStatusQuery,
} from 'Campaigns/store/api';
import useCommonSelector from 'Common/utils/use-selector';
import { FunctionComponent, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import FormattedMessage from 'shared/components/FormattedMessage';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

const campaignFindingsByTicketStatusWidgetColors: Array<string> = [
  '#C3AFF2',
  '#9670EF',
  '#7547DC',
  '#562ABB',
  '#31156F',
];

interface CampaignFindingsByTicketStatusWidgetProps
  extends BaseComponentProps {}

export const CampaignFindingsByTicketStatusWidget: FunctionComponent<
  CampaignFindingsByTicketStatusWidgetProps
> = () => {
  const activeCampaign = useCommonSelector<ProjectInfoData | undefined>(
    getActiveCampaign
  );

  const [
    fetchFindingsByTicketStatus,
    {
      data: campaignFindingsByTicketStatusWidgetData,
      isFetching: isLoading,
      error: campaignFindingsByTicketStatusDataFetchError,
    },
  ] = useLazyFetchFindingsByTicketStatusQuery();

  const totalCount = useMemo(() => {
    if (campaignFindingsByTicketStatusWidgetData?.datasets[0]?.data) {
      return campaignFindingsByTicketStatusWidgetData?.datasets[0]?.data?.reduce(
        (acc: number, currentValue: number) => acc + currentValue,
        0
      );
    }
  }, [campaignFindingsByTicketStatusWidgetData]);

  useEffect(() => {
    if (activeCampaign) {
      fetchFindingsByTicketStatus(activeCampaign.id);
    }
  }, [activeCampaign]);

  if (campaignFindingsByTicketStatusDataFetchError)
    return (
      <div>
        <FormattedMessage
          id="common.anErrorOccured"
          defaultMessage="An error occured"
        />
      </div>
    );

  const renderWidgetBody = () => {
    if (isLoading || !campaignFindingsByTicketStatusWidgetData)
      return <CircularProgress />;

    return campaignFindingsByTicketStatusWidgetData && totalCount ? (
      <div className="campaign-findings-by-ticket-status-widget">
        <div className="campaign-finding-by-ticket-status-widget-render-body">
          {campaignFindingsByTicketStatusWidgetData?.labels?.map(
            (label: string, index: number) => {
              return (
                <div
                  className="campaign-finding-by-ticket-status-widget-render-body-cell"
                  style={{
                    backgroundColor:
                      campaignFindingsByTicketStatusWidgetColors[index],
                    width: `${
                      (campaignFindingsByTicketStatusWidgetData?.datasets[0]
                        .data[index] /
                        totalCount) *
                      100
                    }%`,
                  }}
                >
                  <div className="campaign-finding-by-ticket-status-widget-render-body-cell-text">
                    {
                      campaignFindingsByTicketStatusWidgetData?.datasets[0]
                        .data[index]
                    }
                  </div>
                </div>
              );
            }
          )}
        </div>
        <div className="campaign-finding-by-ticket-status-widget-legend">
          {campaignFindingsByTicketStatusWidgetData?.labels?.map(
            (label: string, index: number) => {
              return (
                <div className="campaign-finding-by-ticket-status-widget-legend-cell">
                  <div
                    className="campaign-finding-by-ticket-status-widget-legend-cell-indicator"
                    style={{
                      backgroundColor:
                        campaignFindingsByTicketStatusWidgetColors[index],
                    }}
                  ></div>
                  <div className="campaign-finding-by-ticket-status-widget-legend-cell-text">
                    {label}
                  </div>
                </div>
              );
            }
          )}
        </div>
      </div>
    ) : (
      <Stack
        height="100%"
        width="100%"
        justifyContent="center"
        alignItems="center"
      >
        <FormattedMessage id="common.noData" defaultMessage="No data" />
      </Stack>
    );
  };

  return (
    <div className="campaign-widget-container">
      <div className="campaign-widget-title">
        <span>Findings by Ticket Status</span>
      </div>
      <div className="campaign-widget-body">{renderWidgetBody()}</div>
    </div>
  );
};
