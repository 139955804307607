import { FunctionComponent, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import useCommonSelector from 'Common/utils/use-selector';
import { useGetWidgetDataByIdMutation } from 'Dashboard/store/api';
import { Typography, Grid } from '@mui/material';
import TextOverflow from 'shared/components/TextOverflowComponent';
import {
  OverflowDirection,
  OverflowTextDisplay,
} from 'shared/components/TextOverflowComponent/TextOverflow';
import CommonLoading from 'Common/components/Loading';
import DataGridTable from 'shared/components/DataGridTable';
import WidgetContainer from 'Common/components/Widgets/components/WidgetContainer';
import { getInitialFilterLoad, selectdashboardFilter } from 'Dashboard/store';
import NoDataToDisplayCard from '../NoDataCard';

export const TopRootCausesTableWidget: FunctionComponent = ({}) => {
  const { t: translation } = useTranslation();
  const navigate = useNavigate();
  const [getWidgetData, { isLoading, data: widgetDataSet }] =
    useGetWidgetDataByIdMutation();

  const dashboardFilterInitialLoad: boolean =
    useCommonSelector(getInitialFilterLoad);

  const filter = useCommonSelector(selectdashboardFilter);

  useEffect(() => {
    if (dashboardFilterInitialLoad) {
      let filterPayload = {
        businessUnitId: filter.businessUnitId,
        findingType: filter.findingTypes,
      };
      if (filter.findingTypes.length === 0)
        delete (filterPayload as { findingType?: any }).findingType;

      getWidgetData({
        id: 'top_root_causes',
        searchBody: { filter: filterPayload },
      });
    }
  }, [dashboardFilterInitialLoad, filter.businessUnitId, filter.findingTypes]);

  const topRootCausesTableColumns: Array<GridColDef> = [
    {
      field: 'findingTitle',
      headerName: translation('dashboards.widgets.topRootCauses.riskInfo'),
      flex: 3,
      headerAlign: 'left',
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <TextOverflow
          direction={OverflowDirection.WIDTH}
          type={OverflowTextDisplay.ELLIPSIS}
          maxWidth={250}
        >
          {params?.value}
        </TextOverflow>
      ),
    },
    {
      field: 'roundedImpactScore',
      headerName: translation('dashboards.widgets.topRootCauses.impactScore'),
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <Typography>{params?.value}</Typography>
      ),
    },
    {
      field: 'numberOfFindings',
      headerName: translation(
        'dashboards.widgets.topRootCauses.numberOfFindings'
      ),
      flex: 1,
      minWidth: 160,
      sortable: false,

      renderCell: (params: GridRenderCellParams) => (
        <Typography>{params?.value}</Typography>
      ),
    },
  ];

  const onNavigateToCodeEvent = (event: { ignore: any }, id: string) => {
    const newTab = window.open(
      `/risks?activeTabId=rootCause&openCodeEventId=${id}`,
      '_blank'
    );
    if (newTab) {
      newTab.focus();
    } else {
      navigate(`/risks?activeTabId=rootCause&openCodeEventId=${id}`);
    }
  };

  return (
    <Grid className="top_causes_table_widget dashboard-table-widget">
      <WidgetContainer
        title={translation(`dashboards.widgets.topRootCauses.title`)}
      >
        {widgetDataSet && !widgetDataSet.length && !isLoading ? (
          <NoDataToDisplayCard />
        ) : (
          <DataGridTable
            rowHeight={59}
            components={{
              LoadingOverlay: CommonLoading,
            }}
            loading={isLoading}
            rows={widgetDataSet || []}
            columns={topRootCausesTableColumns}
            getRowId={(row) => {
              return row?.id;
            }}
            getRowClassName={(params) => 'clickable-item'}
            onRowClick={(params: GridRowParams<any>, event: any) => {
              if (!event.ignore) onNavigateToCodeEvent(event, params?.row?.id);
            }}
            hideFooter
            disableColumnMenu
            disableColumnSelector
            isRowSelectable={() => false}
            isCellEditable={() => false}
          />
        )}
      </WidgetContainer>
    </Grid>
  );
};
