import {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  ApiDynamicOptionSourceProperties,
  FilterOption,
} from 'shared/models/data/data-filter.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { OptionListType } from '../OptionList/OptionList';
import BaseOptionList from '../BaseOptionList';
import { useFetchDataBasedOnParametersMutation } from 'shared/store/api';
import { debounce } from 'lodash';

interface ApiDynamicOptionListProps extends BaseComponentProps {
  title: string;
  source: ApiDynamicOptionSourceProperties;
  mode: OptionListType;
  onOptionsSelect?: (
    options: Array<FilterOption>,
    isAllSelected?: boolean
  ) => void;
  open?: boolean;
  onAllSelect?: (options: Array<FilterOption>) => void;
  selectedOptions?: Array<FilterOption>;
  extraOptions?: Array<FilterOption>;
  actionHandlers?: {
    goBackHandler?: () => void;
  };
  allSelected?: boolean;
  sortMethod?: (optionData: Array<FilterOption>) => Array<FilterOption>;
}

export const ApiDynamicOptionList: FunctionComponent<
  ApiDynamicOptionListProps
> = ({
  title,
  source,
  mode,
  onOptionsSelect,
  open,
  onAllSelect,
  selectedOptions,
  extraOptions,
  actionHandlers,
  allSelected,
  sortMethod,
}) => {
  const { url, method, body, optionsTransformer, searchProperty } = source;

  const [searchValue, setSearchValue] = useState<string>('');

  const [fetchOptions, { data: optionsData, isLoading: optionsDataLoading }] =
    useFetchDataBasedOnParametersMutation();

  useEffect(() => {
    if (url && open) {
      let searchBody = {
        ...(body || {}),
      };

      fetchOptions({
        url,
        method: method || 'POST',
        data: searchBody,
      });
    }
  }, [url, open, method]);

  const getAllOptionData = useCallback(
    (includeSelectedOptions?: boolean): Array<FilterOption> => {
      if (optionsData) {
        const transformedOptions = optionsTransformer
          ? optionsTransformer(optionsData)
          : optionsData;

        const allOptions = [...(extraOptions || []), ...transformedOptions];

        const options = includeSelectedOptions
          ? [...(selectedOptions || []), ...allOptions].filter(
              (
                option: FilterOption,
                index: number,
                optionList: Array<FilterOption>
              ) => {
                return (
                  index ===
                  optionList.findIndex(
                    (optionItem) => optionItem.value === option.value
                  )
                );
              }
            )
          : allOptions;

        return sortMethod ? sortMethod(options) : options;
      }

      return [];
    },
    [optionsData]
  );

  const optionsWithSelected = getAllOptionData(true);

  const optionsWithoutSelected = getAllOptionData();

  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setSearchValue(value);

    let searchBody = {
      ...(body || {}),
      ...(searchProperty
        ? {
            [searchProperty]: value,
          }
        : {}),
    };

    fetchOptions({
      url,
      method: method || 'POST',
      data: searchBody,
    });
  };

  const getEveryOptionSelected = () => {
    return Boolean(
      optionsWithSelected.every((option) =>
        selectedOptions?.find(
          (selectedOption) => selectedOption.value === option.value
        )
      )
    );
  };

  const handleOptionsSelect = (
    selectedOptions: Array<FilterOption>,
    allSelected?: boolean
  ) => {
    onOptionsSelect && onOptionsSelect(selectedOptions, false);
  };

  const handleAllSelect = () => {
    const everyOptionSelected = getEveryOptionSelected();

    onOptionsSelect &&
      onOptionsSelect(everyOptionSelected ? [] : optionsWithSelected, false);
  };

  return (
    <BaseOptionList
      title={title}
      options={
        searchValue.length ? optionsWithoutSelected : optionsWithSelected
      }
      optionsLoading={optionsDataLoading}
      mode={mode}
      onOptionsSelect={handleOptionsSelect}
      selectedOptions={selectedOptions}
      actionHandlers={actionHandlers}
      onAllSelect={handleAllSelect}
      allSelected={getEveryOptionSelected()}
      onSearch={debounce(onSearch, 400)}
      enableAllSelection
    />
  );
};
