import { Grid } from '@mui/material';
import {
  fillDataPlugin,
  presentationalConfigurationForWidgets,
} from 'Common/components/Widgets/fixtures/widget-data';
import useCommonSelector from 'Common/utils/use-selector';
import { DashboardFilterType } from 'Dashboard/interfaces/Dashboard';
import { getInitialFilterLoad, selectdashboardFilter } from 'Dashboard/store';
import {
  useFetchOperationalDashboardWidgetDataMutation,
  useGetUserAvailableNodesOfTypeMutation,
} from 'Dashboard/store/api';
import { FunctionComponent, useEffect, useMemo } from 'react';
import HorizontalStackedBarChart from 'shared/components/HorizontalStackedBarChart';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import VerticalSimpleBarChart from 'shared/components/VerticalSimpleBarChart';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { OperationalWidgetAnalyticsType } from 'shared/models/data/operational-widget-data.model';
import NoDataToDisplayCard from '../NoDataCard';
import DashboardChartCard from '../DashboardChartCard';
import { DashboardSection } from '../DashboardSection';
import { useTranslation } from 'react-i18next';
import { useFetchFilterInformationForFieldMutation } from 'Risk/store/api';
import { AutocompleteOption } from 'FindingDetails/store/api';
import { useNavigate } from 'react-router-dom';
import { GridType } from 'Risk/store';
import { NavigationHandler } from 'shared/handlers/navigation.handler';
import { FilterOption } from 'shared/components/SearchFilter/LegacyAdvanceFilter';
import { OrganizationNodeType } from 'Organization/interfaces/OrganizationNodeType.enum';
import { OrganizationNode } from 'Organization/interfaces/OrganizationNode.interface';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { OrganizationalDataProps } from 'Dashboard/DashboardPage';
import {
  FindingState,
  unassignedFilterOption,
} from 'shared/fixtures/data/risk-grid.data';

const navigationHandler = new NavigationHandler();

interface OperationalOverviewProps
  extends BaseComponentProps,
    OrganizationalDataProps {}

const OperationalOverview: FunctionComponent<OperationalOverviewProps> = ({
  scopeData,
  groupData,
}) => {
  const { t: translation } = useTranslation();

  const dashboardFilter: DashboardFilterType = useCommonSelector(
    selectdashboardFilter
  );

  const [fetchAsigneeOptions, { data: assigneeOptions }] =
    useFetchFilterInformationForFieldMutation();

  useEffect(() => {
    fetchAsigneeOptions({
      field: 'assigneeUserId',
    });
  }, []);

  const dashboardFilterInitialLoad: boolean =
    useCommonSelector(getInitialFilterLoad);

  const businessUnitNavigationFilter = useMemo<
    Partial<{ businessUnitId: Array<FilterOption> }>
  >(() => {
    const selectedServices = dashboardFilter.businessUnitItems?.filter(
      (businessUnitItem) => {
        return dashboardFilter.selectedServices.find(
          (serviceId) => businessUnitItem.id === serviceId
        );
      }
    );

    return dashboardFilter.presentationalBusinessUnitId?.includes('all')
      ? selectedServices?.length
        ? { businessUnitId: selectedServices }
        : {}
      : {
          businessUnitId: dashboardFilter.businessUnitItems,
        };
  }, [dashboardFilter.businessUnitId, dashboardFilter.selectedServices]);

  const groupNavigationFilter = useMemo<
    Partial<{ groupId: FilterOption }>
  >(() => {
    if (businessUnitNavigationFilter.businessUnitId) return {};

    const selectedGroup: OrganizationNode | undefined = groupData?.find(
      (groupDataItem: OrganizationNode) =>
        dashboardFilter.selectedGroup?.includes(groupDataItem.id)
    );

    return selectedGroup
      ? {
          groupId: {
            id: selectedGroup.id,
            name: selectedGroup.name,
          },
        }
      : {};
  }, [dashboardFilter.selectedGroup, groupData, businessUnitNavigationFilter]);

  const scopeNavigationFilter = useMemo<
    Partial<{ scopeId: FilterOption }>
  >(() => {
    if (businessUnitNavigationFilter.businessUnitId) return {};

    const selectedScope: OrganizationNode | undefined = scopeData?.find(
      (scopeDataItem: OrganizationNode) =>
        dashboardFilter.selectedScope?.includes(scopeDataItem.id)
    );

    return selectedScope
      ? {
          scopeId: {
            id: selectedScope.id,
            name: selectedScope.name,
          },
        }
      : {};
  }, [dashboardFilter.selectedScope, scopeData, businessUnitNavigationFilter]);

  const findingTypeNavigationFilter = useMemo<
    Partial<{ findingType: Array<FilterOption> }>
  >(() => {
    return dashboardFilter.findingTypes?.length
      ? {
          findingType: dashboardFilter.findingTypes.map((findingType) => ({
            id: findingType,
            name: findingType,
          })),
        }
      : {};
  }, [dashboardFilter.findingTypes]);

  const [
    getByAssigneeWidgetData,
    { data: byAssigneeWidgetData, isLoading: byAssigneeWidgetDataLoading },
  ] = useFetchOperationalDashboardWidgetDataMutation();
  const [
    getByStatusWidgetData,
    { data: byStatusWidgetData, isLoading: byStatusWidgetDataLoading },
  ] = useFetchOperationalDashboardWidgetDataMutation();
  const [
    getByTicketStatusWidgetData,
    {
      data: byTicketStatusWidgetData,
      isLoading: byTicketStatusWidgetDataLoading,
    },
  ] = useFetchOperationalDashboardWidgetDataMutation();

  const [
    getByTicketAgeWidgetData,
    { data: byTicketAgeWidgetData, isLoading: byTicketAgeWidgetDataLoading },
  ] = useFetchOperationalDashboardWidgetDataMutation();

  useEffect(() => {
    if (dashboardFilterInitialLoad) {
      getByAssigneeWidgetData({
        businessUnitIds: dashboardFilter.businessUnitId,
        findingTypes: dashboardFilter.findingTypes,
        type: OperationalWidgetAnalyticsType.ACCUMULATIVE_OPEN_FINDINGS_BY_ASSIGNEE,
      });
      getByStatusWidgetData({
        businessUnitIds: dashboardFilter.businessUnitId,
        findingTypes: dashboardFilter.findingTypes,
        type: OperationalWidgetAnalyticsType.ACCUMULATIVE_OPEN_FINDINGS_BY_STATUS,
      });

      getByTicketStatusWidgetData({
        businessUnitIds: dashboardFilter.businessUnitId,
        findingTypes: dashboardFilter.findingTypes,
        type: OperationalWidgetAnalyticsType.ACCUMULATIVE_OPEN_FINDINGS_BY_TICKET_STATUS,
      });

      getByTicketAgeWidgetData({
        businessUnitIds: dashboardFilter.businessUnitId,
        findingTypes: dashboardFilter.findingTypes,
        type: OperationalWidgetAnalyticsType.ACCUMULATIVE_OPEN_FINDINGS_BY_TICKET_AGE,
      });
    }
  }, [
    dashboardFilterInitialLoad,
    dashboardFilter.businessUnitId,
    dashboardFilter.findingTypes,
  ]);

  const renderChartWidget = (
    type: OperationalWidgetAnalyticsType,
    widgetData: any,
    isLoading: boolean
  ) => {
    if ((!widgetData || widgetData?.datasets.length == 0) && !isLoading) {
      return <NoDataToDisplayCard displayIcon={true} />;
    }
    if (type === OperationalWidgetAnalyticsType.NEW_FINDINGS_BY_ASSIGNEE) {
      return (
        <>
          <VerticalSimpleBarChart
            fetchWidgetData={() => {}}
            isWidgetDataLoading={byAssigneeWidgetDataLoading}
            widgetData={byAssigneeWidgetData}
            displayLabels={false}
            aspectRatio={1.3}
            enableTooltip
            style={presentationalConfigurationForWidgets['top_assigned'].style}
            applyColorList
            options={
              presentationalConfigurationForWidgets['top_assigned']
                .options as any
            }
            onElementClick={(value, label) => {
              const assignee = assigneeOptions?.find(
                (assigneeOption: AutocompleteOption) =>
                  assigneeOption.label === label
              );

              navigationHandler.handleRiskNavigation(GridType.None, {
                state: {
                  id: FindingState.ACTIVE,
                  name: FindingState.ACTIVE,
                },
                assigneeUserId: assignee
                  ? [
                      {
                        id: assignee?.value,
                        name: assignee?.label,
                      },
                    ]
                  : [unassignedFilterOption],
                ...businessUnitNavigationFilter,
                ...findingTypeNavigationFilter,
                ...scopeNavigationFilter,
                ...groupNavigationFilter,
              });
            }}
          />
          {!isLoading && <div></div>}
        </>
      );
    } else if (type === OperationalWidgetAnalyticsType.NEW_FINDINGS_BY_STATUS) {
      return (
        <>
          <VerticalSimpleBarChart
            fetchWidgetData={() => {}}
            isWidgetDataLoading={byStatusWidgetDataLoading}
            widgetData={byStatusWidgetData}
            displayLabels={false}
            aspectRatio={1.3}
            isResponsive
            enableTooltip
            style={presentationalConfigurationForWidgets['by_status'].style}
            applyColorList
            options={
              presentationalConfigurationForWidgets['by_status'].options as any
            }
            onElementClick={(value, label) => {
              navigationHandler.handleRiskNavigation(
                GridType.None,
                {
                  status: [
                    {
                      id: label,
                      name: label,
                    },
                  ],
                  ...businessUnitNavigationFilter,
                  ...findingTypeNavigationFilter,
                  ...scopeNavigationFilter,
                  ...groupNavigationFilter,
                },
                true
              );
            }}
          />
          {!isLoading && <div></div>}
        </>
      );
    } else if (
      type === OperationalWidgetAnalyticsType.NEW_FINDINGS_BY_TICKET_STATUS
    ) {
      return (
        <>
          <HorizontalStackedBarChart
            fetchWidgetData={() => {}}
            isWidgetDataLoading={byTicketStatusWidgetDataLoading}
            widgetData={byTicketStatusWidgetData}
            displayLabels={true}
            aspectRatio={3}
            isResponsive
            legendPosition="bottom"
            style={
              presentationalConfigurationForWidgets['finding_by_ticket_status']
                .style
            }
            displayLegend
            customLegendContainerId={
              'operational-dashboard-section-by-ticket-status-chart-legend-container'
            }
            options={
              presentationalConfigurationForWidgets['finding_by_ticket_status']
                .options as any
            }
            plugins={[fillDataPlugin]}
          />
        </>
      );
    } else if (type === OperationalWidgetAnalyticsType.FINDING_BY_TICKET_AGE) {
      return (
        <>
          <HorizontalStackedBarChart
            fetchWidgetData={() => {}}
            isWidgetDataLoading={byTicketAgeWidgetDataLoading}
            widgetData={byTicketAgeWidgetData}
            displayLabels={false}
            aspectRatio={3}
            isResponsive
            legendPosition="bottom"
            style={
              presentationalConfigurationForWidgets['finding_by_ticket_age']
                .style
            }
            displayLegend
            customLegendContainerId={
              'operational-dashboard-section-by-ticket-age-chart-legend-container'
            }
            options={
              presentationalConfigurationForWidgets['finding_by_ticket_age']
                .options as any
            }
            plugins={[fillDataPlugin]}
          />
        </>
      );
    }
  };

  return (
    <DashboardSection
      title={translation(`dashboards.widgets.operationalOverview`)}
      icon={<OpusSvgIcon type={SVG_ICON_TYPES.CHART_SIMPLE_ICON} />}
    >
      <div className="operational-dashboard-section-body">
        <Grid container columns={12} spacing={2}>
          <Grid item xs={6}>
            <DashboardChartCard
              title="Top Assignees"
              className="operational-dashboard-section-chart-container"
            >
              {renderChartWidget(
                OperationalWidgetAnalyticsType.NEW_FINDINGS_BY_ASSIGNEE,
                byAssigneeWidgetData,
                byAssigneeWidgetDataLoading
              )}
            </DashboardChartCard>
          </Grid>
          <Grid item xs={6}>
            <DashboardChartCard
              title="Findings by Status"
              className="operational-dashboard-section-chart-container"
            >
              {renderChartWidget(
                OperationalWidgetAnalyticsType.NEW_FINDINGS_BY_STATUS,
                byStatusWidgetData,
                byStatusWidgetDataLoading
              )}
            </DashboardChartCard>
          </Grid>
          <Grid item xs={6}>
            <DashboardChartCard
              title="Findings by Ticket Status"
              className="operational-dashboard-section-chart-container operational-dashboard-section-chart-container-stacked-chart"
            >
              {renderChartWidget(
                OperationalWidgetAnalyticsType.NEW_FINDINGS_BY_TICKET_STATUS,
                byTicketStatusWidgetData,
                byTicketStatusWidgetDataLoading
              )}
            </DashboardChartCard>
          </Grid>
          <Grid item xs={6}>
            <DashboardChartCard
              title={translation(`dashboards.widgets.findingsByTicketAge`)}
              className="operational-dashboard-section-chart-container operational-dashboard-section-chart-container-stacked-chart"
            >
              {renderChartWidget(
                OperationalWidgetAnalyticsType.FINDING_BY_TICKET_AGE,
                byTicketAgeWidgetData,
                byTicketAgeWidgetDataLoading
              )}
            </DashboardChartCard>
          </Grid>
        </Grid>
      </div>
    </DashboardSection>
  );
};
export default OperationalOverview;
