import { AuthorizedContent } from '@frontegg/react';
import {
  Alert,
  Button,
  Menu,
  MenuItem,
  Snackbar,
  Typography,
} from '@mui/material';
import { CampaignStatus } from 'Campaigns/interfaces/ProjectInfoWidget.interface';
import {
  useFetchProjectInfoWidgetQuery,
  useUpdateCampaignMutation,
} from 'Campaigns/store/api';
import { FunctionComponent, MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import MoreActionsMenu from 'shared/components/MoreActionsMenu';
import { ApplicationPermission } from 'shared/enums/permission.enum';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

interface CampaignActionsProps extends BaseComponentProps {}

export const CampaignActions: FunctionComponent<CampaignActionsProps> = () => {
  const { t: translation } = useTranslation();
  const { id: campaignIdParam } = useParams();

  const { data } = useFetchProjectInfoWidgetQuery(campaignIdParam || '', {
    skip: campaignIdParam === undefined,
  });

  const [updateCampaign, { isLoading: isUpdating }] =
    useUpdateCampaignMutation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeCampaignStatus = (id: string, status: CampaignStatus) => {
    updateCampaign({
      id,
      data: {
        status,
      },
    });
  };

  const renderCampaignDetailsPageActions = () => {
    return (
      <AuthorizedContent
        requiredPermissions={[ApplicationPermission.CAMPAIGNS_WRITE]}
        render={(isAuthorized) => {
          return (
            <MoreActionsMenu
              actions={[
                {
                  name: translation(
                    `common.${
                      data?.status === CampaignStatus.ARCHIVED
                        ? 'unarchive'
                        : 'archive'
                    }`,
                  ),
                  disabled: !isAuthorized || data === undefined || isUpdating,
                  onClick: () => {
                    handleClose();
                    changeCampaignStatus(
                      campaignIdParam as string,
                      data?.status === CampaignStatus.ARCHIVED
                        ? CampaignStatus.NEW
                        : CampaignStatus.ARCHIVED,
                    );
                  },
                },
              ]}
            />
          );
        }}
      />
    );
  };

  return (
    <div>
      {renderCampaignDetailsPageActions()}
      <Snackbar
        open={isUpdating}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert severity="info" sx={{ width: '100%' }}>
          {data?.status === CampaignStatus.ARCHIVED
            ? 'Unarchiving campaign'
            : 'Archiving campaign'}
        </Alert>
      </Snackbar>
    </div>
  );
};
