import { BoxProps, Paper, styled } from '@mui/material';
import { ReactNode } from 'react';

interface ItemContainer extends BoxProps {
  children: ReactNode;
}

export default function ItemContainer({ children, sx }: ItemContainer) {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    borderRadius: '15px',
    boxShadow: 'none',
    paddingLeft: '0px',
  }));
  return <Item sx={sx}>{children}</Item>;
}
