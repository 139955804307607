import { FunctionComponent, useEffect } from 'react';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { Navigation, SwiperOptions } from 'swiper';
import BaseCarouselList from 'shared/components/CommonCarouselList';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { BusinessUnitMetadata } from 'shared/models/data/business-unit.model';
import BusinessUnitCarouselCard from '../BusinessUnitCarouselCard';
import { CircularProgress } from '@mui/material';
import useCommonDispatch from 'Common/utils/use-dispatch';
import { DashboardFilterType } from 'Dashboard/interfaces/Dashboard';
import useCommonSelector from 'Common/utils/use-selector';
import { selectdashboardFilter, setdashboardFilters } from 'Dashboard/store';
import { useTranslation } from 'react-i18next';
import { OrganizationalDataProps } from 'Dashboard/DashboardPage';

interface BusinessUnitCarouselListProps
  extends BaseComponentProps,
    OrganizationalDataProps {
  businessUnits: Array<BusinessUnitMetadata>;
  isLoading: boolean;
}

const dashboardCarouselConfiguration: SwiperOptions = {
  slidesPerView: 7,
  navigation: {
    prevEl: '.dashboard-previous-control-button',
    nextEl: '.dashboard-next-control-button',
    disabledClass: 'dashboard-control-button-hidden',
    hideOnClick: true,
  },
  autoHeight: true,
  wrapperClass: 'dashboard-carousel-wrapper',
  spaceBetween: 16,
};

const getDashboardCarouselControlButtons = () => {
  return (
    <>
      <div className="dashboard-previous-control-button">
        <OpusSvgIcon
          type={SVG_ICON_TYPES.CARET_LEFT_ICON}
          className="dashboard-carousel-caret-icon"
        />
      </div>

      <div className="dashboard-next-control-button">
        <OpusSvgIcon
          type={SVG_ICON_TYPES.CARET_RIGHT_ICON}
          className="dashboard-carousel-caret-icon"
        />
      </div>
    </>
  );
};

export const BusinessUnitCarouselList: FunctionComponent<
  BusinessUnitCarouselListProps
> = ({ businessUnits, isLoading, scopeData, groupData }) => {
  const { t: translation } = useTranslation();

  const dispatch = useCommonDispatch();
  const dashboardFilter: DashboardFilterType = useCommonSelector(
    selectdashboardFilter,
  );

  useEffect(() => {
    if (businessUnits?.length) {
      selectAllBusinessUnits();
    }
  }, [businessUnits]);

  const onSelectBusinessUnit = (businessUnitId: Array<string>) => {
    if (businessUnitId.includes('all')) {
      const notAllBusinessUnits = businessUnits.filter(
        (businessUnit) => businessUnit.id !== 'all',
      );

      dispatch(
        setdashboardFilters({
          ...dashboardFilter,
          presentationalBusinessUnitId: ['all'],
          businessUnitId: notAllBusinessUnits.map(
            (businessUnit) => businessUnit.id,
          ),
          businessUnitItems: [
            ...notAllBusinessUnits.map((businessUnit) => ({
              id: businessUnit.id,
              name: businessUnit.title,
            })),
          ],
        }),
      );
    } else {
      dispatch(
        setdashboardFilters({
          ...dashboardFilter,
          businessUnitId,
          presentationalBusinessUnitId: businessUnitId,
          businessUnitItems: businessUnitId.map((id) => {
            const businessUnit = businessUnits.find(
              (businessUnit) => businessUnit.id === id,
            );

            return {
              id,
              name: businessUnit?.title || id,
            };
          }),
        }),
      );
    }
  };

  const selectAllBusinessUnits = () => {
    const notAllBusinessUnits = businessUnits.filter(
      (businessUnit) => businessUnit.id !== 'all',
    );

    dispatch(
      setdashboardFilters({
        ...dashboardFilter,
        presentationalBusinessUnitId: ['all'],
        businessUnitId: notAllBusinessUnits.map(
          (businessUnit) => businessUnit.id,
        ),
        businessUnitItems: [
          ...notAllBusinessUnits.map((businessUnit) => ({
            id: businessUnit.id,
            name: businessUnit.title,
          })),
        ],
      }),
    );
  };

  return (
    <div className="business-unit-carousel-list-container">
      {isLoading ? (
        <CircularProgress size={24} />
      ) : businessUnits?.length ? (
        <BaseCarouselList
          configuration={dashboardCarouselConfiguration}
          items={businessUnits}
          renderItem={(item) => (
            <BusinessUnitCarouselCard
              {...item}
              onSelect={onSelectBusinessUnit}
              scopeData={scopeData}
              groupData={groupData}
            />
          )}
          getAdditionalChildren={() => getDashboardCarouselControlButtons()}
        />
      ) : (
        <div className="no-data-container">{translation(`common.noData`)}</div>
      )}
    </div>
  );
};
