import useCommonDispatch from 'Common/utils/use-dispatch';
import { FunctionComponent } from 'react';
import { campaignsFilterItems } from 'shared/fixtures/data/campaigns-grid.data';
import CampaignsAdvanceFilter from './components/CampaignsAdvanceFilter';
import CampaignsListView from './components/CampaignsListView';
import { setListItems } from './store';
import HeaderComponent from 'shared/components/Header/HeaderComponent';
export const CampaignsPage: FunctionComponent = () => {
  const dispatch = useCommonDispatch();
  dispatch(setListItems(campaignsFilterItems));
  return (
    <>
      <HeaderComponent text="Campaigns" />
      <CampaignsAdvanceFilter />

      <CampaignsListView />
    </>
  );
};
