import { FilterItem } from 'shared/components/SearchFilter/LegacyAdvanceFilter';
import { TypeFilter } from 'shared/enums/campaigns.enum';
import { riskScoreItems } from './risk-grid.data';

export const campaignFindingsFilterItems: Array<FilterItem> = [
  {
    id: 'riskScore',
    name: 'Risk Score',
    type: TypeFilter.SINGLE_SELECT,
    items: riskScoreItems,
    loaded: true,
  },
  {
    id: 'title',
    name: 'Finding',
    items: [],
    type: TypeFilter.MULTI_SELECT,
  },
  {
    id: 'resourceType',
    name: 'Resource',
    items: [],
    type: TypeFilter.MULTI_SELECT,
  },
  {
    id: 'ticketLink',
    name: 'Ticket',
    type: TypeFilter.SINGLE_SELECT,
    items: [
      {
        id: true,
        name: 'Yes',
      },
      {
        id: false,
        name: 'No',
      },
    ],
    loaded: true,
  },
  {
    id: 'ticketStatus',
    name: 'Ticket Status',
    type: TypeFilter.MULTI_SELECT,
    items: [],
  },

  {
    id: 'status',
    name: 'Finding Status',
    type: TypeFilter.MULTI_SELECT,
    items: [],
  },
  {
    id: 'slaBreachDate',
    type: TypeFilter.SINGLE_SELECT,
    name: 'SLA Status',
    items: [
      {
        id: 'Ontime',
        name: 'On time',
      },
      {
        id: 'Late',
        name: 'Late',
      },
    ],
    loaded: true,
  },
];
