import { useAuthUser } from '@frontegg/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Avatar, Button, FormControlLabel, Tooltip } from '@mui/material';
import {
  useCreateCampaignMutation,
  useFetchCampaignQuery,
  useLazyFetchCampaignQuery,
  useUpdateCampaignByIdMutation,
} from 'Campaigns/store/api';
import Autocomplete from 'Common/components/Autocomplete';
import CommonTooltip from 'Common/components/CommonTooltip/CommonTooltip';
import DatePicker from 'Common/components/DatePicker';
import useCommonSelector from 'Common/utils/use-selector';
import { AvailableUser } from 'FindingDetails/interfaces/Users';
import {
  AutocompleteOption,
  useFetchAvailableUsersToAssignMutation,
  useFetchFindingsWithResourceMutation,
} from 'FindingDetails/store/api';
import {
  getAdditionalSearchParams,
  getVulnerabilitySearchParams,
} from 'Risk/store';
import { FunctionComponent, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import InputLabelWrapper from 'shared/components/InputLabelWrapper';
import { CampaignCreateFormDateRange } from 'shared/enums/campaigns.enum';
import {
  campaignCreateFormBusinessPriorityOptions,
  campaignCreateFormDateRangeOptions,
} from 'shared/fixtures/data/campaign-create-form.data';
import {
  CampaignCreateFormValues,
  CampaignCriticality,
  CampaignModel,
  CampaignType,
  SingleCampaignModel,
  campaignFormSchema,
} from 'shared/models/data/campaigns.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { CampaignFormDateService } from 'shared/services/campaigns/campaign-form-date.service';

interface CampaignEditFormProps extends BaseComponentProps {
  onCloseModal: () => void;
  campaignData: SingleCampaignModel;
  successCallback?: () => void;
}

type HookFormValueKey =
  | `${string}`
  | `${string}.${string}`
  | `${string}.${number}`;

const campaignFormDateService = new CampaignFormDateService();

export const CampaignEditForm: FunctionComponent<CampaignEditFormProps> = ({
  onCloseModal,
  campaignData,
  successCallback,
}) => {
  const { t: translation } = useTranslation();

  const {
    register,
    handleSubmit: handleFormSubmit,
    watch: watchFormValue,
    setValue: setFormValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(campaignFormSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const additionalSearchParams = useCommonSelector(getAdditionalSearchParams);
  const vulnerabilitySearchParams = useCommonSelector(
    getVulnerabilitySearchParams
  );

  const [
    editCampaign,
    { isLoading: editCampaignLoading, isSuccess: editCampaignSuccess },
  ] = useUpdateCampaignByIdMutation();

  const ownerUser = watchFormValue('ownerUserId');
  const stakeholders = watchFormValue('stakeholders');

  const duration = watchFormValue('duration');

  const startTime = watchFormValue('startTime');
  const endDate = watchFormValue('endDate');

  const priority = watchFormValue('priority');

  const [fetchUserList, { data: userList, isLoading: userListLoading }] =
    useFetchAvailableUsersToAssignMutation();

  const userOptionList = useMemo<Array<AutocompleteOption>>(() => {
    if (userList) {
      return userList?.map((user: AvailableUser) => ({
        value: user.id,
        label: user.name,
        iconUrl: user.profilePictureUrl,
      }));
    }

    return [];
  }, [userList]);

  const setDefaultValues = () => {
    const duration = campaignFormDateService.determineDateRangeType(
      campaignData.startTime,
      campaignData.endDate
    );

    setFormValue('name', campaignData.name);
    setFormValue('duration', {
      value: duration,
      label: duration,
    });
    setFormValue('startTime', campaignData.startTime);
    setFormValue('endDate', campaignData.endDate);
    setFormValue('ownerUserId', {
      value: campaignData.ownerUser?.id,
      label: campaignData.ownerUser?.name,
      iconUrl: campaignData.ownerUser?.profilePictureUrl as string,
    });
    setFormValue(
      'stakeholders',
      campaignData.stakeholders?.map((stakeholder) => ({
        value: stakeholder.id,
        label: stakeholder.name,
        iconUrl: stakeholder.profilePictureUrl,
      }))
    );
    setFormValue('priority', {
      value: campaignData.priority,
      label: campaignData.priority,
    });
    setFormValue('type', {
      value: campaignData.type,
      label: campaignData.type,
    });
  };

  useEffect(() => {
    if (campaignData) {
      setDefaultValues();
    }
  }, [campaignData, userOptionList]);

  useEffect(() => {
    fetchUserList({});
  }, []);

  useEffect(() => {
    if (editCampaignSuccess) {
      successCallback && successCallback();
    }
  }, [editCampaignSuccess]);

  const onFormSubmit = (values: any) => {
    const getDateRangeFunction =
      campaignFormDateService.getRangeCalculatorFunctionByOptions(
        values.duration?.value
      );

    const dateRange = getDateRangeFunction();
    editCampaign({
      id: campaignData.id,
      body: {
        ...values,
        ownerUserId: values.ownerUserId.value,
        priority: values.priority.value,
        stakeholders: values.stakeholders.map(
          (stakeholder: AutocompleteOption) => stakeholder.value
        ),
        type: values.type.value,
        appliedFilter: {
          filter: additionalSearchParams,
          vulnerabilityFilter: vulnerabilitySearchParams,
        },
        startTime:
          values.duration?.value === CampaignCreateFormDateRange.CUSTOM
            ? values.startTime
            : dateRange.startDate,
        endDate:
          values.duration?.value === CampaignCreateFormDateRange.CUSTOM
            ? values.endDate
            : dateRange.endDate,
      },
    });
  };

  return (
    <div className="campaign-create-modal-form-container">
      <form className="campaign-create-modal-form">
        <InputLabelWrapper label="Campaign Title">
          <input
            type="text"
            className={`text-field-1 ${
              errors.name ? 'input-validation-error-1' : ''
            }`}
            {...register('name')}
          />
        </InputLabelWrapper>
        <InputLabelWrapper label="Campaign Owner">
          <Autocomplete
            single
            classes={{
              root: 'multi-select-field-1 ',
              paper: 'multi-select-field-paper-1',
              inputRoot: errors.ownerUserId ? 'input-validation-error-1' : '',
            }}
            model="ownerUserId"
            optionList={userOptionList}
            areOptionsLoaded
            getIcon={(option) => (
              <Avatar src={option.iconUrl} sx={{ width: 16, height: 16 }} />
            )}
            onChangeCallBack={(
              model: string,
              filterOptions: Array<AutocompleteOption> | AutocompleteOption
            ) => {
              setFormValue(model as HookFormValueKey, filterOptions);
            }}
            values={ownerUser}
            initialSelectedValues={ownerUser}
          />
        </InputLabelWrapper>
        <InputLabelWrapper label="Campaign Duration">
          <Autocomplete
            classes={{
              root: 'multi-select-field-1 ',
              paper: 'multi-select-field-paper-1',
              inputRoot: errors.duration ? 'input-validation-error-1' : '',
            }}
            model="duration"
            onChangeCallBack={(
              model: string,
              option: Array<AutocompleteOption> | AutocompleteOption
            ) => {
              setFormValue(model as HookFormValueKey, option);
            }}
            optionList={campaignCreateFormDateRangeOptions}
            single
            placeholder={translation(`campaigns.modal.placeholders.dateRange`)}
            values={duration}
            initialSelectedValues={duration}
          />
        </InputLabelWrapper>
        {duration?.value === CampaignCreateFormDateRange.CUSTOM && (
          <>
            <InputLabelWrapper label="">
              <DatePicker
                label={'Start Date'}
                model="startTime"
                maxDate={endDate as unknown as Date}
                onChangeCallBack={(model: string, values: Array<string>) => {
                  setFormValue(model as HookFormValueKey, values[0]);
                }}
                classes={{
                  root: 'campaign-create-form-date-picker',
                }}
                range={false}
                placeholder={translation(
                  `campaigns.modal.placeholders.startTime`
                )}
                initialSelectedValues={[startTime]}
              />
            </InputLabelWrapper>

            <InputLabelWrapper label="">
              <DatePicker
                label={'End Date'}
                model="endDate"
                minDate={startTime as unknown as Date}
                onChangeCallBack={(model: string, values: Array<string>) => {
                  setFormValue(model as HookFormValueKey, values[0]);
                }}
                classes={{
                  root: 'campaign-create-form-date-picker',
                }}
                range={false}
                placeholder={translation(
                  `campaigns.modal.placeholders.endDate`
                )}
                initialSelectedValues={[endDate]}
              />
            </InputLabelWrapper>
          </>
        )}
        <InputLabelWrapper label="Campaign Assignees">
          <Autocomplete
            classes={{
              root: 'multi-select-field-1 ',
              paper: 'multi-select-field-paper-1',
              inputRoot: errors.stakeholders ? 'input-validation-error-1' : '',
            }}
            model="stakeholders"
            optionList={userOptionList}
            areOptionsLoaded
            getIcon={(option) => (
              <Avatar src={option.iconUrl} sx={{ width: 16, height: 16 }} />
            )}
            onChangeCallBack={(
              model: string,
              filterOptions: Array<AutocompleteOption> | AutocompleteOption
            ) => {
              setFormValue(model as HookFormValueKey, filterOptions);
            }}
            values={stakeholders}
            initialSelectedValues={stakeholders}
            enableCheckbox
          />
        </InputLabelWrapper>
        <InputLabelWrapper label="Business Priority">
          <Autocomplete
            classes={{
              root: 'multi-select-field-1 ',
              paper: 'multi-select-field-paper-1',
              inputRoot: errors.priority ? 'input-validation-error-1' : '',
            }}
            model="priority"
            optionList={campaignCreateFormBusinessPriorityOptions}
            single
            onChangeCallBack={(
              model: string,
              option: Array<AutocompleteOption> | AutocompleteOption
            ) => {
              setFormValue(model as HookFormValueKey, option);
            }}
            initialSelectedValues={priority}
            values={priority}
          />
        </InputLabelWrapper>
      </form>
      <div className="campaign-create-modal-form-footer">
        <div></div>
        <div className="campaign-create-modal-form-buttons">
          <Button onClick={onCloseModal} className="action-close">
            Cancel
          </Button>
          <Button
            className="action-save opus-primary-button"
            onClick={() => {
              handleFormSubmit(onFormSubmit)();
            }}
          >
            {editCampaignLoading ? 'Editing' : 'Edit'}
          </Button>
        </div>
      </div>
    </div>
  );
};
