import { FunctionComponent } from 'react';
import { BusinessPriority } from 'shared/components/BusinessPriorityChip/businessPriority';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

interface DashboardBusinessPriorityChipProps extends BaseComponentProps {
  businessPriority: BusinessPriority;
}

export const DashboardBusinessPriorityChip: FunctionComponent<
  DashboardBusinessPriorityChipProps
> = ({ businessPriority }) => {
  return (
    <div
      className={`dashboard-business-priority-chip-container dashboard-business-priority-chip-${businessPriority}-container`}
    >
      {businessPriority}
    </div>
  );
};
