import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Popover,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import {
  useDeleteCampaignByIdMutation,
  useUpdateCampaignByIdMutation,
} from 'Campaigns/store/api';
import CommonDialog from 'Common/components/Dialogs/CommonDialog';
import {
  FunctionComponent,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import {
  CampaignModel,
  CampaignStatus,
  SingleCampaignModel,
} from 'shared/models/data/campaigns.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import CampaignEditForm from '../CampaignEditForm';
import { CommonModalContainer } from 'shared/components/CommonModalContainer';

interface CampaignGridActionsProps extends BaseComponentProps {
  campaignData: SingleCampaignModel;
  enableEdit?: boolean;
  onDeleteSuccess?: () => void;
  onStatusChange?: (status: CampaignStatus) => void;
  onStatusChangeSuccess?: () => void;
}

export const CampaignGridActions: FunctionComponent<
  CampaignGridActionsProps
> = ({
  campaignData,
  enableEdit,
  onStatusChange,
  onStatusChangeSuccess,
  onDeleteSuccess,
}) => {
  const { id, name: title, status } = campaignData;

  const [currentCampaignStatus, setCurrentCampaignStatus] =
    useState<CampaignStatus>(status);

  const { t: translation } = useTranslation();

  const [openDelete, setOpenDelete] = useState<boolean>(false);

  const [openEdit, setOpenEdit] = useState<boolean>(false);

  const [buttonRef, setButtonRef] = useState<HTMLButtonElement | null>(null);

  const [
    deleteCampaign,
    { isLoading: deleteCampaignLoading, isSuccess: deleteCampaignSuccess },
  ] = useDeleteCampaignByIdMutation();

  const [
    updateCampaignStatus,
    {
      isLoading: updateCampaignStatusLoading,
      isSuccess: updateCampaignStatusSuccess,
    },
  ] = useUpdateCampaignByIdMutation();

  function onDeleteHandler(event?: React.MouseEvent<HTMLElement>) {
    deleteCampaign(id);
  }

  function onUpdateStatus(status: CampaignStatus) {
    setCurrentCampaignStatus(status);
    onStatusChange && onStatusChange(status);
    updateCampaignStatus({
      id,
      body: {
        status,
      },
    });
  }

  useEffect(() => {
    if (updateCampaignStatusSuccess) {
      onStatusChangeSuccess && onStatusChangeSuccess();
    }
  }, [updateCampaignStatusSuccess]);

  useEffect(() => {
    if (deleteCampaignSuccess) {
      setOpenDelete(false);
      onDeleteSuccess && onDeleteSuccess();
    }
  }, [deleteCampaignSuccess]);

  const renderActivationItem = () => {
    return (
      <MenuItem
        onClick={() => {
          onUpdateStatus(
            currentCampaignStatus === CampaignStatus.ACTIVE
              ? CampaignStatus.INACTIVE
              : CampaignStatus.ACTIVE
          );
        }}
        disabled={
          !(
            currentCampaignStatus === CampaignStatus.ACTIVE ||
            currentCampaignStatus === CampaignStatus.INACTIVE
          )
        }
      >
        <OpusSvgIcon
          type={
            currentCampaignStatus === CampaignStatus.ACTIVE
              ? SVG_ICON_TYPES.POWER_OFF_ICON
              : SVG_ICON_TYPES.WAVE_PULSE_ICON
          }
        />

        <span>
          {currentCampaignStatus === CampaignStatus.ACTIVE
            ? 'Deactivate'
            : 'Activate'}
        </span>
      </MenuItem>
    );
  };

  const renderCompleteItem = () => {
    return (
      <MenuItem
        onClick={() => {
          onUpdateStatus(CampaignStatus.COMPLETED);
        }}
        disabled={currentCampaignStatus === CampaignStatus.COMPLETED}
      >
        <OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_CHECK_ICON} />
        <span>Complete</span>
      </MenuItem>
    );
  };

  const renderEditItem = () => {
    if (enableEdit)
      return (
        <MenuItem
          onClick={() => {
            setOpenEdit(true);
          }}
        >
          <OpusSvgIcon type={SVG_ICON_TYPES.EDIT_ICON} />
          <span>Edit</span>
        </MenuItem>
      );

    return <></>;
  };

  return (
    <div className="campaign-grid-actions">
      <IconButton
        onClick={(event: MouseEvent<HTMLButtonElement>) => {
          event.stopPropagation();
          setButtonRef(event.currentTarget);
        }}
        className="campaign-grid-actions-button"
      >
        <OpusSvgIcon type={SVG_ICON_TYPES.ELLIPSIS_STROKE_ICON} />
      </IconButton>

      <Menu
        open={Boolean(buttonRef)}
        onClose={() => {
          setButtonRef(null);
        }}
        anchorEl={buttonRef}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        className="campaign-grid-actions-menu"
      >
        {renderEditItem()}
        {renderActivationItem()}
        {renderCompleteItem()}
        <MenuItem
          onClick={() => {
            setOpenDelete(true);
          }}
        >
          <OpusSvgIcon type={SVG_ICON_TYPES.TRASH_ICON_OUTLINED} />
          <span>Delete</span>
        </MenuItem>
      </Menu>

      <CommonDialog
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        title={'Are you sure?'}
        loadingButtonText={translation('common.deleting')}
        closeButtonText={translation('common.cancel')}
        saveButtonText={translation('common.delete')}
        isLoading={deleteCampaignLoading}
        onSave={onDeleteHandler}
        icon={<OpusSvgIcon type={SVG_ICON_TYPES.TRASH_ICON_OUTLINED} />}
      >
        <Box minWidth={'450px'} mb={3}>
          <Typography variant="inherit" className="body-1" color={'#3d3d3d'}>
            You are about to delete the campaign [{title}], are you sure you
            want to proceed?
          </Typography>
        </Box>
      </CommonDialog>

      <CommonModalContainer
        isOpen={openEdit}
        handleClose={() => {
          setOpenEdit(false);
        }}
        title="Edit Campaign"
        components={{
          CancelButton: (
            <Button
              onClick={() => {
                setOpenEdit(false);
              }}
              className="campaign-modal-cancel-button"
            ></Button>
          ),
          SubmitButton: (
            <Button className="campaign-modal-create-button">Create</Button>
          ),
        }}
        rootClassName="campaign-modal-container"
      >
        <CampaignEditForm
          onCloseModal={() => {
            setOpenEdit(false);
          }}
          campaignData={campaignData}
        />{' '}
      </CommonModalContainer>
    </div>
  );
};
