export enum BusinessPriority {
  LOW = 'Low',
  MODERATE = 'Medium',
  HIGH = 'High',
  URGENT = 'Urgent',
}

export const BusinessPriorityColorMapper: { [key: string]: string } = {
  LOW: '#0FAE7F',
  MODERATE: '#C77A32',
  HIGH: '#E02B4B',
  URGENT: '#AB2039',
  LOW_SECONDARY: '#ACECDA',
  MODERATE_SECONDARY: '#FFCFAE',
  HIGH_SECONDARY: '#FFB3C0',
  URGENT_SECONDARY: '#FFB3C0',
};
