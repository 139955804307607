import { Stack } from '@mui/material';
import { FunctionComponent, useEffect, useMemo, useRef } from 'react';
import CampaignFindingsTable from '../CampaignFindingsTable';
import CardWrapper from 'Common/components/CardWrapper';
import useCommonDispatch from 'Common/utils/use-dispatch';
import {
  getIacFindingsTableSelectionProps,
  iacFindingsTableSelectionDefaultProps,
  setIacFindingsTableSelection,
  getFilterList,
  setListFilter,
  getSelectionList,
} from 'Campaigns/store';
import useCommonSelector from 'Common/utils/use-selector';
import AdvanceFilter from 'shared/components/SearchFilter/LegacyAdvanceFilter';
import { useFetchCampaignFindingFiltersMutation } from 'Campaigns/store/api';
import { useParams } from 'react-router-dom';
import FilterActions from 'Risk/components/FilterActions';
import { AgGridEvent } from 'ag-grid-community';
import { GridSelectionType } from 'Risk/store';

interface ActionControlPayload {
  itemCount: number;
  onCancel: () => void;
  payload: any;
}

export const CampaignFindingsAdvancedDataGrid: FunctionComponent = () => {
  const dispatch = useCommonDispatch();
  const gridRef = useRef<AgGridEvent | null>(null);

  const advanceFilterSelections = useCommonSelector(getSelectionList);

  const iacFindingsTableSelectionProps = useCommonSelector(
    getIacFindingsTableSelectionProps
  );
  const { id: campaignId } = useParams();

  useEffect(() => {
    cancelIacFindingsSelection();
  }, [advanceFilterSelections]);

  const cancelIacFindingsSelection = () => {
    dispatch(
      setIacFindingsTableSelection({
        ...iacFindingsTableSelectionDefaultProps,
        availableChildren: iacFindingsTableSelectionProps.availableChildren,
        rows: iacFindingsTableSelectionProps.rows?.map((rowItem) => ({
          ...rowItem,
          selection: GridSelectionType.UNSELECTED,
          totalSelectedChildren: 0,
          unselectedChildren: rowItem.availableChildren,
        })),
      })
    );
  };
  const actionControlPayload = useMemo<ActionControlPayload>(() => {
    return {
      itemCount: iacFindingsTableSelectionProps.totalSelectedChildren,
      onCancel: cancelIacFindingsSelection,
      payload: {
        gridType: 'none',
        ...iacFindingsTableSelectionProps,
      },
    };
  }, [iacFindingsTableSelectionProps]);

  return (
    <>
      <div className="campaign-findings-main-container">
        <Stack className="campaign-findings-main-header" direction="row">
          <AdvanceFilter
            setListFilter={setListFilter}
            getFilterList={getFilterList}
            searchEnabled={false}
            onSearch={() => {}}
            mutationProps={{
              fetchMutation: useFetchCampaignFindingFiltersMutation,
              params: { campaignId: campaignId },
            }}
          />
        </Stack>
        <Stack direction="row" className="campaign-findings-main-header">
          {actionControlPayload.itemCount ? (
            <div className="actions-container">
              <FilterActions
                onCancel={() => {
                  if (gridRef?.current) {
                    gridRef?.current?.api?.deselectAll();
                  }
                  actionControlPayload.onCancel();
                }}
                getSelectionList={getSelectionList}
                itemCount={actionControlPayload.itemCount}
                selectionProps={actionControlPayload.payload}
                disableCampaignCreation
              />
            </div>
          ) : (
            <></>
          )}
        </Stack>
        <div className="campaign-findings-main-table">
          <CampaignFindingsTable gridRef={gridRef} />
        </div>
      </div>
    </>
  );
};
