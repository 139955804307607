import useCommonSelector from 'Common/utils/use-selector';
import {
  getFilterList,
  getSelectedGroup,
  getSelectedScope,
  getSelectedServices,
} from 'Dashboard/store';
import { useGetUserAvailableNodesOfTypeMutation } from 'Dashboard/store/api';
import { FunctionComponent, useEffect, useMemo } from 'react';
import { BusinessUnitMetadata } from 'shared/models/data/business-unit.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { BusinessUnitCarouselList } from '../BusinessUnitCarouselList';
import { DashboardFilterType } from 'Dashboard/interfaces/DashboardFilterType.enum';
import { OrganizationNodeType } from 'Organization/interfaces/OrganizationNodeType.enum';
import { OrganizationNode } from 'Organization/interfaces/OrganizationNode.interface';
import { OrganizationalDataProps } from 'Dashboard/DashboardPage';

interface BusinessUnitDashboardSectionProp
  extends BaseComponentProps,
    OrganizationalDataProps {
  businessUnitListWithMetadata?: any;
  businessUnitLoading: boolean;
}

export enum UniqueBusinessUnitId {
  ALL = 'all',
  NO_BUSINESS_UNIT = '00000000-0000-0000-0000-000000000000',
}

export const BusinessUnitDashboardSection: FunctionComponent<
  BusinessUnitDashboardSectionProp
> = ({
  businessUnitLoading,
  businessUnitListWithMetadata,
  scopeData,
  groupData,
}) => {
  const filters = useCommonSelector(getFilterList);

  const [
    getUserAvailableNodesOfType,
    { data: nodeData, isLoading: nodeDataLoading },
  ] = useGetUserAvailableNodesOfTypeMutation();

  const selectedGroupIds = useCommonSelector(getSelectedGroup);
  const selectedScopeIds = useCommonSelector(getSelectedScope);
  const selectedServiceIds = useCommonSelector(getSelectedServices);

  const isAllGroupSelected = selectedGroupIds[0] === DashboardFilterType.ALL;
  const isAllScopeSelected = selectedScopeIds[0] === DashboardFilterType.ALL;

  useEffect(() => {
    if (!isAllGroupSelected) {
      getUserAvailableNodesOfType({
        nodesType: OrganizationNodeType.DATA,
        startNodeId: selectedGroupIds[0],
      });
    } else if (!isAllScopeSelected) {
      getUserAvailableNodesOfType({
        nodesType: OrganizationNodeType.DATA,
        startNodeId: selectedScopeIds[0],
      });
    } else {
      getUserAvailableNodesOfType({
        nodesType: OrganizationNodeType.DATA,
      });
    }
  }, [selectedGroupIds, selectedScopeIds]);

  const filteredBusinessUnits = useMemo(() => {
    let allBusinessIds: string[] | undefined = [];

    const allItem = businessUnitListWithMetadata?.find(
      (bu: BusinessUnitMetadata) => bu.id === UniqueBusinessUnitId.ALL,
    );

    if (nodeData && businessUnitListWithMetadata) {
      allBusinessIds = nodeData.map((nodeDataItem: any) => nodeDataItem.id);
      const allFilter = filters.every((filter) => {
        const allOption = filter.items.find(
          (filterItem) => filterItem.id === DashboardFilterType.ALL,
        );
        return allOption?.checked;
      });
      if (allFilter) {
        const serviceWithoutFindings = businessUnitListWithMetadata?.find(
          (bu: BusinessUnitMetadata) =>
            bu.id === UniqueBusinessUnitId.NO_BUSINESS_UNIT,
        ) as BusinessUnitMetadata;

        if (serviceWithoutFindings?.openIssues > 0)
          allBusinessIds?.push(UniqueBusinessUnitId.NO_BUSINESS_UNIT);
      }

      const filteredBusinessUnits = businessUnitListWithMetadata?.filter(
        (bu: BusinessUnitMetadata) =>
          allBusinessIds?.includes(bu.id) &&
          (selectedServiceIds[0] === 'All' ||
            selectedServiceIds.includes(bu.id)),
      ) as BusinessUnitMetadata[];

      const allIssuesCount = filteredBusinessUnits?.reduce(
        (allIssuesSum, businessUnit) => {
          return allIssuesSum + businessUnit.openIssues;
        },
        0,
      );

      return [
        {
          ...allItem,
          openIssues: allIssuesCount,
          childrenCount: filteredBusinessUnits?.length || 0,
        },
        ...(filteredBusinessUnits || []),
      ];
    }

    return [];
  }, [nodeData, businessUnitListWithMetadata, selectedServiceIds]);

  return (
    <div className="business-unit-dashboard-section-container">
      <div className="business-unit-dashboard-section-body">
        <BusinessUnitCarouselList
          isLoading={businessUnitLoading || nodeDataLoading}
          businessUnits={filteredBusinessUnits}
          scopeData={scopeData}
          groupData={groupData}
        />
      </div>
    </div>
  );
};
