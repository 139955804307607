import { createApi } from '@reduxjs/toolkit/query/react';
import { BurndownWidgetData } from 'Campaigns/interfaces/BurndownWidget.interface';
import { ProjectInfoData } from 'Campaigns/interfaces/ProjectInfoWidget.interface';
import { WorkBreakdownWidgetData } from 'Campaigns/interfaces/work-breakdown-widget.interface';
import axiosFetchBaseQuery from 'Common/utils/axios-base-query';
import commonConfig from 'Config';
import { ActiveCampaignMetrics } from 'shared/models/data/active-campaign.model';
import {
  CampaignCreateModel,
  CampaignModel,
  SingleCampaignModel,
} from 'shared/models/data/campaigns.model';

enum CampaignApiTag {
  UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN',
}

const campaignsApi = createApi({
  reducerPath: 'campaignsApi',
  baseQuery: axiosFetchBaseQuery({
    baseUrl: commonConfig.bffBaseUrl,
  }),
  tagTypes: [CampaignApiTag.UPDATE_CAMPAIGN],
  endpoints: (builder) => ({
    fetchCampaignsList: builder.mutation({
      query: (data) => ({
        url: 'campaigns/search',
        method: 'POST',
        body: data,
      }),
      transformResponse: async (response) => {
        return response as { data: ProjectInfoData[]; totalItems: number };
      },
    }),
    searchCampaignList: builder.mutation<
      {
        data: Array<ProjectInfoData>;
      },
      {
        filter: {
          businessUnitsIds: Array<string>;
          findingType?: Array<string>;
        };
      }
    >({
      query: (params) => ({
        url: 'campaigns/search',
        method: 'POST',
        body: params,
      }),
    }),
    updateCampaign: builder.mutation({
      query: ({ id, data }) => ({
        url: `campaigns/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: [CampaignApiTag.UPDATE_CAMPAIGN],
    }),
    fetchFindingsByCampaign: builder.mutation({
      query: (data) => ({
        url: 'campaigns/findings/search',
        method: 'POST',
        body: data,
      }),
    }),
    fetchProjectInfoWidget: builder.query({
      query: (campaignId: string) => ({
        url: `campaigns/${campaignId}`,
        method: 'GET',
      }),
      transformResponse: async (response) => {
        return response as ProjectInfoData;
      },
      providesTags: [CampaignApiTag.UPDATE_CAMPAIGN],
    }),

    createPullRequest: builder.mutation({
      query: (findingId) => ({
        url: `findings/actions/${findingId}/github`,
        method: 'POST',
        body: {},
      }),
    }),
    fetchBurndownWidget: builder.query({
      query: (campaignId: string) => ({
        url: `campaigns/${campaignId}/widgets/burndown`,
        method: 'GET',
      }),
      transformResponse: async (response) => {
        return response as BurndownWidgetData;
      },
    }),
    fetchWorkBreakdownWidget: builder.query({
      query: (campaignId: string) => ({
        url: `campaigns/${campaignId}/widgets/metrics`,
        method: 'GET',
      }),
      transformResponse: async (response) => {
        return response as WorkBreakdownWidgetData;
      },
    }),
    fetchSlaBreakdownWidget: builder.query({
      query: (campaignId: string) => ({
        url: `campaigns/${campaignId}/widgets/sla-breakdown`,
        method: 'GET',
      }),
    }),
    fetchTicketsPerPersonWidget: builder.query({
      query: (campaignId: string) => ({
        url: `campaigns/${campaignId}/widgets/tickets-per-person`,
        method: 'GET',
      }),
    }),
    fetchFindingsByTicketStatus: builder.query({
      query: (campaignId: string) => ({
        url: `campaigns/${campaignId}/widgets/findings-by-ticket-status`,
        method: 'GET',
      }),
    }),
    fetchFindingsByStatus: builder.query({
      query: (campaignId: string) => ({
        url: `campaigns/${campaignId}/widgets/findings-by-status`,
        method: 'GET',
      }),
    }),
    fetchMetricsWidget: builder.query({
      query: (campaignId: string) => ({
        url: `campaigns/${campaignId}/widgets/metrics`,
        method: 'GET',
      }),
    }),
    getCampaignMetrics: builder.query<ActiveCampaignMetrics, string>({
      query: (campaignId: string) => ({
        url: `campaigns/${campaignId}/metrics`,
        method: 'GET',
      }),
      transformResponse: async (response) => {
        return response as ActiveCampaignMetrics;
      },
    }),
    fetchCampaignFindingFilters: builder.mutation({
      query: (body) => ({
        url: `campaigns/findings/${body.campaignId}/filters/${body.field}`,
        method: 'POST',
      }),
    }),
    fetchCampaignsFilters: builder.mutation({
      query: (body) => ({
        url: `campaigns/filters/${body.field}`,
        method: 'POST',
      }),
    }),
    bumpCampaign: builder.mutation({
      query: (days: number) => ({
        url: `campaigns/bump/${days}`,
        method: 'POST',
      }),
    }),
    getCampaignList: builder.mutation<Array<CampaignModel>, unknown>({
      query: () => ({
        url: `v2/campaigns`,
        method: 'GET',
      }),
    }),
    createCampaign: builder.mutation<CampaignModel, CampaignCreateModel>({
      query: (body: CampaignCreateModel) => ({
        url: `v2/campaigns`,
        method: 'POST',
        body,
      }),
    }),
    fetchCampaign: builder.query<SingleCampaignModel, string>({
      query: (id: string) => ({
        url: `v2/campaigns/${id}`,
        method: 'GET',
      }),
      providesTags: [CampaignApiTag.UPDATE_CAMPAIGN],
    }),
    fetchCampaignMetrics: builder.mutation({
      query: ({ id, scopeId, groupId }) => {
        const params = new URLSearchParams();

        if (scopeId) {
          params.append('scopeId', scopeId);
        }

        if (groupId) {
          params.append('groupId', groupId);
        }

        const queryString = params.toString();
        const url = `v2/campaigns/${id}/metrics${
          queryString ? `?${queryString}` : ''
        }`;

        return {
          url,
          method: 'GET',
        };
      },
    }),
    updateCampaignById: builder.mutation({
      query: ({ id, body }) => ({
        url: `v2/campaigns/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [CampaignApiTag.UPDATE_CAMPAIGN],
    }),
    deleteCampaignById: builder.mutation({
      query: (id: string) => ({
        url: `v2/campaigns/${id}`,
        method: 'DELETE',
      }),
    }),
    fetchActiveFindingsCountByProperty: builder.mutation({
      query: ({ id, property, scopeId, groupId }) => {
        const params = new URLSearchParams();

        if (scopeId) {
          params.append('scopeId', scopeId);
        }

        if (groupId) {
          params.append('groupId', groupId);
        }

        const queryString = params.toString();
        const url = `v2/campaigns/${id}/activeFindingsCount/${property}${
          queryString ? `?${queryString}` : ''
        }`;

        return {
          url,
          method: 'GET',
        };
      },
    }),
  }),
});

export default campaignsApi;

export const {
  useFetchFindingsByCampaignMutation,
  useFetchProjectInfoWidgetQuery,
  useCreatePullRequestMutation,
  useFetchCampaignFindingFiltersMutation,
  useFetchBurndownWidgetQuery,
  useFetchSlaBreakdownWidgetQuery,
  useFetchTicketsPerPersonWidgetQuery,
  useFetchMetricsWidgetQuery,
  useFetchFindingsByTicketStatusQuery,
  useFetchWorkBreakdownWidgetQuery,
  useFetchFindingsByStatusQuery,
  useLazyFetchProjectInfoWidgetQuery,
  useLazyFetchBurndownWidgetQuery,
  useLazyFetchWorkBreakdownWidgetQuery,
  useLazyFetchMetricsWidgetQuery,
  useLazyFetchFindingsByStatusQuery,
  useLazyFetchFindingsByTicketStatusQuery,
  useLazyFetchTicketsPerPersonWidgetQuery,
  useSearchCampaignListMutation,
  useGetCampaignMetricsQuery,
  useBumpCampaignMutation,
  useFetchCampaignsFiltersMutation,
  useFetchCampaignsListMutation,
  useUpdateCampaignMutation,
  useGetCampaignListMutation,
  useCreateCampaignMutation,
  useFetchCampaignQuery,
  useFetchCampaignMetricsMutation,
  useUpdateCampaignByIdMutation,
  useDeleteCampaignByIdMutation,
  useLazyFetchCampaignQuery,
  useFetchActiveFindingsCountByPropertyMutation,
} = campaignsApi;
