import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import { ProjectInfoData } from 'Campaigns/interfaces/ProjectInfoWidget.interface';
import {
  getFilterList,
  getSearchParams,
  getSelectionList,
} from 'Campaigns/store';
import { useFetchCampaignsListMutation } from 'Campaigns/store/api';
import useCommonSelector from 'Common/utils/use-selector';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FormattedMessage from 'shared/components/FormattedMessage';
import { AdvanceFilterHandler } from 'shared/handlers/advance-filter-data.handler';
import { FilterSearchParamsHandler } from 'shared/handlers/filter-search-params.handler';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import CampaignDetailsHeader from '../../../CampaignDetails/components/CampaignDetailsHeader';
import CommonPagination from 'Common/components/Pagination';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';

const advanceFilterDataHandler = new AdvanceFilterHandler();
export const CampaignsListView: FunctionComponent = () => {
  let navigate = useNavigate();
  let [urlSearchParams, setUrlSearchParams] = useQueryParams();
  const advanceFilterSelections = useCommonSelector(getSelectionList);
  const advanceFilterList = useCommonSelector(getFilterList);

  const campaignSearchParams = useCommonSelector(getSearchParams);

  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [searchCampaigns, { data: campaignsList, isLoading: isLoading }] =
    useFetchCampaignsListMutation();

  const onPageChange = (selectedPage: number) => {
    setCurrentPage(selectedPage);
  };

  const onPageSizeChange = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
  };

  const redirectToCampaignDetails = (campaign: ProjectInfoData) => {
    // if (campaign.status === CampaignStatus.NEW) return;
    navigate(`/campaigns/${campaign.id}`);
  };
  const additionalSearchParams = useMemo(() => {
    const updatedSearchParams =
      advanceFilterDataHandler.translateAdvanceFilterComponentValuesToSearchParams(
        advanceFilterSelections,
      );

    return updatedSearchParams;
  }, [advanceFilterSelections]);

  useEffect(() => {
    searchCampaigns({
      filter: { ...additionalSearchParams, ...campaignSearchParams.filter },
      take: pageSize,
      skip: currentPage && pageSize ? (currentPage - 1) * pageSize : 0,
    });
  }, [additionalSearchParams, campaignSearchParams, pageSize, currentPage]);

  if (isLoading)
    return (
      <Box textAlign="center" py={4}>
        <CircularProgress size={30} />
      </Box>
    );
  return (
    <div className="campaigns-list-page-container">
      {campaignsList?.data?.map((campaign: ProjectInfoData, index: number) => (
        <div
          key={index}
          className="campaigns-item"
          onClick={() => redirectToCampaignDetails(campaign)}
        >
          <CampaignDetailsHeader campaignData={campaign} />
        </div>
      ))}
      {!campaignsList?.data?.length && (
        <Box className="title" display={'flex'} justifyContent="center">
          <FormattedMessage
            id="common.noDataFound"
            defaultMessage="No data found"
            capitalize
          />
        </Box>
      )}

      <CommonPagination
        totalItems={campaignsList?.totalItems || 0}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        classes={{
          root: 'advanced-data-grid-pagination-root',
          pages: 'advanced-data-grid-pagination-pages',
          results: 'advanced-data-grid-pagination-results',
          rowsPerPageSelect:
            'advanced-data-grid-pagination-rows-per-page-select',
          rowsPerPageSelectItem:
            'advanced-data-grid-pagination-rows-per-page-select-item',
          rowsPerPageText: 'advanced-data-grid-pagination-rows-per-page-text',
        }}
        icons={{
          ExpandIcon: <OpusSvgIcon type={SVG_ICON_TYPES.EXPAND_MORE_ICON} />,
        }}
      />
    </div>
  );
};
