import { Box, Typography } from '@mui/material';
import { useSearchCampaignListMutation } from 'Campaigns/store/api';
import useCommonSelector from 'Common/utils/use-selector';
import { DashboardFilterType } from 'Dashboard/interfaces/Dashboard';
import { getInitialFilterLoad, selectdashboardFilter } from 'Dashboard/store';
import { FunctionComponent, useEffect } from 'react';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import ActiveCampaignCarouselList from '../ActiveCampaignCarouselList';

interface ActiveCampaignDashboardSectionProps extends BaseComponentProps {}

export const ActiveCampaignDashboardSection: FunctionComponent<
  ActiveCampaignDashboardSectionProps
> = () => {
  const dashboardFilter: DashboardFilterType = useCommonSelector(
    selectdashboardFilter
  );

  const dashboardFilterInitialLoad: boolean =
    useCommonSelector(getInitialFilterLoad);

  const [
    getActiveCampaigns,
    { data: activeCampaigns, isLoading: activeCampaignsLoading },
  ] = useSearchCampaignListMutation();

  useEffect(() => {
    if (dashboardFilterInitialLoad)
      getActiveCampaigns({
        filter: {
          businessUnitsIds: dashboardFilter.businessUnitId as Array<string>,
        },
      });
  }, [dashboardFilterInitialLoad, dashboardFilter.businessUnitId]);

  return (
    <div className="active-campaign-dashboard-section-container">
      <div className="active-campaign-dashboard-section-header">
        <Box display={'flex'} gap={1}>
          <OpusSvgIcon type={SVG_ICON_TYPES.BULLHORN_ICON} />
          <Typography className="header-6" variant="inherit">
            Active Campaigns
            {activeCampaigns?.data?.length
              ? ` (${activeCampaigns?.data?.length})`
              : ''}
          </Typography>
        </Box>
      </div>
      <div className="active-campaign-dashboard-section-body">
        <ActiveCampaignCarouselList
          isLoading={activeCampaignsLoading}
          activeCampaigns={activeCampaigns?.data || []}
        />
      </div>
    </div>
  );
};
