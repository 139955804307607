import { GridSelectionProps } from 'Risk/store';
import { CampaignCreateFormBusinessPriority } from 'shared/enums/campaigns.enum';
import * as yup from 'yup';

export interface CampaignCreateFormValues {
  name: string;
  startTime: Array<string> | string;
  endDate: Array<string> | string;
  assignees: Array<string>;
  businessPriority: CampaignCreateFormBusinessPriority | string;
  dateRange: string;
}

export type CampaignCreateFormErrors = Record<
  keyof CampaignCreateFormValues,
  string | null
>;

export enum CampaignType {
  LIVE = 'live',
  STATIC = 'static',
}

export enum CampaignStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  COMPLETED = 'Completed',
}

export enum CampaignCriticality {
  LBI = 'LBI',
  MBI = 'MBI',
  HBI = 'HBI',
  CBI = 'CBI',
}

export interface CampaignOwner {
  id: string;
  name: string;
  email: string;
  profilePictureUrl: string;
}

export interface CampaignModel {
  id: string;
  name: string;
  remainingDays: number;
  ownerUser: CampaignOwner;
  priority: CampaignCriticality;
  status: CampaignStatus;
  type: CampaignType;
  appliedFilter: any;
  activeFindings: number;
  totalFindings: number;
  completionRate: number;
}

export interface SingleCampaignModel extends CampaignModel {
  startTime: Date;
  endDate: Date;
  stakeholders: { [key: string]: any }[];
}
export interface CampaignCreateModel {
  name: string;
  duration: string;
  startTime: Date;
  endDate: Date;
  ownerUserId: string;
  stakeholders: string[];
  priority: CampaignCriticality;
  type: CampaignType;
  status?: CampaignStatus;
  appliedFilter?: any;
  gridSelectionProps?: GridSelectionProps;
  findingIds?: Array<string>;
}

export const campaignFormSchema = yup.object().shape({
  name: yup.string().required(),
  duration: yup.object().required(),
  startTime: yup.string(),
  endDate: yup.string(),
  ownerUserId: yup.object().required(),
  stakeholders: yup.array().min(1),
  priority: yup.object().required(),
  type: yup.object().required(),
});

export const campaignsMockData: Array<CampaignModel> = [
  {
    id: '1',
    name: 'test campaign',
    remainingDays: 12,
    ownerUser: {
      id: 'user',
      email: 'ardit@opus.security',
      name: 'Ardit Mirashi',
      profilePictureUrl:
        'https://www.gravatar.com/avatar/3dd570c6245c7552256b22acf553baea?d=https://ui-avatars.com/api/Ardit/128/random',
    },
    activeFindings: 2,
    totalFindings: 12,
    appliedFilter: {},
    completionRate: 12,
    priority: CampaignCriticality.HBI,
    status: CampaignStatus.INACTIVE,
    type: CampaignType.LIVE,
  },
  {
    id: '2',
    name: 'test campaign',
    remainingDays: 12,
    ownerUser: {
      id: 'user1',
      email: 'or@opus.security',
      name: 'Or Mirashi',
      profilePictureUrl:
        'https://www.gravatar.com/avatar/3dd570c6245c7552256b22acf553baea?d=https://ui-avatars.com/api/Ardit/128/random',
    },
    activeFindings: 2,
    totalFindings: 12,
    appliedFilter: {},
    completionRate: 12,
    priority: CampaignCriticality.HBI,
    status: CampaignStatus.ACTIVE,
    type: CampaignType.LIVE,
  },
  {
    id: '3',
    name: 'test campaign',
    remainingDays: 12,
    ownerUser: {
      id: 'user2',
      email: 'rigerta@opus.security',
      name: 'Rigerta Xhafa',
      profilePictureUrl:
        'https://www.gravatar.com/avatar/3dd570c6245c7552256b22acf553baea?d=https://ui-avatars.com/api/Ardit/128/random',
    },
    activeFindings: 2,
    totalFindings: 12,
    appliedFilter: {},
    completionRate: 12,
    priority: CampaignCriticality.HBI,
    status: CampaignStatus.ACTIVE,
    type: CampaignType.LIVE,
  },

  {
    id: '4',
    name: 'test campaign',
    remainingDays: 12,
    ownerUser: {
      id: 'user3',
      email: 'roni@opus.security',
      name: 'Roni Fheudental',
      profilePictureUrl:
        'https://www.gravatar.com/avatar/3dd570c6245c7552256b22acf553baea?d=https://ui-avatars.com/api/Ardit/128/random',
    },
    activeFindings: 2,
    totalFindings: 12,
    appliedFilter: {},
    completionRate: 12,
    priority: CampaignCriticality.HBI,
    status: CampaignStatus.ACTIVE,
    type: CampaignType.LIVE,
  },
  {
    id: '5',
    name: 'test campaign',
    remainingDays: 12,
    ownerUser: {
      id: 'user3',
      email: 'roni@opus.security',
      name: 'Roni Fheudental',
      profilePictureUrl:
        'https://www.gravatar.com/avatar/3dd570c6245c7552256b22acf553baea?d=https://ui-avatars.com/api/Ardit/128/random',
    },
    activeFindings: 2,
    totalFindings: 12,
    appliedFilter: {},
    completionRate: 12,
    priority: CampaignCriticality.HBI,
    status: CampaignStatus.ACTIVE,
    type: CampaignType.LIVE,
  },
  {
    id: '6',
    name: 'test campaign',
    remainingDays: 12,
    ownerUser: {
      id: 'user3',
      email: 'roni@opus.security',
      name: 'Roni Fheudental',
      profilePictureUrl:
        'https://www.gravatar.com/avatar/3dd570c6245c7552256b22acf553baea?d=https://ui-avatars.com/api/Ardit/128/random',
    },
    activeFindings: 2,
    totalFindings: 12,
    appliedFilter: {},
    completionRate: 12,
    priority: CampaignCriticality.HBI,
    status: CampaignStatus.ACTIVE,
    type: CampaignType.LIVE,
  },
  {
    id: '7',
    name: 'test campaign',
    remainingDays: 12,
    ownerUser: {
      id: 'user3',
      email: 'roni@opus.security',
      name: 'Roni Fheudental',
      profilePictureUrl:
        'https://www.gravatar.com/avatar/3dd570c6245c7552256b22acf553baea?d=https://ui-avatars.com/api/Ardit/128/random',
    },
    activeFindings: 2,
    totalFindings: 12,
    appliedFilter: {},
    completionRate: 12,
    priority: CampaignCriticality.HBI,
    status: CampaignStatus.ACTIVE,
    type: CampaignType.LIVE,
  },
  {
    id: '8',
    name: 'test campaign',
    remainingDays: 12,
    ownerUser: {
      id: 'user3',
      email: 'roni@opus.security',
      name: 'Roni Fheudental',
      profilePictureUrl:
        'https://www.gravatar.com/avatar/3dd570c6245c7552256b22acf553baea?d=https://ui-avatars.com/api/Ardit/128/random',
    },
    activeFindings: 2,
    totalFindings: 12,
    appliedFilter: {},
    completionRate: 12,
    priority: CampaignCriticality.HBI,
    status: CampaignStatus.ACTIVE,
    type: CampaignType.LIVE,
  },
  {
    id: '9',
    name: 'test campaign',
    remainingDays: 12,
    ownerUser: {
      id: 'user3',
      email: 'roni@opus.security',
      name: 'Roni Fheudental',
      profilePictureUrl:
        'https://www.gravatar.com/avatar/3dd570c6245c7552256b22acf553baea?d=https://ui-avatars.com/api/Ardit/128/random',
    },
    activeFindings: 2,
    totalFindings: 12,
    appliedFilter: {},
    completionRate: 12,
    priority: CampaignCriticality.HBI,
    status: CampaignStatus.ACTIVE,
    type: CampaignType.LIVE,
  },
  {
    id: '10',
    name: 'test campaign',
    remainingDays: 12,
    ownerUser: {
      id: 'user3',
      email: 'roni@opus.security',
      name: 'Roni Fheudental',
      profilePictureUrl:
        'https://www.gravatar.com/avatar/3dd570c6245c7552256b22acf553baea?d=https://ui-avatars.com/api/Ardit/128/random',
    },
    activeFindings: 2,
    totalFindings: 12,
    appliedFilter: {},
    completionRate: 12,
    priority: CampaignCriticality.MBI,
    status: CampaignStatus.COMPLETED,
    type: CampaignType.STATIC,
  },
  {
    id: '11',
    name: 'test campaign',
    remainingDays: 12,
    ownerUser: {
      id: 'user3',
      email: 'roni@opus.security',
      name: 'Roni Fheudental',
      profilePictureUrl:
        'https://www.gravatar.com/avatar/3dd570c6245c7552256b22acf553baea?d=https://ui-avatars.com/api/Ardit/128/random',
    },
    activeFindings: 2,
    totalFindings: 12,
    appliedFilter: {},
    completionRate: 12,
    priority: CampaignCriticality.LBI,
    status: CampaignStatus.ACTIVE,
    type: CampaignType.LIVE,
  },
];
