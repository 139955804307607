import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { OptionListType } from '../OptionList/OptionList';
import {
  ApiStaticOptionSourceProperties,
  FilterOption,
} from 'shared/models/data/data-filter.model';
import { useFetchDataBasedOnParametersMutation } from 'shared/store/api';
import BaseOptionList from '../BaseOptionList';
import { DataFitlerHandler } from 'shared/handlers/data-filter.handler';

const dataFilterHandler = new DataFitlerHandler();

interface ApiStaticOptionListProps extends BaseComponentProps {
  title: string;
  source: ApiStaticOptionSourceProperties;
  mode: OptionListType;
  onOptionsSelect?: (
    options: Array<FilterOption>,
    isAllSelected?: boolean
  ) => void;
  open?: boolean;
  onAllSelect?: (options: Array<FilterOption>) => void;
  selectedOptions?: Array<FilterOption>;
  extraOptions?: Array<FilterOption>;
  actionHandlers?: {
    goBackHandler?: () => void;
  };
  allSelected?: boolean;
  sortMethod?: (optionData: Array<FilterOption>) => Array<FilterOption>;
}

export const ApiStaticOptionList: FunctionComponent<
  ApiStaticOptionListProps
> = ({
  title,
  source,
  mode,
  onOptionsSelect,
  open,
  onAllSelect,
  selectedOptions,
  extraOptions,
  actionHandlers,
  allSelected,
  sortMethod,
}) => {
  const { url, method, body, optionsTransformer } = source;

  const [searchValue, setSearchValue] = useState<string>('');

  const [fetchOptions, { data: optionsData, isLoading: optionsDataLoading }] =
    useFetchDataBasedOnParametersMutation();

  const getAllOptionData = (): Array<FilterOption> => {
    if (optionsData) {
      const transformedOptions = optionsTransformer
        ? optionsTransformer(optionsData)
        : optionsData;

      const allOptions = [...(extraOptions || []), ...transformedOptions];
      const sortedOptions = sortMethod ? sortMethod(allOptions) : allOptions;
      return sortedOptions;
    }

    return [];
  };

  const getFilteredOptionData = (): Array<FilterOption> => {
    if (optionsData) {
      const allOptions = getAllOptionData();

      return dataFilterHandler.searchFilterOptions(allOptions, searchValue);
    }

    return [];
  };

  const options = getAllOptionData();

  const filteredOptions = getFilteredOptionData();

  useEffect(() => {
    if (url && open) {
      let searchBody = {
        ...(body || {}),
      };

      fetchOptions({
        url,
        method: method || 'POST',
        data: searchBody,
      });
    }
  }, [url, open, method]);

  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value;
    setSearchValue(searchValue);
  };

  const handleOptionsSelect = (
    selectedOptions: Array<FilterOption>,
    allSelected?: boolean
  ) => {
    onOptionsSelect &&
      onOptionsSelect(
        selectedOptions,
        selectedOptions.length === options.length
      );
  };

  const handleAllSelect = (allOptions: Array<FilterOption>) => {
    if (filteredOptions.length < options.length) {
      const everyOptionSelected =
        selectedOptions?.length &&
        selectedOptions.every((selectedOption) =>
          filteredOptions.find(
            (filteredOption) => filteredOption.value === selectedOption.value
          )
        );

      onOptionsSelect &&
        onOptionsSelect(everyOptionSelected ? [] : filteredOptions, false);
    } else {
      onAllSelect && onAllSelect(allOptions);
    }
  };

  return (
    <BaseOptionList
      title={title}
      options={filteredOptions}
      optionsLoading={optionsDataLoading}
      mode={mode}
      onOptionsSelect={handleOptionsSelect}
      selectedOptions={selectedOptions}
      actionHandlers={actionHandlers}
      onAllSelect={handleAllSelect}
      allSelected={filteredOptions.length === selectedOptions?.length}
      onSearch={onSearch}
      enableAllSelection
    />
  );
};
