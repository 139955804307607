import { CircularProgress } from '@mui/material';
import { FunctionComponent, useEffect, useState, ReactNode } from 'react';
import FormattedMessage from 'shared/components/FormattedMessage';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { WorkBreakdownWidgetData } from 'Campaigns/interfaces/work-breakdown-widget.interface';
import { useLazyFetchMetricsWidgetQuery } from 'Campaigns/store/api';
import useCommonSelector from 'Common/utils/use-selector';
import { ProjectInfoData } from 'Campaigns/interfaces/ProjectInfoWidget.interface';
import { getActiveCampaign } from 'Campaigns/store';
import { CampaignWorkBreakdownWidgetItem } from 'CampaignDetails/components/CampaignWorkBreakdownWidget/CampainWorkBreakdownWidgetItem';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import { ReactComponent as Ticket } from 'shared/icons/ticket.svg';

interface CampaignWorkBreakdownWidgetProps extends BaseComponentProps {
  campaignId?: string;
}

interface WidgetBodyItem {
  getIcon: ReactNode;
  getTitle: (widgetData?: WorkBreakdownWidgetData) => string;
  getDescription: (translation: any) => string;
}

const widgetBodyItems: Array<WidgetBodyItem> = [
  {
    getIcon: <Ticket className="icon-widget-breakdown" />,
    getTitle: (widgetData) =>
      `${widgetData?.ticketsCompleted || 0}/${widgetData?.totalTickets || 0}`,
    getDescription: (translation) =>
      translation('campaigns.metrics.ticketCompleted'),
  },
  {
    getIcon: <DoneOutlinedIcon className="icon-widget-breakdown" />,
    getTitle: (widgetData) => `${widgetData?.ticketsCompletedLastWeek || 0}`,
    getDescription: (translation) =>
      translation('campaigns.metrics.completedPastWeek'),
  },
  {
    getIcon: <AutoAwesomeOutlinedIcon className="icon-widget-breakdown" />,
    getTitle: (widgetData) => `${widgetData?.newTickets || 0}`,
    getDescription: (translation) =>
      translation('campaigns.metrics.newFindings'),
  },
  {
    getIcon: <TodayOutlinedIcon className="icon-widget-breakdown" />,
    getTitle: (widgetData) => `${widgetData?.remainingDays || 0}`,
    getDescription: (translation) =>
      translation('campaigns.metrics.remainingDays'),
  },
];

export const CampaignWorkBreakdownWidget: FunctionComponent<
  CampaignWorkBreakdownWidgetProps
> = ({ campaignId }) => {
  const activeCampaign = useCommonSelector<ProjectInfoData | undefined>(
    getActiveCampaign
  );

  const [workBreakData, setWorkBreakData] = useState<
    WorkBreakdownWidgetData | undefined
  >();

  const [
    fetchMetricsWidgetData,
    { data: workBreakDataResponse, isFetching, error: metricsDataFetchError },
  ] = useLazyFetchMetricsWidgetQuery();

  useEffect(() => {
    if (workBreakDataResponse) {
      setWorkBreakData(workBreakDataResponse);
    }
  }, [workBreakDataResponse]);

  useEffect(() => {
    if (activeCampaign) {
      fetchMetricsWidgetData(activeCampaign.id);
    }

    if (campaignId?.length) {
      fetchMetricsWidgetData(campaignId);
    }
  }, [activeCampaign, campaignId]);

  if (metricsDataFetchError)
    return (
      <div>
        {' '}
        <FormattedMessage
          id="common.anErrorOccured"
          defaultMessage="An error occured"
        />
      </div>
    );

  if (isFetching || !workBreakData) return <CircularProgress size={14} />;

  return (
    <div className="campaign-work-breakdown-widget-container">
      <div className="campaign-work-breakdown-widget-title">
        <div className="header-8 campaign-label">
          <FormattedMessage
            defaultMessage="Work Breakdown"
            id="campaigns.metrics.workBreakdown"
          ></FormattedMessage>
        </div>
      </div>
      <div className="campaign-work-breakdown-widget-body">
        {widgetBodyItems.map(
          (widgetBodyItem: WidgetBodyItem, index: number) => (
            <CampaignWorkBreakdownWidgetItem
              key={index}
              widgetBodyItem={widgetBodyItem}
              workBreakData={workBreakData}
            />
          )
        )}
      </div>
    </div>
  );
};
