import { ExternalToolTip } from 'shared/services/widgets/ExternalTooltip';
import { PresentationalWidgetConfiguration } from '../interfaces/widget-configuration';
import { _DeepPartialObject } from 'chart.js/types/utils';
import { Options } from 'chartjs-plugin-datalabels/types/options';

export const fillDataPlugin = {
  id: 'fillGaps',
  beforeDatasetDraw(chart: any, args: any) {
    if (args.index > 0 && chart.isDatasetVisible(args.index)) {
      args.meta.data.forEach(function (item: any, i: any) {
        const { width } = item.getProps(['width'], true);
        item.base = item.x - width + 7;
      });
    }
  },
};

const singleBarDatalabelConfiguration: _DeepPartialObject<Options> = {
  display: 'auto',
  anchor: 'start',
  align: 'bottom',
  offset: 20,
  color: '#3D3D3D',
  formatter(value) {
    const space = String(value)
      .split('')
      .map(() => ' ')
      .join('')
      .repeat(4);

    return `${space}${value}`;
  },
  clamp: true,
  clip: false,
  font: {
    size: 13,
    style: 'normal',
    weight: 700,
    lineHeight: 'normal',
    family: "'HEX Franklin v0.2'",
  },
};

export const presentationalConfigurationForWidgets: {
  [key: string]: PresentationalWidgetConfiguration;
} = {
  campaign_burndown_widget: {
    style: {
      backgroundColor: ['#9870F5', '#e2e2e2', '#71FFD6', '#23CD9C', '#0A0B50'],
      borderColor: ['#9870F5', '#e2e2e2', '#71FFD6', '#23CD9C', '#0A0B50'],
    },
  },
  campaign_sla_breakdown_widget: {
    style: {
      backgroundColor: ['#AB2039', '#F9D345', '#67E0BD'],
      borderColor: ['#AB2039', '#F9D345', '#67E0BD'],
    },
  },
  campaigns_tickets_per_person: {
    style: {
      backgroundColor: ['#C3AFF2', '#9670EF', '#7547DC', '#562ABB', '#31156F'],
      borderColor: ['#C3AFF2', '#9670EF', '#7547DC', '#562ABB', '#31156F'],
      borderRadius: 4,
      maxBarThickness: 80,
    },
  },
  comparative_slaStatus: {
    style: {
      backgroundColor: ['#C7C4C4', '#FFDB53', '#FC9C5A', '#E02B4B'],
      maxBarThickness: 80,
      borderRadius: 4,
    },
  },
  comparative_severity: {
    style: {
      backgroundColor: ['#0C5FFD', '#61D8B6', '#ED923C', '#FD5B78', '#AB2039'],
      maxBarThickness: 80,
      borderRadius: 4,
    },
  },
  comparative_slaBreachDateInSeconds: {
    style: {
      backgroundColor: ['#FFDB53', '#FC9C5A', '#FD5B78', '#AB2039'],
      maxBarThickness: 80,
      borderRadius: 4,
    },
  },
  comparative_accumulative_open_findings_by_sla_status: {
    style: {
      backgroundColor: ['#C7C4C4', '#FFDB53', '#FC9C5A', '#E02B4B'],
      maxBarThickness: 80,
      borderRadius: 4,
    },
  },
  comparative_accumulative_open_findings_by_severity: {
    style: {
      backgroundColor: ['#0C5FFD', '#61D8B6', '#ED923C', '#FD5B78', '#AB2039'],
      maxBarThickness: 80,
      borderRadius: 4,
    },
  },
  comparative_accumulative_open_findings_by_sla_breach_age: {
    style: {
      backgroundColor: ['#FFDB53', '#FC9C5A', '#FD5B78', '#AB2039'],
      maxBarThickness: 80,
      borderRadius: 4,
    },
  },
  campaign_findings_by_status_widget: {
    style: {
      backgroundColor: ['#C3AFF2', '#9670EF', '#7547DC', '#562ABB', '#31156F'],
      borderColor: ['#C3AFF2', '#9670EF', '#7547DC', '#562ABB', '#31156F'],
      borderRadius: 4,
      maxBarThickness: 80,
    },
  },
  by_severity: {
    style: {
      backgroundColor: ['#22CC9A', '#E4CD47', '#E02B4B', '#761727'],
      borderColor: ['#22CC9A', '#E4CD47', '#E02B4B', '#761727'],
    },
    options: {
      elements: {
        point: {
          radius: 0,
        },
      },
      interaction: {
        mode: 'nearest',
        intersect: false,
        axis: 'xy',
      },
      scales: {
        x: {
          grid: {
            lineWidth: 0,
          },
          ticks: {
            color: '#7F7F7F',
          },
          offset: true,
        },
        y: {
          ticks: {
            color: '#C7C4C4',
            autoSkip: true,
            maxTicksLimit: 5,
          },
        },
      },
      layout: {
        padding: {
          top: 35,
          left: 15,
        },
      },
    },
  },
  top_assigned: {
    style: {
      backgroundColor: ['#9670EF'],
      maxBarThickness: 30,
      borderColor: ['#9670EF'],
      labelColor: ['#5C5C5C'],
      borderRadius: 4,
    },
    options: {
      maintainAspectRatio: false,
      plugins: {
        tooltip: {
          enabled: false,
          yAlign: 'bottom',
          intersect: false,
          external: function (context) {
            return ExternalToolTip.externalFixedTooltipHandler(context);
          },
        },
      },
      interaction: {
        mode: 'nearest',
        intersect: false,
        axis: 'xy',
      },
      scales: {
        x: {
          offset: true,
        },
        y: {
          ticks: {
            color: '#C7C4C4',
            autoSkip: true,
            maxTicksLimit: 5,
          },
        },
      },
      layout: {},
    },
  },
  by_status: {
    style: {
      backgroundColor: ['#9670EF'],
      maxBarThickness: 40,
      borderColor: ['#9670EF'],
      labelColor: ['#5C5C5C'],
      borderRadius: 4,
    },
    options: {
      maintainAspectRatio: false,
      plugins: {
        tooltip: {
          enabled: false,
          yAlign: 'bottom',
          intersect: false,
          external: function (context) {
            return ExternalToolTip.externalFixedTooltipHandler(context);
          },
        },
      },
      interaction: {
        mode: 'nearest',
        intersect: false,
        axis: 'xy',
      },
      indexAxis: 'x',
      scales: {
        x: {
          offset: true,
        },
        y: {
          ticks: {
            color: '#C7C4C4',
            autoSkip: true,
            maxTicksLimit: 5,
          },
        },
      },
      layout: {},
    },
  },
  finding_by_ticket_status: {
    style: {
      backgroundColor: [
        '#C3AFF2',
        // 'transparent',
        '#9670EF',
        // 'transparent',
        '#7547DC',
        // 'transparent',
        '#562ABB',
      ],
      borderColor: 'transparent',
      borderRadius: {
        topLeft: 4,
        topRight: 4,
        bottomLeft: 4,
        bottomRight: 4,
      },
      borderSkipped: false,
      borderWidth: 0,
      maxBarThickness: 40,
    },
    options: {
      maintainAspectRatio: false,
      indexAxis: 'y',
      scales: {
        x: {
          beginAtZero: true,
          stacked: true,
          display: false,
        },
        y: {
          grid: {
            display: false,
          },
          beginAtZero: true,
          stacked: true,
          display: false,
        },
      },
      layout: {
        padding: 30,
      },
      plugins: {
        datalabels: singleBarDatalabelConfiguration,
      },
    },
  },
  finding_by_ticket_age: {
    style: {
      backgroundColor: [
        '#C3AFF2',
        // 'transparent',
        '#9670EF',
        // 'transparent',
        '#7547DC',
        // 'transparent',
        '#562ABB',
        '#31156F',
      ],
      borderColor: ['#C3AFF2', '#9670EF', '#7547DC', '#562ABB', '#31156F'],
      borderRadius: {
        topLeft: 4,
        topRight: 4,
        bottomLeft: 4,
        bottomRight: 4,
      },
      borderSkipped: false,
      borderWidth: 0,
      maxBarThickness: 40,
    },
    options: {
      maintainAspectRatio: false,
      indexAxis: 'y',
      scales: {
        x: {
          beginAtZero: true,
          stacked: true,
          display: false,
        },
        y: {
          grid: {
            display: false,
          },
          beginAtZero: true,
          stacked: true,
          display: false,
        },
      },
      layout: {
        padding: 30,
      },
      plugins: {
        datalabels: singleBarDatalabelConfiguration,
      },
    },
  },
  by_category: {
    style: {
      backgroundColor: [
        '#C3AFF2',
        '#BDD4FF',
        '#FFCFAE',
        '#FFB3C0',
        '#ACECDA',
        '#FCE89C',
      ],
      borderColor: ['#ffffff'],
      borderRadius: 4,
      labelColor: [
        '#31156F',
        '#013393',
        '#9F5A1A',
        '#761727',
        '#0F6A50',
        '#8D751F',
      ],
    },
    options: {
      plugins: {
        datalabels: {
          color: (props) => {
            return ((props.dataset as any).labelColor as Array<string>)[
              props.dataIndex
            ] as string;
          },
          font: {
            weight: 700,
            size: 13,
            lineHeight: 16,
            style: 'normal',
            family: "'HEX Franklin v0.2'",
          },
        },
      },
    },
  },
  analytic_trends: {
    style: {
      backgroundColor: [
        '#9670EF',
        '#562ABB',
        '#FD5B78',
        '#AB2039',
        '#FC9C5A',
        '#C77A32',
        '#FFDB53',
        '#CDAD37',
        '#61D8B6',
        '#0FAE7F',
        '#5992FE',
        '#024AD4',
      ],
      borderColor: [
        '#9670EF',
        '#562ABB',
        '#FD5B78',
        '#AB2039',
        '#FC9C5A',
        '#C77A32',
        '#FFDB53',
        '#CDAD37',
        '#61D8B6',
        '#0FAE7F',
        '#5992FE',
        '#024AD4',
      ],
    },
    options: {
      maintainAspectRatio: false,
      elements: {
        point: {
          radius: 0,
        },
      },
      interaction: {
        mode: 'nearest',
        intersect: false,
        axis: 'xy',
      },
      scales: {
        x: {
          grid: {
            lineWidth: 0,
          },
          ticks: {
            color: '#7F7F7F',
          },
          offset: true,
        },
        y: {
          ticks: {
            color: '#C7C4C4',
            autoSkip: true,
            maxTicksLimit: 5,
          },
        },
      },
      layout: {
        padding: {
          top: 15,
          left: 15,
        },
      },
    },
  },
  posture_by_severity: {
    style: {
      backgroundColor: ['#0C5FFD', '#61D8B6', '#ED923C', '#FD5B78', '#AB2039'],
      borderColor: ['#5992FE', '#61D8B6', '#FFDB53', '#FD5B78', '#AB2039'],
      maxBarThickness: 50,
      borderRadius: 4,
    },
    options: {
      plugins: {
        tooltip: {
          enabled: false,
          yAlign: 'bottom',
          intersect: false,
          external: function (context) {
            return ExternalToolTip.externalFixedTooltipHandler(context);
          },
        },
      },
      elements: {
        point: {
          radius: 0,
        },
      },
      interaction: {
        mode: 'nearest',
        intersect: false,
        axis: 'xy',
      },
      scales: {
        x: {
          grid: {
            lineWidth: 0,
          },
          ticks: {
            color: '#7F7F7F',
          },
          offset: true,
        },
        y: {
          ticks: {
            color: '#C7C4C4',
            autoSkip: true,
            maxTicksLimit: 5,
          },
        },
      },
      layout: {
        padding: {
          top: 35,
          left: 15,
        },
      },
    },
  },
  posture_by_category: {
    style: {
      backgroundColor: [
        '#FD5B78',
        '#FFDB53',
        '#9670EF',
        '#61D8B6',
        '#FC9C5A',
        '#5992FE',
      ],
      borderColor: ['#ffffff'],
      borderRadius: 4,
      labelColor: [
        '#761727',
        '#8D751F',
        '#31156F',
        '#0F6A50',
        '#9F5A1A',
        '#013393',
      ],
    },
    options: {
      plugins: {
        datalabels: {
          color: (props) => {
            return ((props.dataset as any).labelColor as Array<string>)[
              props.dataIndex
            ] as string;
          },
          font: {
            weight: 700,
            size: 13,
            lineHeight: 16,
            style: 'normal',
            family: "'HEX Franklin v0.2'",
          },
        },
      },
    },
  },
};
