import {
  Box,
  CircularProgress,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  ProjectInfoWidgetProps,
  ProjectInfoData,
} from 'Campaigns/interfaces/ProjectInfoWidget.interface';
import useManageActiveCampaignFromParams from 'FindingDetails/hooks/useManageActiveCampaignFromParams';
import moment from 'moment';
import { FunctionComponent, useEffect, useState } from 'react';
import FormattedMessage from 'shared/components/FormattedMessage';
import CampaignSelectDropdownSection from '../../../Campaigns/components/CampaignSelectDropdownSection';
import DateRange from './CampaignDateRange';
import CampaignStatus from './CampaignStatus';
import CampaignAssigns from './CampaignAssigns';
export const CampaignProjectInfoWidget: FunctionComponent<
  ProjectInfoWidgetProps
> = ({ projectData }) => {
  const [isLoading, activeCampaign, campaignList] =
    useManageActiveCampaignFromParams();

  const [projectInfoData, setProjectInfoData] = useState<
    ProjectInfoData | undefined
  >(projectData);

  const [isNearEndDate, setIsNearEndDate] = useState<boolean>(false);
  const [remainingDays, setRemainingDays] = useState<number>(1);

  useEffect(() => {
    if (activeCampaign && !projectData) {
      setProjectInfoData(activeCampaign);
    }
  }, [activeCampaign?.id]);

  const calculateRemainingDays = () => {
    const currentDate = moment();
    const endDate = moment(projectInfoData?.endDate);
    const difference = currentDate.diff(endDate, 'days');
    setIsNearEndDate(difference >= 0 && difference < 30);
    setRemainingDays(30 - difference);
  };

  useEffect(() => {
    calculateRemainingDays();
  }, [projectInfoData]);

  if (isLoading && !projectData) return <CircularProgress size={16} />;

  return (
    <Grid
      container
      wrap="wrap"
      direction="row"
      spacing={2}
      className="project-info-widget"
    >
      <Grid item xs={12}>
        <div className={`${isNearEndDate && 'warning-info'} project-info`}>
          {projectInfoData && (
            <Tooltip title={projectInfoData?.name}>
              <div className="header-9 project-name">
                {projectInfoData?.name}
              </div>
            </Tooltip>
          )}
          {/* : (
            <CampaignSelectDropdownSection
              activeCampaign={activeCampaign}
              campaignList={campaignList}
            />
          )}*/}

          {projectInfoData && (
            <DateRange
              startDate={projectInfoData.startTime}
              endDate={projectInfoData.endDate}
            />
          )}
          {isNearEndDate && (
            <Typography className="archive-message">
              <FormattedMessage
                id="campaigns.details.archiveCampaign"
                params={{
                  param1: remainingDays,
                }}
                defaultMessage="This campaign will be archived"
              />
            </Typography>
          )}
          {/*{projectInfoData?.status === CampaignStatus.NEW && (*/}
          {/*  <Typography className="creating-message">*/}
          {/*    <FormattedMessage*/}
          {/*      id="campaigns.details.creatingCampaign"*/}
          {/*      defaultMessage="This campaign is being created"*/}
          {/*    />*/}
          {/*  </Typography>*/}
          {/*)}*/}
        </div>
      </Grid>

      <Grid item xs={12} className="business-priority">
        <CampaignStatus
          type={projectInfoData && projectInfoData.businessPriority}
        />
      </Grid>
      <Grid item xs={12} className="assignees-list">
        <CampaignAssigns projectInfoData={projectInfoData} />
      </Grid>
    </Grid>
  );
};
