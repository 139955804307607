import { FunctionComponent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid';
import useCommonSelector from 'Common/utils/use-selector';
import { useGetWidgetDataByIdMutation } from 'Dashboard/store/api';
import { Typography, Grid } from '@mui/material';
import CommonLoading from 'Common/components/Loading';
import DataGridTable from 'shared/components/DataGridTable';
import WidgetContainer from 'Common/components/Widgets/components/WidgetContainer';
import { getInitialFilterLoad, selectdashboardFilter } from 'Dashboard/store';
import RiskChip from 'Risk/components/RiskChip';
import NoDataToDisplayCard from '../NoDataCard';
import ItemWithIcon from 'Common/components/ItemWithIcon';
import { NavigationHandler } from 'shared/handlers/navigation.handler';
import { GridType } from 'Risk/store';
import { FindingState } from 'shared/fixtures/data/risk-grid.data';

const navigationHandler = new NavigationHandler();

export const TopRiskOwnerTableWidget: FunctionComponent = ({}) => {
  const { t: translation } = useTranslation();
  const navigate = useNavigate();

  const dashboardFilterInitialLoad: boolean =
    useCommonSelector(getInitialFilterLoad);

  const [getWidgetData, { isLoading, data: widgetDataSet }] =
    useGetWidgetDataByIdMutation();

  const filter = useCommonSelector(selectdashboardFilter);

  useEffect(() => {
    if (dashboardFilterInitialLoad) {
      let filterPayload = {
        businessUnitId: filter.businessUnitId,
        findingType: filter.findingTypes,
      };
      if (filter.findingTypes.length === 0)
        delete (filterPayload as { findingType?: any }).findingType;
      getWidgetData({
        id: 'top_risk_owners',
        searchBody: {
          filter: filterPayload,
        },
      });
    }
  }, [dashboardFilterInitialLoad, filter.businessUnitId, filter.findingTypes]);

  const topRiskOwnersTableColumns: Array<GridColDef> = [
    {
      field: 'owner',
      headerName: translation('dashboards.widgets.topRiskOwners.owner'),
      headerAlign: 'left',
      flex: 3,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <ItemWithIcon
          title={params?.value}
          missingTitleKey="dashboards.widgets.topRiskOwners.noOwner"
          // icon={<PersonOutlineOutlinedIcon />}
        />
        // <Grid
        //   container
        //   columnSpacing={{ xs: 1 }}
        //   display="flex"
        //   alignItems={'center'}
        // >
        //   <Grid item xs={12} className="owner-details">
        //     <div className="avatar-container">
        //       <PersonOutlineOutlinedIcon />
        //     </div>
        //     <TextOverflow
        //       direction={OverflowDirection.WIDTH}
        //       type={OverflowTextDisplay.ELLIPSIS}
        //       maxWidth={200}
        //     >
        //       {params?.value ||
        //         translation('dashboards.widgets.topRiskOwners.noOwner')}
        //     </TextOverflow>
        //   </Grid>
        // </Grid>
      ),
    },
    {
      field: 'max_risk',
      headerName: translation('dashboards.widgets.topRiskOwners.riskScore'),
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <div className="chip-cell">
          <RiskChip riskScore={params?.value} />
        </div>
      ),
    },
    {
      field: 'finding_count',
      headerName: translation(
        'dashboards.widgets.topRiskOwners.numberOfFindings'
      ),
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <Typography>{params?.value}</Typography>
      ),
    },
  ];
  const onNavigateToRiskPage = (event: { ignore: any }, ownerId: string) => {
    if (!event.ignore) {
      const owner = ownerId || 'Unassigned';
      navigationHandler.handleRiskNavigation(GridType.None, {
        state: {
          id: FindingState.ACTIVE,
          name: FindingState.ACTIVE,
        },
        ownerUserId: [
          {
            id: owner,
            name: owner,
          },
        ],
      });
    }
  };

  return (
    <Grid
      className="top_risk_owners_table_widget dashboard-table-widget"
      height={'425px'}
      minHeight={'425px'}
    >
      <WidgetContainer
        title={translation(`dashboards.widgets.topRiskOwners.title`)}
      >
        {widgetDataSet && !widgetDataSet.length && !isLoading ? (
          <NoDataToDisplayCard />
        ) : (
          <DataGridTable
            rowHeight={59}
            components={{
              LoadingOverlay: CommonLoading,
            }}
            getRowClassName={(params) => 'clickable-item'}
            loading={isLoading}
            rows={widgetDataSet || []}
            columns={topRiskOwnersTableColumns}
            getRowId={(row) => {
              return row?.owner || 'Unassigned';
            }}
            onRowClick={(params: GridRowParams<any>, event: any) => {
              if (!event.ignore) onNavigateToRiskPage(event, params?.row.owner);
            }}
            hideFooter
            disableColumnMenu
            disableColumnSelector
            isRowSelectable={() => false}
            isCellEditable={() => false}
          />
        )}
      </WidgetContainer>
    </Grid>
  );
};
