import { FunctionComponent, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid';
import useCommonSelector from 'Common/utils/use-selector';
import { useGetScoreCardWidgetDataMutation } from 'Dashboard/store/api';
import { Grid } from '@mui/material';
import CommonLoading from 'Common/components/Loading';
import DataGridTable from 'shared/components/DataGridTable';
import WidgetContainer from 'Common/components/Widgets/components/WidgetContainer';
import { getInitialFilterLoad, selectdashboardFilter } from 'Dashboard/store';
import NoDataToDisplayCard from '../NoDataCard';
import SimpleLineChart from 'shared/components/SimpleLineChart';
import { PostureWidgetAnalyticsType } from 'shared/models/data/operational-widget-data.model';
import { FindingSeverity } from 'FindingDetails/interfaces/severity';
import CommonIconCell from 'shared/components/CommonIconCell';
import SeverityBreakdown from 'shared/components/SeverityBreakdown/SeverityBreakdown';
import { TrendChip } from 'shared/components/TrendChip/TrendChip';
import { DashboardFilterType } from 'Dashboard/interfaces/Dashboard';
import { GridType } from 'Risk/store';
import { FindingState } from 'shared/fixtures/data/risk-grid.data';
import { NavigationHandler } from 'shared/handlers/navigation.handler';
import ItemWithIcon from 'Common/components/ItemWithIcon';
import ScoreChip from 'shared/components/ScoreChip/ScoreChip';

const navigationHandler = new NavigationHandler();
const scorecardDomainChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      display: false,
      ticks: {
        display: false,
      },
      grid: {
        display: false,
      },
    },
    y: {
      display: false,
      ticks: {
        display: false,
      },
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
    datalabels: {
      display: false,
    },
  },
  elements: {
    point: {
      radius: 0,
      hoverRadius: 0,
    },
    line: {
      borderColor: '#7547DC',
      borderWidth: 2,
      tension: 0.4,
    },
  },
};

export const ScoreCardDomainWidget: FunctionComponent = ({}) => {
  const { t: translation } = useTranslation();

  const dashboardFilterInitialLoad: boolean =
    useCommonSelector(getInitialFilterLoad);
  const dashboardFilter: DashboardFilterType = useCommonSelector(
    selectdashboardFilter
  );

  const [
    getByCategoryWidgetData,
    { data: scorePerDomainData, isLoading: scorePerDomainDataLoading },
  ] = useGetScoreCardWidgetDataMutation();

  useEffect(() => {
    if (dashboardFilterInitialLoad)
      getByCategoryWidgetData({
        businessUnitIds: dashboardFilter.businessUnitId,
        findingTypes: dashboardFilter.findingTypes,
        timeline: dashboardFilter.timeline,
        types: [PostureWidgetAnalyticsType.SCORECARD_PER_DOMAIN],
      });
  }, [
    dashboardFilterInitialLoad,
    dashboardFilter?.businessUnitId,
    dashboardFilter?.timeline,
    dashboardFilter?.metricsTypes,
    dashboardFilter?.findingTypes,
  ]);

  const handleChipClick = (
    event: any,
    severity: FindingSeverity,
    findingType: string
  ) => {
    event.ignore = true;
    navigationHandler.handleRiskNavigation(GridType.None, {
      state: {
        id: FindingState.ACTIVE,
        name: FindingState.ACTIVE,
      },
      findingType: [
        {
          id: findingType,
          name: findingType,
        },
      ],
      severity: [
        {
          id: severity,
          name: capitalize(FindingSeverity[severity]),
        },
      ],
    });
  };

  const onNavigateToRiskPage = (event: any, findingType: string) => {
    if (!event.ignore) {
      navigationHandler.handleRiskNavigation(GridType.None, {
        state: {
          id: FindingState.ACTIVE,
          name: FindingState.ACTIVE,
        },
        findingType: [
          {
            id: findingType,
            name: findingType,
          },
        ],
      });
    }
  };

  const scorePerDomainTableColumns: Array<GridColDef> = [
    {
      field: 'name',
      headerName: translation('dashboards.widgets.riskAssesmentSurface.name'),
      flex: 3,
      headerAlign: 'left',
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            <ItemWithIcon
              maxWidth={300}
              icon={<img src={params.row.logo} height={16} />}
              title={params.value}
              missingTitleKey={''}
              displayIconOutline={false}
            />
          </>
        );
      },
    },
    {
      field: 'eventSources',
      headerName: translation('dashboards.widgets.riskAssesmentSurface.source'),
      flex: 2,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <CommonIconCell
          maxWidth={140}
          bordered
          data={params?.row?.eventSources.map((item: any) => ({
            ...item,
            label: item.name,
          }))}
          label={''}
        />
      ),
    },
    {
      field: 'severityBreakdown',
      headerName: translation(
        'dashboards.widgets.riskAssesmentSurface.severityBreakdown'
      ),
      sortable: false,
      flex: 3,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <SeverityBreakdown
            severityData={params?.row?.severityBreakdown}
            severityToDisplay={[
              FindingSeverity.HIGH,
              FindingSeverity.CRITICAL,
              FindingSeverity.MEDIUM,
            ]}
            onChipClick={(event, severity) =>
              handleChipClick(event, severity, params?.row?.name)
            }
          />
        );
      },
    },

    {
      field: 'trendChart',
      headerName: translation(
        'dashboards.widgets.riskAssesmentSurface.trendChart'
      ),
      flex: 3,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="cell-chart-widget">
            <SimpleLineChart
              fetchWidgetData={() => {}}
              isWidgetDataLoading={scorePerDomainDataLoading}
              widgetData={params.row.chartData as any}
              options={scorecardDomainChartOptions}
            />
          </div>
        );
      },
    },
    {
      field: 'trendChange',
      headerName: translation(
        'dashboards.widgets.riskAssesmentSurface.trendChange'
      ),
      flex: 2,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        const trendChange = params.value;
        if (trendChange.value === null || trendChange.value === undefined) {
          return <p>{translation('common.missingValue')}</p>;
        } else {
          return (
            <TrendChip
              trendValue={trendChange.trendPercentage}
              trendImpact={trendChange.trendImpact}
              trend={trendChange.trend}
            />
          );
        }
      },
    },

    {
      field: 'score',
      headerName: translation('dashboards.widgets.riskAssesmentSurface.score'),
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams) =>
        !!params.value ? (
          <ScoreChip
            score={
              params.value % 1 === 0 ? params.value : params.value.toFixed(1)
            }
            bordered
          />
        ) : (
          <p>{translation(`common.missingValue`)}</p>
        ),
    },
  ];

  const shouldRenderWidget = useMemo(() => {
    return (
      scorePerDomainData &&
      !scorePerDomainData.length &&
      !scorePerDomainDataLoading
    );
  }, [scorePerDomainData, scorePerDomainDataLoading]);

  return (
    <>
      <Grid
        className="risk_assessment_surface_table_widget dashboard-table-widget"
        height={'425px'}
        minHeight={'425px'}
      >
        <WidgetContainer>
          {shouldRenderWidget ? (
            <NoDataToDisplayCard />
          ) : (
            <DataGridTable
              rowHeight={59}
              components={{
                LoadingOverlay: CommonLoading,
              }}
              loading={scorePerDomainDataLoading}
              rows={scorePerDomainData || []}
              columns={scorePerDomainTableColumns}
              getRowId={(row) => {
                return row?.name;
              }}
              onRowClick={(params: GridRowParams<any>, event: any) => {
                if (!event.ignore)
                  onNavigateToRiskPage(event, params?.row.name);
              }}
              hideFooter
              disableColumnMenu
              disableColumnSelector
              isRowSelectable={() => false}
              isCellEditable={() => false}
            />
          )}
        </WidgetContainer>
      </Grid>
    </>
  );
};
